/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';

const processOptions = (options) => options.map(({ id, percentage }) => (
  { value: id, label: `${percentage}%` }
));

const VatSelect = (props) => {
  const { t } = useTranslation();

  return (
    <RemoteSelect
      filters={['id_ne', 'site_null', 'site']}
      url={props.reference ? 'vat/reference' : 'vats'}
      isFilter={props.isFilter}
      placeholder={t('common.addVat')}
      processOptions={processOptions}
      {...props}
    />
  );
};

export default VatSelect;
