import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import Async from 'react-select/async';

/**
 * Override of React Select :
 * - return option.value instead of option (obj) (works also with multiple values instead of array of option)
 *   To be able to initialize more quickly a select in a form
 * - onChange will returns also the full option (row) as second arguments, onChange(opt.value,opt)
 * - Default label
 * - Clearable by default, except if required
 * - Default NoResult Message (intl)
 * - Default label Message (intl)
 * - Close Menu on Select except if Multiple
 * - disabled and multiple props instea of multi and isDisabled for more common name
 */
const Select = ({
  value,
  onChange,
  multiple,
  loadOptions,
  clearable,
  disabled,
  required,
  options,
  defaultOptions,
  children,
  isFilter,
  'data-cy': dataCy,
  ...rest
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((val) => {
    let value;

    if (!val) {
      value = val;
    } else if (Array.isArray(val)) {
      value = val.map((item) => item.value);
    } else {
      value = val.value;
    }

    if (multiple && !Array.isArray(value)) {
      value = [value];
    }

    onChange(value, val);
  }, [onChange, multiple]);

  const selectedOption = useMemo(() => {
    if (!value) return value;

    const opts = options || defaultOptions || [];

    if (multiple) {
      return opts.filter((opt) => value.includes(opt.value));
    }

    return opts.find((opt) => opt.value === value);
  }, [value, multiple, options, defaultOptions]);

  let isClearable = clearable;

  if (required) {
    isClearable = false;
  }

  const customSelectStyles = {
    menu: (provided) => ({ ...provided, zIndex: 100 }),
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      backgroundColor: isFilter ? '#282735' : '#fff',
      borderRadius: 4,
      borderWidth: 1,
      borderColor: state.isFocused
        ? '#009ad4'
        : isFilter ? '#4c4b5c' : '#4c4b5c',
      '&:hover': {
        borderColor: state.isFocused
          ? '#009ad4'
          : '#4c4b5c',
      },
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.isFocused
        ? '#009ad4'
        : isFilter ? '#F5F5F5' : '#282735',
    }),
    placeholder: (base) => ({
      ...base,
      color: isFilter ? '#F5F5F5' : '#999',
    }),
    singleValue: (base) => ({
      ...base,
      color: isFilter ? '#F5F5F5' : '#282735',
    }),
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? 'white' : '#1d1c26',
      '&:hover': {
        backgroundColor: '#009ad4',
        color: '#f5f5f5',
      },
    }),
  };

  const Component = loadOptions ? Async : ReactSelect;

  return (
    <Component
      styles={{ ...customSelectStyles, menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
      loadOptions={loadOptions}
      value={selectedOption}
      onChange={handleChange}
      noOptionsMessage={() => t('common.noResult')}
      loadingMessage={() => t('common.loading')}
      closeMenuOnSelect={!multiple}
      placeholder={t('common.search')}
      isMulti={multiple}
      isDisabled={disabled}
      isClearable={isClearable}
      options={options}
      defaultOptions={defaultOptions}
      classNamePrefix="react-select"
      aria-label={dataCy}
      {...rest}
    >
      {children}
    </Component>
  );
};

Select.propTypes = {
  loadOptions: PropTypes.func,
  value: PropTypes.any,
  children: PropTypes.element,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  clearable: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.array,
  defaultOptions: PropTypes.array,
  isFilter: PropTypes.bool,
  'data-cy': PropTypes.string,
};

Select.defaultProps = {
  loadOptions: null,
  value: null,
  onChange() {},
  children: null,
  placeholder: null,
  multiple: false,
  clearable: true,
  label: null,
  disabled: false,
  required: false,
  options: null,
  defaultOptions: null,
  isFilter: false,
  'data-cy': '',
};

export default Select;
