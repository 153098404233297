import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useSeat from 'common/contexts/seats';

import PageHeader from 'common/components/PageHeader/PageHeader';
import PageLoader from 'common/components/PageLoader/PageLoader';
import Button from 'common/components/Button/Button';
import fetchJSON from 'common/utils/fetchJSON';

import useSite from 'sites/contexts/sites';

const Home = () => {
  const { t } = useTranslation();
  const { currentSite } = useSite();
  const [isSyncronizing, setIsSyncronizing] = useState(false);
  const {
    items: seats,
    getSeatsInfo,
    setItems: setSeatsInfo,
  } = useSeat();

  useEffect(() => {
    const getAndSetSeatsInfo = async () => {
      const res = await getSeatsInfo();

      setSeatsInfo(res);
    };

    getAndSetSeatsInfo();
  }, [getSeatsInfo, setSeatsInfo]);

  return (
    <>
      <PageLoader processing={isSyncronizing} />
      <PageHeader
        title={t('menu.pos')}
      />
      <section className="section list-page">
        <Button
          onClick={async () => {
            setIsSyncronizing(true);
            await fetchJSON({
              url: 'pos/import-config',
              method: 'GET',
            });
            window.location.reload(false);
            setIsSyncronizing(false);
          }}
          icon="fa-sync"
          label={t('pos.syncronise')}
        />
        <Button
          onClick={async () => {
            setIsSyncronizing(true);
            await fetchJSON({
              url: 'pos/sync-checks',
              method: 'GET',
            });
            setIsSyncronizing(false);
          }}
          icon="fa-sync"
          label={t('pos.syncChecks')}
        />
      </section>
      <div className="seatsViewer">
        <table className="table is-striped is-fullwidth seatsTable">
          <thead>
            <tr>
              <th>
                <span className="tableHeader">{t('pos.posNotSite')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {currentSite.tablesDefinition?.filter((t) => seats.map((s) => s.name).indexOf(t.Name.StringText[0]) < 0)
              .map((t) => (
                <tr>
                  <span>{t.Name.StringText[0]}</span>
                </tr>
              ))}
          </tbody>
        </table>
        <table className="table is-striped is-fullwidth seatsTable">
          <thead>
            <tr>
              <th>
                <span className="tableHeader">{t('pos.linked')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {currentSite.tablesDefinition?.filter((t) => seats.map((s) => s.name).indexOf(t.Name.StringText[0]) >= 0)
              .map((t) => (
                <tr>
                  <span>{t.Name.StringText[0]}</span>
                </tr>
              ))}
          </tbody>
        </table>
        <table className="table is-striped is-fullwidth seatsTable">
          <thead>
            <tr>
              <th>
                <span className="tableHeader">{t('pos.siteNotPos')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {seats?.filter((s) => currentSite.tablesDefinition?.map((t) => t.Name.StringText[0]).indexOf(s.name) < 0)
              .map((s) => (
                <tr>
                  <span>{s.name}</span>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Home;
