import React, { useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import useSpecialOffer from 'specialOffers/contexts/specialOffers';

import PageHeader from 'common/components/PageHeader/PageHeader';
import PageLoader from 'common/components/PageLoader/PageLoader';
import SpecialOfferForm from './SpecialOfferForm';

const SpecialOfferFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: specialOffer, isFetching,
  } = useSpecialOffer();

  const { id: specialOfferId } = router.match.params;

  useEffect(() => {
    fetchItem(specialOfferId);
  }, [fetchItem, specialOfferId]);

  const onSubmit = useCallback(async (data) => {
    const specialOffer = {
      ...data,
    };

    if (specialOffer.id) {
      const updatedSpecialOffer = await update(specialOffer.id, specialOffer);

      if (updatedSpecialOffer) {
        setAlert(t('specialOffers.successEdit'), 'success');
        router.push('/special-offers');
      }
    } else {
      const newProduct = await save({ ...specialOffer });

      if (newProduct) {
        setAlert(t('specialOffers.successAdd'), 'success');
        router.push('/special-offers');
      }
    }
  }, [router, setAlert, save, update, t]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`specialOffers.${specialOffer && specialOffer.id ? 'edit' : 'add'}SpecialOffer`)}
        subLink={{ url: '/special-offers', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <SpecialOfferForm
          onSubmit={onSubmit}
          specialOffer={specialOffer}
        />
      </section>
    </>
  );
};

export default SpecialOfferFormPage;
