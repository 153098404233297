import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import FormInput from 'common/components/FormInput/FormInput';
import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';
import Button from 'common/components/Button/Button';

const WaiterAreaForm = ({
  onSubmit, onDelete, waiterArea, site,
}) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={waiterArea?.id ? {
        ...waiterArea,
        site: waiterArea?.site?.id,
      } : { site }}
      render={({
        form,
        handleSubmit,
        submitting,
        pristine,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormInput
            name="name"
            label={t('common.name')}
            icon="heading"
            required
          />
          <FormInput
            name="color"
            type="circle-color"
            required
          />
          {!currentSite && (
          <FormInput
            type="custom"
            name="site"
            label={t('common.site')}
            required
          >
            <SiteSelect />
          </FormInput>
          )}
          <div className="editor-type-modal-buttons">
            {!waiterArea?.default && waiterArea?.id && (
            <Button
              onClick={() => onDelete(waiterArea)}
              confirm
              confirmMessage={t('waiterAreas.confirmDelete')}
              color="danger"
              label={t('editor.deleteType')}
              icon="fa-trash-alt"
            >
              <i className="fa fa-trash" />
              {t('editor.deleteType')}
            </Button>
            )}

            <FormInput
              type="submit"
              label={t('common.save')}
              icon="check"
              disabled={submitting || pristine}
            />
          </div>
        </form>
      )}
    />
  );
};

WaiterAreaForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  waiterArea: PropTypes.object,
  site: PropTypes.number,
};

WaiterAreaForm.defaultProps = {
  waiterArea: {},
  site: null,
};

export default WaiterAreaForm;
