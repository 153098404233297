import React from 'react';
import { PropTypes } from 'prop-types';

// const setTitleCase = (str) => str.split(/\s+/).map((w) => w[0].toUpperCase() + w.slice(1)).join(' ');

const ToggleThreeStates = ({
  options,
  value,
  onChange,
  onCustomChange,
  disabled,
  vertical,
}) => {
  const handleChange = (val) => {
    if (!disabled) {
      if (onChange) onChange(val);
      if (onCustomChange) {
        onCustomChange();
      }
    }
  };

  const count = options.length;

  return (
    <div style={{
      height: vertical ? 'auto' : 36,
      display: 'flex',
      borderRadius: 5,
      overflow: 'hidden',
      flexDirection: vertical ? 'column' : 'row',
    }}
    >
      {options.map((option, index) => (
        <button
          className="button"
          disabled={disabled}
          type="button"
          key={`option-${option.value}`}
          onClick={() => { handleChange(option.value); }}
          style={{
            flex: 'auto',
            lineHeight: '36px',
            height: 36,
            fontSize: '15px',
            backgroundColor: value === option.value ? '#009ad4' : '#eaeaea',
            color: value === option.value ? 'white' : 'black',
            textAlign: 'center',
            cursor: !disabled ? 'pointer' : '',
            transition: '0.3s',
            borderTopRightRadius: !vertical && index + 1 === count ? 4 : 0,
            borderBottomRightRadius: !vertical && index + 1 === count ? 4 : 0,
            borderTopLeftRadius: !vertical && index === 0 ? 4 : 0,
            borderBottomLeftRadius: !vertical && index === 0 ? 4 : 0,
          }}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

ToggleThreeStates.protoTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onCustomChange: PropTypes.func,
  disabled: PropTypes.bool,
  vertical: PropTypes.bool,
};

ToggleThreeStates.defaultProps = {
  options: [],
  value: '',
  onChange: () => {},
  onCustomChange: null,
  disabled: false,
  vertical: false,
};

export default ToggleThreeStates;
