import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormInput from 'common/components/FormInput/FormInput';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';

const SiteSmsForm = ({ site, values }) => {
  const { t, i18n } = useTranslation();

  // eslint-disable-next-line camelcase
  const defaultLang = values ? values.default_language : i18n.language;

  return (
    <>
      <section className="section" style={{ marginTop: 20 }}>
        <h2 className="subtitle">{t('menu.sms')}</h2>

        <div className="row">
          <FormInput
            name="smsIsActive"
            type="checkbox"
            label={t('sms.isActive')}
          />
          <FormInput
            type="time"
            name="smsSendTime"
            label={t('sms.sendTime')}
            minuteStep={60}
          />
          <span className="spacer" />
        </div>
        <div className="row">
          <MultilingualInput
            name="smsMessage"
            label={t('sms.message', { nbCharaters: values?.smsMessage?.[defaultLang]?.length || '?' })}
            type="textarea"
            value={values?.smsMessage}
            defaultLang={defaultLang}
          />
        </div>

      </section>
    </>
  );
};

SiteSmsForm.propTypes = {
  site: PropTypes.object,
  values: PropTypes.object,
};

SiteSmsForm.defaultProps = {
  site: {},
  values: {},

};

export default SiteSmsForm;
