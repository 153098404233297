import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const SitesListContext = createContext();

export const SitesListProvider = ({ children }) => (
  <ContextProvider url="lists" context={SitesListContext}>{children}</ContextProvider>
);

SitesListProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useSitesList = () => useContext(SitesListContext);

export default useSitesList;
