/* eslint-disable camelcase */
import React, {
  useMemo, useCallback, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import useAuth from 'common/contexts/auth';
import useRouter from 'common/hooks/use-router';
import { createUploadUrl } from 'common/utils/createUrl';
import getUserDisplayName from 'common/utils/getUserDisplayName';
import Button from 'common/components/Button/Button';
import AlertsContext from 'common/contexts/alerts';
import useBooking from 'bookings/contexts/bookings';
import useOrder from 'orders/contexts/orders';
import BookingModalOrder from 'sites/pages/BeachVisualizer/components/BookingModalOrder/BookingModalOrder';
import useBeachVisualizer from 'sites/pages/BeachVisualizer/contexts/beachVisualizer';
import formatPrice from 'common/utils/formatPrice';
import useSite from 'sites/contexts/sites';
import { isNumber } from 'lodash';

const SeatDetails = ({ seat, seatBookingId, seatsInfo }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    item: booking,
    fetchItem: fetchBooking,
    deactivateBooking,
    toggleValidBooking,
    fetchItems: fetchBookings,
    payAll,
    payBooking,
  } = useBooking();
  const {
    setFilters: setOrdersFilters,
  } = useOrder();
  const {
    setSeatModal,
  } = useBeachVisualizer();
  const { setAlert } = useContext(AlertsContext);
  const { user } = useAuth();
  const { currentSite } = useSite();

  useEffect(() => {
    if (seatBookingId) {
      fetchBooking(seatBookingId);
    }
    // bookings is in deps to refresh current to refresh booking
    // in case of booking updating which triggers fetchBookings
  }, [fetchBooking, seatBookingId]);

  useEffect(() => {
    console.log({ booking, seatBookingId });
  }, [booking, seatBookingId]);

  const bookingOrders = useMemo(() => {
    let currentOrders = [];

    let finishedOrders = [];

    currentOrders = booking?.orders
      ? [...booking.orders?.filter((o) => o.state !== 'done')]
      : [];
    finishedOrders = booking?.orders
      ? [...booking.orders?.filter((o) => o.state === 'done')]
      : [];

    const bookingOrders = {
      currentOrders,
      finishedOrders,
    };

    return bookingOrders;
  }, [booking]);

  const bookingPrice = useMemo(() => {
    if (booking?.payment?.status !== 'PAID' && !booking?.payment?.online) {
      return booking?.payment?.amount;
    }

    return 0;
  }, [booking]);

  const ordersSum = useMemo(() => {
    let total = booking?.payment?.amount || 0;

    total += booking?.orders?.reduce((acc, { price }) => acc + price, 0);

    return total;
  }, [booking]);

  const restToPay = useMemo(() => booking?.orders?.reduce(
    (acc, { price, active, payment: { status } }) => active && status !== 'PAID'
      ? (acc || 0) + (price || 0)
      : (acc || 0),
    bookingPrice,
  ), [booking, bookingPrice]);

  const createSpecialOffer = useCallback((bookingId) => {
    router.push(`/special-offers/add?destBooking=${bookingId}`);
  }, [router]);

  const handlePayAll = useCallback(async () => {
    const response = await payAll(booking.id);

    if (!response.error) {
      await fetchBookings();
      setSeatModal(null);
    }
  }, [booking, fetchBookings, setSeatModal, payAll]);

  const handleSeeOrders = useCallback(() => {
    setOrdersFilters({
      booking: booking.id,
    });
    router.push('/active-orders');
  }, [booking, setOrdersFilters, router]);

  const handleDeactivateBooking = useCallback(async (bookingId) => {
    const res = await deactivateBooking(bookingId);

    if (!res.error) {
      await fetchBookings();
      setSeatModal(null);
      setAlert(t('beachVisualizer.successDeactivateBooking'), 'success');
    } else {
      setAlert(t('beachVisualizer.errorDeactivateBooking'), 'danger');
    }
  }, [deactivateBooking, fetchBookings, setAlert, setSeatModal, t]);

  const handleValidBooking = useCallback(async (bookingId, isValid) => {
    const res = await toggleValidBooking(bookingId, isValid);

    if (!res.error) {
      await fetchBookings();
      setSeatModal(null);
      setAlert(t('beachVisualizer.successValidBooking'), 'success');
    } else {
      setAlert(t('beachVisualizer.errorValidBooking'), 'danger');
    }
  }, [toggleValidBooking, fetchBookings, setAlert, t, setSeatModal]);

  const handlePaymentBooking = useCallback(async (bookingId, status) => {
    if (status !== 'PAID') {
      const res = await payBooking(bookingId);

      if (!res.error) {
        await fetchBookings();
        setSeatModal(null);
        setAlert(t('beachVisualizer.successValidBooking'), 'success');
      } else {
        setAlert(t('beachVisualizer.errorValidBooking'), 'danger');
      }
    } else {
      /* TODO dé-payer */
    }
  }, [fetchBookings, setAlert, t, setSeatModal, payBooking]);

  const date = booking?.checkin ? moment(booking.checkin).format('HH:mm') : (
    moment(booking?.created_at).locale(user.language).startOf('day').format('LL')
  );

  // TODO change
  const cDate = moment().format('YYYY-MM-DD');

  const bookingItem = booking?.booking_items?.find(({ date: bDate }) => bDate === cDate);

  return (
    <div className="booking-modal">
      {(seatBookingId && booking)
      && (
      <>
        {/* HEAD */}
        <div className="booking-modal--header">

          {/* title */}
          <div className="booking-modal--title">
            <span className="mr">{t('beachVisualizer.seatNumber')}</span>
            <span className="lead">
              {seat.name}
            </span>
            <span className="ml has-text-primary">
              {console.log({ booking })}
              {booking.clientName ? booking.clientName
                : (booking.user ? booking.user.name : 'Inconnu')}
            </span>

            {/*
            <span
              className="ml tag is-medium"
              style={{
                color: booking.payment?.status !== 'PAID' ? '#f36954' : '#2bb349',
                fontWeight: 'bold',
              }}
            >
              {booking.payment?.status
                ? t(`beachVisualizer.${booking.payment.status.toLowerCase()}`)
                : t('beachVisualizer.pending')}
            </span>

            <span
              className="ml tag is-medium"
              style={{
                color: bookingItem?.valid !== 'PAID' ? '#f36954' : '#2bb349',
                fontWeight: 'bold',
              }}
            >
              {bookingItem?.valid
                ? t('beachVisualizer.validCustomerOnPlace')
                : t('beachVisualizer.unvalidCustomerOnPlace')}
            </span>
              */}

          </div>

          {/* subtitle */}
          <div className="booking-modal--client">
            <div className="booking-modal--client-info">
              <i className="mr fas fa-barcode" />
              <span className="link" onClick={() => router.push(`/bookings/edit/${booking.id}`)}>
                {booking.code && booking.code}
              </span>
            </div>
            <div className="booking-modal--client-info">
              <i className="mr fas fa-clock" />
              <span>
                {`${date} h`}
                &nbsp;
              </span>
              <span>{`(${t(`bookings.${booking.stayDuration}`)})`}</span>
            </div>
            {booking.roomID && (
            <div className="booking-modal--client-info">
              <i className="mr fas fa-hotel" />
              <span>{booking.roomID}</span>
            </div>
            )}
            <div className="booking-modal--client-info">
              <i className="mr fas fa-envelope" />
              <span>
                {booking.user?.email ? booking.user.email
                  : (booking.user?.email ? booking.user.name : 'Inconnu')}

              </span>
            </div>
            <div className="booking-modal--client-info">
              <i className="mr fas fa-mobile" />
              <span>
                {booking.user?.phone ? booking.user.phone
                  : (booking.phoneNumber ? booking.phoneNumber : 'Inconnu')}

              </span>
            </div>
          </div>

          <Button
            onClick={() => router.push(`/bookings/edit/${booking.id}`)}
            icon="fa-edit"
            color="primary"
            className="booking-modal--modify"
          />
        </div>

        {/* MAIN */ }
        <div className="booking-modal--content">
          <div className="booking-modal--details">

            {booking.seats?.length > 1 && (
            <div className="booking-modal--seats">
              <span>{t('beachVisualizer.seats')}</span>
              {booking.seats?.filter((s) => {
                const seatInfo = seatsInfo.find(({ id }) => id === s.id);

                return (
                  seatInfo.name !== seat.name
                );
              }).map((s, index) => {
                const seatInfo = seatsInfo.find(({ id }) => id === s.id);
                const seatFurniturePrice = seatInfo.furniture.prices?.find(
                  (p) => p.price_area === seatInfo.price_area.id,
                );

                return (
                  <div key={index} className="booking-modal--seat">
                    <div className="booking-modal--seat-image">
                      <img alt={seatInfo.name} src={createUploadUrl(seatInfo.furniture.image?.url)} />
                      <div className="booking-modal--seat-name">{ seatInfo.name }</div>
                    </div>
                    <div>
                      {
                        seatInfo.furniture.prices && seatInfo?.price_area?.price
                        && (
                          <>
                            <div className="booking-modal--seat-pricearea">
                              {
                                seatInfo?.price_area?.name
                              }
                            </div>
                            <div className="booking-modal--seat-price">
                              {formatPrice(seatFurniturePrice?.price, currentSite?.currency)}
                            </div>
                          </>
                        )
                      }
                    </div>
                  </div>
                );
              })}
            </div>
            )}

            {seat.waiterArea.users.length > 0 && (
            <div className="booking-modal--waiters">
              <p className="pb-1">{t('beachVisualizer.waiters')}</p>
              {seat.waiterArea.users.map((user) => (
                <p key={user.id} className="has-text-weight-bold">{getUserDisplayName(user)}</p>
              ))}
            </div>
            )}

          </div>
          {Boolean(bookingOrders.currentOrders?.length) && (
          <div className="booking-modal--activity">
            <div className="booking-modal--activity-header">
              <div className="row" style={{ width: '100%' }}>
                <div style={{ flex: 2 }}>
                  {isNumber(ordersSum) && (
                  <p>
                    <span className="mr">{t('beachVisualizer.totalOrders')}</span>
                    <span className="lead">{`${formatPrice(ordersSum, currentSite?.currency)}`}</span>
                  </p>
                  )}
                  {isNumber(restToPay) && (
                  <p>
                    <span className="mr">{t('orders.restToPay')}</span>
                    <span className="lead">{`${formatPrice(restToPay, currentSite?.currency)}`}</span>
                  </p>
                  )}
                </div>
                {!!restToPay && (
                <Button
                  onClick={handlePayAll}
                  icon="fa-check"
                  color="primary"
                  label={t('orders.payAll')}
                />
                )}
              </div>
            </div>

            <div className="booking-modal--orders-title">
              <Button
                onClick={handleSeeOrders}
                icon="fa-eye"
                color="primary"
                label={`${t('beachVisualizer.seeOrders')} (${bookingOrders.currentOrders.length})`}
              />
              { /*
                  <span className="mr">{t('beachVisualizer.currentOrders')}</span>
                  <span className="lead">{bookingOrders.currentOrders.length}</span>
                */ }
            </div>

            <div className="mt booking-modal--orders">
              <span>{t('beachVisualizer.lastOrder')}</span>
              { Boolean(bookingOrders.currentOrders.length)
                  && <BookingModalOrder order={bookingOrders.currentOrders[0]} />}
            </div>
          </div>
          )}
        </div>

        <div
          className="check-status-bar"
          style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}
        >
          {/* présence du customer */}
          <div
            className="checkbox-input"
            style={{
              backgroundColor: bookingItem?.valid ? '#17b787' : '#f36954',
              padding: 8,
              borderRadius: 8,
              marginLeft: 8,
              marginRight: 8,
            }}
          >
            <input
              id="customer-on-place"
              defaultChecked={bookingItem?.valid}
              onChange={() => handleValidBooking(booking.id, !bookingItem?.valid)}
              type="checkbox"
              className="is-checkradio"
            />
            <label htmlFor="customer-on-place" style={{ color: 'white' }}>
              {bookingItem?.valid
                ? t('beachVisualizer.validCustomerOnPlace')
                : t('beachVisualizer.unvalidCustomerOnPlace')}
            </label>
          </div>

          {/* statut du paiement */}
          <div
            className="checkbox-input"
            style={{
              backgroundColor: booking.payment?.status === 'PAID' ? '#17b787' : '#f36954',
              padding: 8,
              borderRadius: 8,
              marginLeft: 8,
              marginRight: 8,
            }}
          >
            <input
              id="payment-status"
              defaultChecked={booking.payment?.status === 'PAID'}
              onChange={() => handlePaymentBooking(booking.id, booking.payment.status)}
              type="checkbox"
              className="is-checkradio"
              disabled={booking.payment?.status === 'PAID'}
            />
            <label htmlFor="payment-status" style={{ color: 'white' }}>
              {booking.payment?.status === 'PAID'
                ? t(`beachVisualizer.${booking.payment.status.toLowerCase()}`)
                : t('beachVisualizer.pending')}
            </label>
          </div>
        </div>

        {/* FOOT */}
        <div className="booking-modal--footer">
          <Button
            onClick={() => createSpecialOffer(booking.id)}
            icon="fa-gift"
            color="primary"
            label={t('beachVisualizer.createOffer')}
          />
          <Button
            onClick={() => handleDeactivateBooking(booking.id)}
            icon="fa-calendar-times"
            color="warning"
            confirm
            label={t('beachVisualizer.deactivateBooking')}
            confirmMessage={t('beachVisualizer.confirmDeactivate')}
          />

          {/*
          <ToggleThreeStates
            value={bookingItem?.valid}
            onChange={(value) => handleValidBooking(booking.id, value)}
            options={[{ value: true, label: t('beachVisualizer.validCustomerOnPlace') },
              { value: false, label: t('beachVisualizer.unvalidCustomerOnPlace') },
            ]}
          />
          */}

          { /*
            <Button
              onClick={() => handleDeleteBooking(booking.id)}
              icon="fa-trash-alt"
              color="danger"
              confirm
              label={t('beachVisualizer.deleteBooking')}
              confirmMessage={t('beachVisualizer.confirmDelete')}
            />
          */ }
        </div>
      </>
      )}
    </div>
  );
};

SeatDetails.propTypes = {
  seat: PropTypes.object.isRequired,
  seatBookingId: PropTypes.number,
  seatsInfo: PropTypes.array.isRequired,
};

SeatDetails.defaultProps = {
  seatBookingId: 0,
};

export default SeatDetails;
