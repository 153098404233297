import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';
import RCTimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

const TimePicker = ({
  datetime, value, onChange, minuteStep, 'data-cy': dataCy, ...rest
}) => {
  const val = useMemo(() => {
    let val = null;

    if (!value) val = null;
    else if (value && typeof value === 'string') {
      if (datetime) {
        val = moment(value);
      } else {
        val = moment(`2020-01-01 ${value}`);
      }
    } else {
      val = value;
    }
    return val;
  }, [value, datetime]);

  const handleChange = useCallback((value) => {
    if (value) {
      if (datetime) {
        onChange(value);
      } else {
        onChange(value.second(0).format('HH:mm:ss.SSS'));
      }
    } else {
      onChange(value);
    }
  }, [onChange, datetime]);

  return (
    <RCTimePicker
      value={val}
      data-cy={dataCy}
      showSecond={false}
      minuteStep={minuteStep}
      onChange={handleChange}
      {...rest}
    />
  );
};

TimePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  'data-cy': PropTypes.string,
  onChange: PropTypes.func,
  minuteStep: PropTypes.number,
  datetime: PropTypes.bool, // returns a DateTime Objet if True otherwise a string like 12:43
};

TimePicker.defaultProps = {
  value: null,
  'data-cy': '',
  datetime: false,
  minuteStep: 15,
  onChange() {},
};

export default TimePicker;
