import React, { useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import useProduct from 'products/contexts/products';

import PageHeader from 'common/components/PageHeader/PageHeader';
import PageLoader from 'common/components/PageLoader/PageLoader';
import ProductForm from './ProductForm';

const ProductFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: product, isFetching,
  } = useProduct();

  const { id: productId } = router.match.params;

  useEffect(() => {
    fetchItem(productId);
  }, [fetchItem, productId]);

  const onSubmit = useCallback(async (data) => {
    const product = {
      ...data,
    };

    // TODO En attendant de faire un vrai champ InputNumber
    product.price = parseFloat(product.price);

    if (product.id) {
      const updatedProduct = await update(product.id, product);

      if (updatedProduct) {
        setAlert(t('products.successEdit'), 'success');
        router.push('/products');
      }
    } else {
      const newProduct = await save({ ...product });

      if (newProduct) {
        setAlert(t('products.successAdd'), 'success');
        router.push('/products');
      }
    }
  }, [router, setAlert, save, update, t]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`products.${product && product.id ? 'edit' : 'add'}Product`)}
        subLink={{ url: '/products', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        {(product || !productId) && (
          <ProductForm
            onSubmit={onSubmit}
            product={product}
          />
        )}
      </section>
    </>
  );
};

export default ProductFormPage;
