import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const VatContext = createContext();

export const VatProvider = ({ children }) => (
  <ContextProvider url="vats" context={VatContext}>{children}</ContextProvider>
);

VatProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useVat = () => useContext(VatContext);

export default useVat;
