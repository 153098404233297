import React, { useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import PageHeader from 'common/components/PageHeader/PageHeader';

import PageLoader from 'common/components/PageLoader/PageLoader';
import useCustomerCategory from 'customers/contexts/customerCategories';
import CustomerCategoryForm from './CustomerCategoryForm';

const CustomerCategoryFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: customerCategory, isFetching,
  } = useCustomerCategory();
  const { id: customerCategoryId } = router.match.params;

  useEffect(() => { fetchItem(customerCategoryId); }, [fetchItem, customerCategoryId]);

  const onSubmit = useCallback(async (customers) => {
    if (customers.id) {
      const res = await update(customers.id, customers);

      if (!res.error) {
        setAlert(t('customers.successEditCategory'), 'success');
        router.push('/customer-categories');
      } else {
        setAlert(t('customers.errorFormCategory'), 'danger');
      }
    } else {
      const res = await save({ ...customers, confirmed: true });

      if (!res.error) {
        setAlert(t('customers.successAddCategory'), 'success');
        router.push('/customer-categories');
      } else {
        setAlert(t('customers.errorFormCategory'), 'danger');
      }
    }
  }, [router, update, save, setAlert, t]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`customers.${customerCategory && customerCategory.id ? 'edit' : 'add'}CustomerCategory`)}
        subLink={{ url: '/customers', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <CustomerCategoryForm
          onSubmit={onSubmit}
          customerCategory={customerCategory}
        />
      </section>
    </>
  );
};

export default CustomerCategoryFormPage;
