/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */
import 'moment/locale/fr';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import DayPicker from 'react-day-picker/DayPicker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';

import MomentLocaleUtils, {
  formatDate,
  // eslint-disable-next-line comma-dangle
  parseDate
} from 'react-day-picker/moment';
import { useTranslation } from 'react-i18next';

class CustomInput extends React.Component {
  render() {
    return (
      <div className="control">
        <input
          className={`input ${this.props.isfilter ? 'is-filter' : ''}`}
          label={this.props.isFilter ? this.props.label : ''}
          data-cy={this.props['data-cy']}
          {...this.props}
        />
      </div>
    );
  }
}
const DatePicker = ({
  value, onChange, showCalendar, 'data-cy': dataCy, disabled, mode,
}) => {
  const { t, i18n } = useTranslation();
  const formattedValue = !value ? '' : (
    Array.isArray(value) ? value.map((d) => moment(d).format('DD/MM/YYYY')) : moment(value).format('DD/MM/YYYY')
  );
  const modifiersStyles = {
    selected: {
      borderRadius: 0,
      backgroundColor: '#009ad4',
    },
    today: {
      color: '#ffab41',
    },
    firstDate: {
      // borderRadius: '50% 0 0 50% !important',

    },
    lastDate: {
      // borderRadius: '0 50% 50% 0 !important',
    },
  };

  const getDaysBetweenDates = (startDate, endDate) => {
    const now = startDate;
    const dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.startOf('day').toDate());
      now.add(1, 'days');
    }
    return dates;
  };

  const handleDayClick = (day, mode) => {
    if (mode === 'multiple') {
      let days = [];
      const newDay = day ? moment(day).startOf('day').toDate() : null;

      if (Array.isArray(value)) {
        days = value.filter((d) => !moment(d).isSame(newDay));

        if (days.length === value.length) {
          days = [...value, newDay];
        }
        onChange(days);
      } else {
        onChange([newDay]);
      }
    }

    if (mode === 'single') {
      let days = [];
      const newDay = day ? moment(day).startOf('day').toDate() : null;

      if (Array.isArray(value)) {
        days = [value.find((d) => !moment(d).isSame(newDay))];

        if (days.length === value.length) {
          days = [newDay];
        }
        onChange(days);
      } else {
        onChange([newDay]);
      }
    }

    if (mode === 'range') {
      // const days = [];

      if (Array.isArray(value) && value.length === 1) {
        const secondDate = moment(day).startOf('day');
        const firstDate = moment(value[0]).startOf('day');

        let betweenAndNewDays;

        if (firstDate.isBefore(secondDate)) {
          betweenAndNewDays = getDaysBetweenDates(firstDate, secondDate);
        } else {
          betweenAndNewDays = getDaysBetweenDates(secondDate, firstDate);
        }

        /* days = value.filter((d) => !moment(d).isSame(newDay));

        if (days.length === value.length) {
          days = [...value, ...betweenAndNewDays];
        } */

        onChange(betweenAndNewDays);
      } else {
        const newDay = day ? moment(day).startOf('day').toDate() : null;

        onChange([newDay]);
      }
    }
  };

  const handleSingleDay = (day) => {
    const d = day ? moment(day).startOf('day').toDate() : null;

    onChange(d);
  };

  let modifiers = {};

  if (Array.isArray(value) && value.length > 1) {
    modifiers = {
      firstDate: value[0],
      lastDate: value[value.length - 1],
    };
  }
  return (
    <div className={`date-picker ${disabled === true && ' date-picker-disabled'}`}>
      {showCalendar ? (
        <>
          <DayPicker
            onDayClick={(day) => handleDayClick(day, mode)}
            selectedDays={value}
            locale={i18n.language}
            localeUtils={MomentLocaleUtils}
            modifiersStyles={modifiersStyles}
            modifiers={modifiers}
            // mode={mode}
          />
        </>
      ) : (
        <DayPickerInput
          value={formattedValue}
          component={(props) => <CustomInput {...props} data-cy={dataCy} />}
          formatDate={formatDate}
          parseDate={parseDate}
          label={t('datePicker.label')}
          onDayChange={(day) => handleSingleDay(day)}
          dayPickerProps={{
            selectedDays: formattedValue,
            locale: i18n.language,
            localeUtils: MomentLocaleUtils,
            modifiersStyles,
          }}
        />
      )}
    </div>
  );
};

export default DatePicker;
