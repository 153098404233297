/* eslint-disable object-curly-newline */
import Button from 'common/components/Button/Button';
import FormInput from 'common/components/FormInput/FormInput';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import fetchJSON from 'common/utils/fetchJSON';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';

const DecorationForm = ({ onSubmit, onDelete, decoration, site }) => {
  const { t, i18n } = useTranslation();
  const { currentSite, fetchItem: fetchSite } = useSite();

  useEffect(() => { fetchSite(site); }, [fetchSite, site]);

  const initialValues = useMemo(() => {
    const values = decoration?.id ? {
      ...decoration,
      site: decoration?.site?.id,
    } : { site, width: 1, height: 1 };

    return values;
  }, [decoration, site]);

  // eslint-disable-next-line camelcase
  const defaultLang = currentSite ? currentSite.default_language : i18n.language;

  const handleTranslate = async (values, setFieldValue) => {
    let translated;
    const { name } = values;

    console.log(currentSite);

    if (name) {
      translated = await fetchJSON({
        url: 'sites/translate',
        method: 'POST',
        payload: { items: { name }, site: currentSite.id },
      });

      setFieldValue({
        field: 'name',
        value: translated.name,
      });
    }
  };

  if (currentSite && decoration && decoration.id && decoration.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      render={({
        values, handleSubmit, submitting, pristine, form,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Button
            style={{ marginBottom: '2rem' }}
            label="Translate"
            icon="fa-language"
            color="primary"
            confirm
            confirmMessage={t('common.translateConfirm')}
            onClick={() => handleTranslate(values, form.mutators.setFieldValue)}
          />
          <MultilingualInput
            name="name"
            label={t('common.name')}
            icon="heading"
            value={values.name}
            defaultLang={defaultLang}
            required
          />
          <FormInput
            name="image"
            type="image"
            nbCols={5}
            maxWidth={500}
            maxHeight={500}
            required
          />

          <div className="row">
            <FormInput
              name="width"
              label={t('common.width')}
              required
              type="number"
            />
            <FormInput
              name="height"
              label={t('common.height')}
              required
              type="number"
            />
          </div>

          {!currentSite && (
            <FormInput
              type="custom"
              name="site"
              label={t('common.site')}
              required
            >
              <SiteSelect />
            </FormInput>
          )}

          <div className="editor-type-modal-buttons">
            {decoration?.id && (
            <Button
              onClick={() => onDelete(decoration)}
              confirmMessage={t('decorations.confirmDelete')}
              confirm
              color="danger"
              label={t('editor.deleteType')}
              icon="fa-trash-alt"
            >
              <i className="fa fa-trash" />
              {t('editor.deleteType')}
            </Button>
            )}

            <FormInput
              type="submit"
              label={t(`decorations.decoration${decoration && decoration.id ? 'Edit' : 'Add'}`)}
              icon="chair"
              disabled={submitting || pristine}
            />
          </div>
        </form>
      )}
    />
  );
};

DecorationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  decoration: PropTypes.object,
  site: PropTypes.number,
};

DecorationForm.defaultProps = {
  decoration: {},
  site: null,
};

export default DecorationForm;
