import PropTypes from 'prop-types';
import React from 'react';
import useBeachEditor from 'sites/pages/BeachEditor/contexts/beachEditor';

const style = {
  position: 'absolute',
  padding: 0,
};

const Item = ({
  item, moveInProgress, mapTrueDimensions, mapScreenDimensions,
}) => {
  const {
    mode, changeSeatName, handleClickonSquare,
    selectedSeats, seats, someErrors, setSomeErrors,
  } = useBeachEditor();

  const allowClickAction = mode !== 'conception' && mode !== 'decoration';

  const component = item?.furniture?.image ? (
    <>
      <img
        alt={item.name}
        src={`${process.env.REACT_APP_API_URL}${item.furniture.image.url}`}
        style={{
          opacity: !item.outOfOrder || !allowClickAction ? 1 : 0.5,
        }}
      />
      {item.outOfService && (
      <span
        className="icon is-large is-left"
        style={{
          color: '#B21807',
          position: 'absolute',
          top: 0,
          right: 0,
          fontSize: `${(((0.2 * item?.furniture?.width) / mapTrueDimensions.width)
          * mapScreenDimensions.width * item?.scale) / 16}em`,
        }}
      >
        <i className="fas fa-lock" />
      </span>
      )}
    </>
  ) : null;

  let color = 'white';

  switch (mode) {
    case 'waiter':
      if (item.waiter_area) {
        color = item.waiter_area.color;
      }
      break;
    default:
      if (item.price_area) {
        color = item.price_area.color;
      }
  }

  const isSelected = selectedSeats.find((seatId) => seatId === item.id);

  const inError = someErrors.find((seatId) => seatId === item.id);
  const itemWidth = `calc(${(item?.furniture?.width / mapTrueDimensions.width)
  * mapScreenDimensions.width * item?.scale}px)`;
  const itemHeight = `calc(${(item?.furniture?.height / mapTrueDimensions.height)
  * mapScreenDimensions.height * item?.scale}px)`;
  // éviter que ça déborde si c'est un rectangle et qu'il y'a rotation 90°
  const itemInputWidth = itemWidth > itemHeight ? `calc(${itemWidth * item?.scale} - 8px)`
    : `calc(${itemHeight * item?.scale} - 8px)`;

  return (
    <div
      style={{
        ...style,
        left: item.x,
        top: item.y,
        transform: item.rotate ? `rotate(${item.rotate}deg)` : '',
        backgroundColor: !allowClickAction || item.outOfOrder ? 'transparent' : color,
        borderWidth: !allowClickAction ? 0 : (isSelected ? 6 : 4),
        borderStyle: 'solid',
        borderColor: isSelected ? 'black' : color,
        cursor: (allowClickAction && selectedSeats.find((seatId) => seatId === item.id))
          ? 'move' : 'pointer',
        pointerEvents: !allowClickAction ? 'none' : 'auto',
        boxShadow: !allowClickAction ? 'none'
          : (isSelected ? '#3f5dae 0px 0px 15px' : '0 2px 1px rgba(0, 0, 0, 0.4)'),
        width: itemWidth,
        height: itemHeight,

      }}
      onClick={(e) => {
        if (allowClickAction && !moveInProgress) { e.stopPropagation(); handleClickonSquare(item.id, e.ctrlKey); }
      }}
      className={`BoardItem ${isSelected && 'is-selected'}`}
      dataid={item.id}
    >
      { !allowClickAction ? (
        <>
          { component }
        </>
      ) : (
        <>
          { component }
          <input
            type="number"
            value={item.name}
            className="itemElement input seatName"
            onChange={
              (e) => {
                const newValue = Number(e.target.value);

                changeSeatName(item, newValue.toString());

                if (seats.find((seat) => (Number(seat.name) === newValue)) || !newValue) {
                  setSomeErrors((oldErrors) => oldErrors.find((seatId) => seatId === item.id)
                    ? oldErrors : [...oldErrors, item.id]);
                } else {
                  setSomeErrors((oldErrors) => oldErrors.filter((seatId) => seatId !== item.id));
                }
              }
            }
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              transform: item.rotate ? `rotate(${item.rotate * -1}deg)` : null,
              backgroundColor: inError ? 'red' : 'white',
              color: inError ? 'white' : 'black',
              maxWidth: itemInputWidth,
            }}
          />

        </>
      )}
    </div>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  moveInProgress: PropTypes.bool,
  mapTrueDimensions: PropTypes.object,
  mapScreenDimensions: PropTypes.object,
};

Item.defaultProps = {
  item: null,
  moveInProgress: false,
  mapTrueDimensions: {
    width: 50,
    height: 50,
  },
  mapScreenDimensions: {
    width: 800,
    height: 800,
  },
};

export default Item;
