import React, {
  useEffect, useContext, useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import useLocation from 'aroundMe/contexts/locations';

import PageHeader from 'common/components/PageHeader/PageHeader';
import calculateDistance from 'common/utils/calculateDistance';
import PageLoader from 'common/components/PageLoader/PageLoader';
import AroundMeForm from './AroundMeForm';

const AroundMeFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: location, isFetching,
  } = useLocation();

  const [categoryItems, setCategoryItems] = useState([]);
  const { id: locationId } = router.match.params;

  useEffect(() => { fetchItem(locationId); }, [fetchItem, locationId]);

  const handleCategoryItemsLoaded = useCallback((categoryItems) => {
    setCategoryItems(categoryItems);
  }, []);

  const onSubmit = useCallback(async (location) => {
    if (categoryItems.message) {
      return;
    }

    const categoryItem = categoryItems.find((categoryItem) => categoryItem.id === location.category_item);
    const { site } = categoryItem;

    const payload = { ...location };

    payload.distance = await calculateDistance(payload.lat_lon.lat, payload.lat_lon.lng, site.lat, site.lng);

    if (location.id) {
      const res = await update(payload.id, payload);

      if (!res.error) {
        setAlert(t('locations.successEdit'), 'success');
        router.push('/aroundme');
      } else {
        setAlert(res.message, 'danger');
      }
    } else {
      const res = await save(payload);

      if (!res.error) {
        setAlert(t('locations.successAdd'), 'success');
        router.push('/aroundme');
      } else {
        setAlert(res.message, 'danger');
      }
    }
  }, [categoryItems, update, setAlert, t, router, save]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`locations.${location && location.id ? 'edit' : 'add'}Location`)}
        subLink={{ url: '/aroundme', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <AroundMeForm
          onCategoryItemsLoaded={handleCategoryItemsLoaded}
          onSubmit={onSubmit}
          location={location}
        />
      </section>
    </>
  );
};

export default AroundMeFormPage;
