import React, { useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import PageHeader from 'common/components/PageHeader/PageHeader';
import useCategory from 'categories/contexts/categories';
import PageLoader from 'common/components/PageLoader/PageLoader';
import CategoryForm from './CategoryForm';

const CategoryFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: category, isFetching,
  } = useCategory();
  const { id: categoryId } = router.match.params;

  useEffect(() => { fetchItem(categoryId); }, [fetchItem, categoryId]);

  const onSubmit = useCallback(async (data) => {
    const category = { ...data };

    if (category.id) {
      const updatedCategory = await update(category.id, category);

      if (updatedCategory) {
        setAlert(t('categories.successEdit'), 'success');
        router.push('/categories');
      }
    } else {
      const newCategory = await save(category);

      if (newCategory) {
        setAlert(t('categories.successAdd'), 'success');
        router.push('/categories');
      }
    }
  }, [router, update, save, setAlert, t]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`categories.${category && category.id ? 'edit' : 'add'}Category`)}
        subLink={{ url: '/categories', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <CategoryForm
          onSubmit={onSubmit}
          category={category}
        />
      </section>
    </>
  );
};

export default CategoryFormPage;
