import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FormInput from 'common/components/FormInput/FormInput';
import SiteSelect from 'sites/components/SiteSelect';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import arrayMutators from 'final-form-arrays';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import i18n from '../../i18n';

const CallTypeForm = ({
  onSubmit, callType, currentSite,
}) => {
  const { t } = useTranslation();

  if (currentSite && callType && callType.id && callType.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  const defaultLang = callType?.site?.default_language ?? (
    currentSite ? currentSite.default_language : i18n.language);

  const initialValues = {
    active: true,
    site: currentSite?.id ?? '',
  };

  const days = [];

  for (let i = 1; i < 32; i++) {
    days.push({ value: i, label: i });
  }

  const months = [];

  let date;

  for (let i = 0; i < 12; i++) {
    date = new Date(2022, i, 1); // the year and day are not important
    months.push({ value: i + 1, label: date.toLocaleString(defaultLang, { month: 'long' }) });
  }

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      initialValues={callType ?? initialValues}
      render={({
        handleSubmit, submitting, pristine,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />

          <h2 className="subtitle">{t('menu.callTypes')}</h2>

          <div className="row">

            <MultilingualInput
              icon="heading"
              type="text"
              name="name"
              label={t('common.name')}
              defaultLang={defaultLang}
              required
            />
            {/* <div style={{
              alignSelf: 'flex-end',
              padding: 16,
            }}
            >
              <FormInput
                type="checkbox"
                name="active"
                label={t('common.active')}

              />
          </div> */}
          </div>

          {!currentSite && (
            <FormInput
              type="custom"
              name="site"
              label={t('common.site')}
              required
            >
              <SiteSelect />
            </FormInput>
          )}

          <FormInput
            type="submit"
            label={t('common.save')}
            icon="map-marker-alt"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

CallTypeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  callType: PropTypes.object,
};

CallTypeForm.defaultProps = {
  callType: {},
};

export default CallTypeForm;
