import React, { useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import useMap from 'maps/contexts/maps';
import useSite from 'sites/contexts/sites';

import PageHeader from 'common/components/PageHeader/PageHeader';
import PageLoader from 'common/components/PageLoader/PageLoader';
import MapForm from './MapForm';

const MapFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentSite, setCurrentSite } = useSite();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: map, isFetching,
  } = useMap();

  const { id: mapId } = router.match.params;

  useEffect(() => {
    fetchItem(mapId);
  }, [fetchItem, mapId]);

  const onSubmit = useCallback(async (data) => {
    const map = {
      ...data,
    };

    if (map.id) {
      const updatedMap = await update(map.id, map);

      if (updatedMap) {
        setAlert(t('maps.successEdit'), 'success');
        // Updating currentSite to refresh left menu

        if (currentSite) {
          // Updating currentSite to refresh left menu
          const newCurrentSite = { ...currentSite };

          const mapIndex = newCurrentSite.maps.findIndex(((iMap) => iMap.id === map.id));

          newCurrentSite.maps[mapIndex] = updatedMap;
          setCurrentSite(newCurrentSite);
        }
        router.push('/maps');
      }
    } else {
      const newMap = await save({ ...map });

      if (newMap) {
        setAlert(t('maps.successAdd'), 'success');
        if (currentSite) {
          // Updating currentSite to refresh left menu
          const newCurrentSite = { ...currentSite };

          newCurrentSite.maps.push(newMap);
          setCurrentSite(newCurrentSite);
        }
        router.push('/maps');
      }
    }
  }, [update, setAlert, t, router, save, currentSite, setCurrentSite]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`maps.${map && map.id ? 'edit' : 'add'}Map`)}
        subLink={{ url: '/maps', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <MapForm
          onSubmit={onSubmit}
          map={map}
        />
      </section>
    </>
  );
};

export default MapFormPage;
