import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const LocationContext = createContext();

export const LocationsProvider = ({ children }) => (
  <ContextProvider url="locations" context={LocationContext}>{children}</ContextProvider>
);

LocationsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useLocation = () => useContext(LocationContext);

export default useLocation;
