import React, { useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import PageHeader from 'common/components/PageHeader/PageHeader';
import useCategoryService from 'categoryServices/contexts/categoryServices';
import PageLoader from 'common/components/PageLoader/PageLoader';
import CategoryServiceForm from './CategoryServiceForm';

const CategoryServiceFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: categoryService, isFetching,
  } = useCategoryService();
  const { id: categoryServiceId } = router.match.params;

  useEffect(() => { fetchItem(categoryServiceId); }, [fetchItem, categoryServiceId]);

  const onSubmit = useCallback(async (data) => {
    const categoryService = { ...data };

    if (categoryService.id) {
      const updatedCategoryService = await update(categoryService.id, categoryService);

      if (updatedCategoryService) {
        setAlert(t('categoryServices.successEdit'), 'success');
        router.push('/categoryServices');
      }
    } else {
      const newCategoryService = await save(categoryService);

      if (newCategoryService) {
        setAlert(t('categoryServices.successAdd'), 'success');
        router.push('/categoryServices');
      }
    }
  }, [router, update, save, setAlert, t]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`categoryServices.${categoryService && categoryService.id ? 'edit' : 'add'}CategoryService`)}
        subLink={{ url: '/categoryServices', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <CategoryServiceForm
          onSubmit={onSubmit}
          categoryService={categoryService}
        />
      </section>
    </>
  );
};

export default CategoryServiceFormPage;
