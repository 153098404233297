/* eslint-disable react/prop-types */
import React from 'react';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';
import { useTranslation } from 'react-i18next';

const processOptions = (options) => options.map((site) => ({ value: site.id, label: site.name, site }));

const SiteSelect = (props) => {
  const { t } = useTranslation();

  return (
    <RemoteSelect
      url="sites"
      isFilter={props.isFilter}
      placeholder={t('common.site')}
      processOptions={processOptions}
      {...props}
    />
  );
};

export default SiteSelect;
