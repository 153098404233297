/* eslint-disable object-curly-newline */
import Button from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import AlertsContext from 'common/contexts/alerts';
import useFurniture from 'furnitures/contexts/furnitures';
import FurnitureForm from 'furnitures/pages/FurnitureFormPage/FurnitureForm';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useBeachEditor from 'sites/pages/BeachEditor/contexts/beachEditor';
import ContextualEditor from './ContextualEditor';

const FurnitureSelector = () => {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const {
    save, update, remove, fetchItems: fetchFurnitures, items: furnitures,
  } = useFurniture();

  const {
    updateSeatInformation, site,
    selectedSeats, setIncrementationSeat, incrementationSeat,
  } = useBeachEditor();

  const [showModal, setShowModal] = useState(false);
  const [selectedFurnitureModal, setSelectedFurnitureModal] = useState(null);

  const handleOpenModal = useCallback((furniture) => {
    setSelectedFurnitureModal(furniture);
    setShowModal(true);
  }, [setShowModal]);

  const handleDelete = useCallback(async (row) => {
    await remove(row.id);
    await fetchFurnitures();
    setShowModal(false);
  }, [remove, fetchFurnitures]);

  const handleSubmitModal = useCallback(async (data) => {
    const payload = { ...data };

    if (data.id) {
      const res = await update(payload.id, payload);

      if (!res.error) {
        setAlert(t('furnitures.successEdit'), 'success');
        fetchFurnitures();
        setShowModal(false);
      } else {
        setAlert(res.message, 'danger');
      }
    } else {
      const res = await save(payload);

      if (!res.error) {
        setAlert(t('furnitures.successAdd'), 'success');
        fetchFurnitures();
        setShowModal(false);
      } else {
        setAlert(res.message, 'danger');
      }
    }
  }, [fetchFurnitures, save, setAlert, t, update]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  useEffect(() => {
    if (furnitures?.length) {
      updateSeatInformation('furniture', furnitures);
    }
  }, [furnitures, updateSeatInformation]);

  return (
    <>
      <div className="selector-panel">
        { showModal && (
        <Modal confirmModal={() => {}} closeModal={handleCloseModal}>
          <FurnitureForm
            site={site.id}
            onSubmit={handleSubmitModal}
            furniture={selectedFurnitureModal}
            onDelete={handleDelete}
          />
        </Modal>
        )}

        <div className="editor-title-add">
          <b>{t('menu.furnitures')}</b>
          <Button
            color="primary"
            label={t('editor.titleAddType')}
            icon="fa-plus"
            onClick={handleOpenModal}
            className="editor-add-type"
          />
        </div>

        <div className="selectors">
          { furnitures && furnitures.map((item, index) => (
            <div
              key={index}
              alt={item.name}
              className={`selector ${item.id === incrementationSeat?.furniture?.id && 'selected'}`}
              onClick={
                () => {
                  setIncrementationSeat((current) => ({ ...current, furniture: item }));
                }
              }
            >
              <img
                alt={item.name}
                src={`${process.env.REACT_APP_API_URL}${item.image.url}`}
              />
              <div className="selectorName">
                <TranslatedText value={item.name} />
                {item.id === incrementationSeat?.furniture?.id && (
                <p>
                  {t('editor.doubleClicOnMapToAdd')}
                </p>
                )}
              </div>
              <div className="actionsButton">
                <Button
                  onClick={() => handleOpenModal(item)}
                  icon="fa-pencil-alt"
                  color="primary"
                  transparent
                />
              </div>

            </div>
          ))}

        </div>
      </div>
      { Boolean(selectedSeats.length) && (
      <ContextualEditor />
      )}
    </>
  );
};

export default FurnitureSelector;
