import React, {
  useEffect, useContext, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import useWaiterArea from 'waiterAreas/contexts/waiterAreas';

import PageHeader from 'common/components/PageHeader/PageHeader';
import PageLoader from 'common/components/PageLoader/PageLoader';
import WaiterAreaForm from './WaiterAreaForm';

const WaiterAreaFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: waiterArea, isFetching,
  } = useWaiterArea();

  const { id: waiterAreaId } = router.match.params;

  useEffect(() => { fetchItem(waiterAreaId); }, [fetchItem, waiterAreaId]);

  const onSubmit = useCallback(async (waiterArea) => {
    const payload = { ...waiterArea };

    if (waiterArea.id) {
      const res = await update(payload.id, payload);

      if (!res.error) {
        setAlert(t('waiterAreas.successEdit'), 'success');
        router.push('/waiter-areas');
      } else {
        setAlert(res.message, 'danger');
      }
    } else {
      const res = await save(payload);

      if (!res.error) {
        setAlert(t('waiterAreas.successAdd'), 'success');
        router.push('/waiter-areas');
      } else {
        setAlert(res.message, 'danger');
      }
    }
  }, [update, setAlert, t, router, save]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`waiterAreas.${waiterArea && waiterArea.id ? 'edit' : 'add'}WaiterArea`)}
        subLink={{ url: '/waiter-areas', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <WaiterAreaForm
          onSubmit={onSubmit}
          waiterArea={waiterArea}
        />
      </section>
    </>
  );
};

export default WaiterAreaFormPage;
