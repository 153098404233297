import React from 'react';
import useBeachEditor from 'sites/pages/BeachEditor/contexts/beachEditor';
// import SeatSelector from './SeatSelector';
// import FurnitureSelector from './FurnitureSelector';
import WaiterAreaSelector from './WaiterAreaSelector';
import PriceAreaSelector from './PriceAreaSelector';
import ConceptionSelector from './ConceptionSelector';
import DecorationSelector from './DecorationSelector';
import FurnitureSelector from './FurnitureSelector';

const SelectorPanel = () => {
  const { mode } = useBeachEditor();

  return (
    <div className="beach-sidebar">
      { mode === 'conception' && <ConceptionSelector /> }
      { mode === 'decoration' && <DecorationSelector /> }
      { mode === 'furniture' && <FurnitureSelector /> }
      { mode === 'price' && <PriceAreaSelector /> }
      { mode === 'waiter' && <WaiterAreaSelector /> }
    </div>
  );
};

export default SelectorPanel;
