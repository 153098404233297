/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'common/hooks/use-router';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Button from 'common/components/Button/Button';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useMap from 'maps/contexts/maps';
import useSite from 'sites/contexts/sites';

const MapsList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentSite, setCurrentSite } = useSite();
  const {
    fetchItems: fetchMaps,
    items: maps,
    remove,
    update,
    setFilter: setMapFilter,

  } = useMap();

  useEffect(() => {
    setMapFilter('site_null', 0);
  }, [setMapFilter]);

  const handleDelete = useCallback(async (mapId) => {
    await remove(mapId);
    await fetchMaps();
    const newCurrentSite = { ...currentSite };

    newCurrentSite.maps = newCurrentSite.maps.filter((iMap) => iMap.id !== mapId);
    setCurrentSite(newCurrentSite);
  }, [remove, fetchMaps, currentSite, setCurrentSite]);

  const handleEdit = useCallback((mapId) => router.push(`/maps/edit/${mapId}`), [router]);

  const handleManage = useCallback((mapId) => router.push(`/sites/organize/${mapId}`), [router]);

  const handleToggle = useCallback(async (mapId) => {
    const specialOffer = maps && maps.find((s) => s.id === mapId);

    await update(specialOffer.id, { ...specialOffer, active: !specialOffer.active });
    await fetchMaps();
  }, [update, fetchMaps, maps]);

  /* const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      Cell: ({ row: { original: { id, name } } }) => (
        <span className="table-link" onClick={() => router.push(`/maps/edit/${id}`)}>
          <TranslatedText value={name} />
        </span>
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      Cell: ({ row: { original: { site } } }) => (
        <span>
          {site?.name}
        </span>
      ),
    },
    {
      Header: t('common.status'),
      accessor: 'active',
      Cell: ({ row: { original: { active, id } } }) => (
        <Button
          onClick={() => handleToggle(id)}
          icon={active ? 'fa-check' : 'fa-times'}
          color={active ? 'success' : 'danger'}
          transparent
        />
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('maps.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, currentSite, handleToggle, handleEdit, handleDelete, router]);
  */

  useEffect(() => {
    fetchMaps();
  }, [fetchMaps]);

  return (
    <>
      <PageHeader
        title={t('menu.listMaps')}
        link={{ url: '/maps/add', icon: 'map', label: t('maps.addMap') }}
      />
      <section className="section list-page">
        {/*
        <Table
          data={maps}
          columns={columns}
          fetchData={fetchMaps}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setMapFilter}
          itemsCount={itemsCount}
        />
        */}
        <div className="map-cards">
          {Boolean(maps?.length) && maps.map((map) => {
            const backImage = map?.backgroundImage ? (
              process.env.REACT_APP_API_URL + map.backgroundImage.url)
              : '/assets/haut-de-plage.png';

            return (
              <div className="map-card-item" key={`map-${map.id}`}>
                <div
                  className="map-card-background"
                  onClick={() => handleManage(map.id)}
                  style={{
                    backgroundImage: `url(${backImage})`,
                  }}
                />
                <div className="map-card-bottom">
                  <div className="map-card-bottom-left">

                    <TranslatedText value={map.name} />

                    {!currentSite && (
                    <span>
                      {' '}
                      {map.site?.name}
                    </span>
                    )}
                  </div>
                  <div className="map-card-bottom-right">
                    <Button
                      onClick={() => handleToggle(map.id)}
                      icon={map.active ? 'fa-check' : 'fa-times'}
                      color={map.active ? 'success' : 'danger'}
                      transparent
                    />
                    <Button
                      onClick={() => handleEdit(map.id)}
                      icon="fa-cog"
                      color="primary"
                      transparent
                    />
                    <Button
                      onClick={() => handleManage(map.id)}
                      icon="fa-brush"
                      color="primary"
                      transparent
                    />
                    <Button
                      onClick={() => handleDelete(map.id)}
                      icon="fa-trash-alt"
                      color="danger"
                      confirm
                      transparent
                      confirmMessage={t('maps.confirmDelete')}
                    />
                  </div>

                </div>

              </div>
            );
          })}

          <div
            className="map-card-item map-card-item-new"
            onClick={() => router.push('/maps/add')}
          >

            <i className="fas fa-plus" />

          </div>
        </div>
      </section>
    </>
  );
};

export default MapsList;
