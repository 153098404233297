import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import { AlertsProvider } from 'common/contexts/alerts';
import { MenuProvider } from 'common/contexts/menu';
import { AuthProvider } from 'common/contexts/auth';
import { UsersProvider } from 'users/contexts/users';
import { CustomersProvider } from 'customers/contexts/customers';
import { SitesProvider } from 'sites/contexts/sites';
import { CategoriesProvider } from 'categories/contexts/categories';
import { SocketProvider } from 'realtime/contexts/socket';
import { ProductsProvider } from 'products/contexts/products';
import { OptionsProvider } from 'products/contexts/options';
import { CategoryServicesProvider } from 'categoryServices/contexts/categoryServices';
import { ServicesProvider } from 'services/contexts/services';
import { LocationsProvider } from 'aroundMe/contexts/locations';
import { CategoryItemsProvider } from 'categoryItems/contexts/categoryItems';
import { FurnituresProvider } from 'furnitures/contexts/furnitures';
import { OrdersProvider } from 'orders/contexts/orders';
import { BeachEditorProvider } from 'sites/pages/BeachEditor/contexts/beachEditor';
import { BeachVisualizerProvider } from 'sites/pages/BeachVisualizer/contexts/beachVisualizer';
import { BookingsProvider } from 'bookings/contexts/bookings';
import { CallsProvider } from 'calls/contexts/calls';
import { SeatsProvider } from 'common/contexts/seats';
import { WaiterAreasProvider } from 'waiterAreas/contexts/waiterAreas';
import { PriceAreasProvider } from 'priceAreas/contexts/priceAreas';
import { SpecialOffersProvider } from 'specialOffers/contexts/specialOffers';
import { MapsProvider } from 'maps/contexts/maps';
import { VatProvider } from 'vat/contexts/vat';
import { ReportsProvider } from 'reports/contexts/reports';

import { loader } from 'common/components/PageLoader/PageLoader';
import { SeasonsProvider } from 'seasons/context/seasons';
import { SitesListProvider } from 'sitesList/contexts/sitesList';
import { DecorationsProvider } from 'decorations/contexts/decorations';
import { CustomerCategoriesProvider } from 'customers/contexts/customerCategories';
import { WatchesProvider } from 'watches/contexts/watches';
import { CallTypesProvider } from 'callTypes/context/callTypes';

const AppProvider = ({ children }) => (
  <Suspense fallback={loader()}>
    <AlertsProvider>
      <VatProvider>
        <SitesListProvider>
          <SitesProvider>
            <AuthProvider>
              <MenuProvider>
                <UsersProvider>
                  <WatchesProvider>
                    <CustomerCategoriesProvider>
                      <CustomersProvider>
                        <CategoriesProvider>
                          <OptionsProvider>
                            <ProductsProvider>
                              <CategoryServicesProvider>
                                <ServicesProvider>
                                  <BookingsProvider>
                                    <CallsProvider>
                                      <OrdersProvider>
                                        <MapsProvider>
                                          <CategoryItemsProvider>
                                            <SeatsProvider>
                                              <FurnituresProvider>
                                                <DecorationsProvider>
                                                  <LocationsProvider>
                                                    <BeachEditorProvider>
                                                      <BeachVisualizerProvider>
                                                        <WaiterAreasProvider>
                                                          <PriceAreasProvider>
                                                            <ReportsProvider>
                                                              <SocketProvider>
                                                                <SpecialOffersProvider>
                                                                  <SeasonsProvider>
                                                                    <CallTypesProvider>
                                                                      {children}
                                                                    </CallTypesProvider>
                                                                  </SeasonsProvider>
                                                                </SpecialOffersProvider>
                                                              </SocketProvider>
                                                            </ReportsProvider>
                                                          </PriceAreasProvider>
                                                        </WaiterAreasProvider>
                                                      </BeachVisualizerProvider>
                                                    </BeachEditorProvider>
                                                  </LocationsProvider>
                                                </DecorationsProvider>
                                              </FurnituresProvider>
                                            </SeatsProvider>
                                          </CategoryItemsProvider>
                                        </MapsProvider>
                                      </OrdersProvider>
                                    </CallsProvider>
                                  </BookingsProvider>
                                </ServicesProvider>
                              </CategoryServicesProvider>
                            </ProductsProvider>
                          </OptionsProvider>
                        </CategoriesProvider>
                      </CustomersProvider>
                    </CustomerCategoriesProvider>
                  </WatchesProvider>
                </UsersProvider>
              </MenuProvider>
            </AuthProvider>
          </SitesProvider>
        </SitesListProvider>
      </VatProvider>
    </AlertsProvider>
  </Suspense>
);

AppProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppProvider;
