import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import MenuContext from 'common/contexts/menu';

import Alerts from 'common/components/Alerts/Alerts';
import Menu from 'common/components/Menu/Menu';
import useAuth from 'common/contexts/auth';

const Layout = ({ children }) => {
  const { user } = useAuth();
  const { menuActive } = useContext(MenuContext);

  return user ? (
    <>
      <Menu />
      <main className={`${menuActive && 'menu-open'}`}>
        <Alerts />
        {children}
      </main>
    </>
  ) : (
    <>
      <Alerts />
      {children}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
