import React, {
  useState, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import fetchJSON from 'common/utils/fetchJSON';
import AlertsContext from 'common/contexts/alerts';
import Select from '../Select/Select';

// filters: ['paramKey']

export const RemoteSelect = ({
  onDataLoaded, url, filters, processOptions,
  additionnalOptions, excludeItemIds, ...rest
}) => {
  const [initDone, setInitDone] = useState(false);
  const [options, setOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [params, setParams] = useState({});
  const { setAlert } = useContext(AlertsContext);

  let paramsUpdated = false;
  const newParams = {};

  filters.forEach((f) => {
    newParams[f] = rest[f];
    if (newParams[f] !== params[f]) {
      paramsUpdated = true;
    }
  });

  if (paramsUpdated) {
    setParams(newParams);
  }

  useEffect(() => {
    const fetchItems = async () => {
      setIsFetching(true);
      try {
        const esc = encodeURIComponent;
        const queryParams = Object.keys(params)
          .filter((key) => params[key] != null)
          .map((key) => `${esc(key)}=${esc(params[key])}`).join('&');

        let res = await fetchJSON({
          url: `${url}${queryParams ? '?' : ''}${queryParams}`,
          method: 'GET',
        });

        if (excludeItemIds.length) {
          res = res.filter(({ id }) => !excludeItemIds.includes(id));
        }

        onDataLoaded && onDataLoaded(res);

        if (additionnalOptions.length && Array.isArray(res)) {
          res = [...additionnalOptions, ...res];
        }

        const opts = processOptions ? processOptions(res) : res;

        setOptions(opts);
        setInitDone(true);
        return res;
      } catch (e) {
        setAlert(e.message, 'danger');
      } finally {
        setIsFetching(false);
      }
    };

    fetchItems();
  }, [setAlert, setIsFetching, setOptions, additionnalOptions, url,
    processOptions, params, onDataLoaded, excludeItemIds]);

  useEffect(() => {
    if (initDone && rest?.onChange && rest?.value) {
      if (Array.isArray(rest.value)) {
        const newValues = rest.value.filter((v) => {
          const found = options.find((o) => o.value === v);

          return Boolean(found);
        });

        if (newValues.length !== rest.value.length) {
          rest.onChange(newValues);
        }
      } else {
        const found = options.find((o) => o.value === rest.value);

        if (!found) {
          rest.onChange(null);
        }
      }
    }
  }, [rest, options, initDone]);

  return (
    <Select
      options={options}
      isLoading={isFetching}
      {...rest}
    />
  );
};

RemoteSelect.propTypes = {
  url: PropTypes.string.isRequired,
  filters: PropTypes.array,
  excludeItemIds: PropTypes.array,
  processOptions: PropTypes.func,
  onDataLoaded: PropTypes.func,
  additionnalOptions: PropTypes.array,
};

RemoteSelect.defaultProps = {
  filters: [],
  excludeItemIds: [],
  processOptions: () => {},
  onDataLoaded: () => {},
  additionnalOptions: [],
};

export default RemoteSelect;
