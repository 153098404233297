export const getUserDisplayName = (user = {}) => {
  if (user.username) {
    return user.username;
  }
  if (user.name) {
    return user.name;
  }
  return user.email;
};

export default getUserDisplayName;
