import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const SpecialOfferContext = createContext();

export const SpecialOffersProvider = ({ children }) => (
  <ContextProvider url="special-offers" context={SpecialOfferContext}>{children}</ContextProvider>
);

SpecialOffersProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useSpecialOffer = () => useContext(SpecialOfferContext);

export default useSpecialOffer;
