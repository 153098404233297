import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const CustomerCategoryContext = createContext();

export const CustomerCategoriesProvider = ({ children }) => (
  <ContextProvider url="customer-categories" context={CustomerCategoryContext}>{children}</ContextProvider>
);

CustomerCategoriesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useCustomerCategory = () => useContext(CustomerCategoryContext);

export default useCustomerCategory;
