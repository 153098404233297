import React from 'react';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

const processOptions = (options) => options.map(({ id, name }) => (
  { value: id, label: <TranslatedText value={name} /> }
));

const ServiceSelect = (props) => (
  <RemoteSelect
    filters={['id_ne']}
    url="services"
    processOptions={processOptions}
    {...props}
  />
);

export default ServiceSelect;
