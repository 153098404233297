import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'common/hooks/use-router';
import Table from 'common/components/Table/Table';
import Button from 'common/components/Button/Button';
import useVat from 'vat/contexts/vat';
import PageHeader from 'common/components/PageHeader/PageHeader';

const VatList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    fetchItems: fetchVats, items: vats, isFetching, pageCount, remove, filters, setFilter,
    itemsCount,
  } = useVat();

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    fetchVats();
  }, [remove, fetchVats]);
  const handleEdit = useCallback((row) => router.push(`/vat/edit/${row.original.id}`), [router]);

  const columns = useMemo(() => [
    {
      Header: t('vat.percentage'),
      accessor: 'percentage',
      filterName: 'percentage_contains',
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('vat.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, handleEdit, handleDelete]);

  return (
    <>
      <PageHeader
        title={t('menu.listVats')}
        link={{ url: '/vat/add', icon: 'user-plus', label: t('vat.addVat') }}
      />
      <section className="section list-page">
        <Table
          data={vats}
          columns={columns}
          fetchData={fetchVats}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default VatList;
