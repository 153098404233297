import React from 'react';
import PropTypes from 'prop-types';

import { createCssUrl } from 'common/utils/createUrl';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import formatPrice from 'common/utils/formatPrice';
import useSite from 'sites/contexts/sites';
import NotFoundImage from '../../../assets/image-not-found.jpg';

const OrderItem = ({ item }) => {
  const { item: currentSite } = useSite();

  return (
    <>
      <div className="order-details--item">
        <div
          className="order-details--item-img"
          style={{
            backgroundImage: item.product?.images[0]?.url || item.service?.images[0].url
              ? createCssUrl(item.product?.images[0]?.url
                ? item.product?.images[0]?.url
                : item.service?.images[0]?.url)
              : `url(${NotFoundImage})`,
          }}
          alt={item.product.name['en-GB']}
        />
        <div className="order-details--item-content">
          <div>
            <span className="order-details--item-name">
              <TranslatedText value={item.product ? item.product.name : item.service.name} />
            </span>
            <span className="order-details--item-quantity">
              {`${item.quantity} x ${item.product
                ? formatPrice(item.product.price, currentSite?.currency)
                : formatPrice(item.service.price, currentSite?.currency)}`}
            </span>
          </div>
          <span className="order-details--item-total">
            {`${item.quantity * (item.product
              ? formatPrice(item.product?.price, currentSite?.currency)
              : formatPrice(item.service?.price, currentSite?.currency))}`}
          </span>
        </div>
        { item.comments && (
        <div className="order-details--comments">
          {item.comments}
        </div>
        ) }
      </div>
      <div>
        {item.selected_options.map((selected) => (
          <div style={{ padding: '0 .5rem 0 2rem' }}>
            {selected.variants.map((variant) => (
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <TranslatedText value={variant.name} />
                <span>
                  {`${variant.price ? formatPrice(variant.price, currentSite?.currency)
                    : formatPrice(0, currentSite?.currency)}`}

                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>

  );
};

OrderItem.propTypes = {
  item: PropTypes.shape.isRequired,
};

export default OrderItem;
