import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Select from 'common/components/Select/Select';
import { useTranslation } from 'react-i18next';
import useAuth from 'common/contexts/auth';
import useUser from 'users/contexts/users';

const LanguagePicker = ({ onChange, ...rest }) => {
  const { t, i18n } = useTranslation();
  const { user: currentUser } = useAuth();
  const { item: user } = useUser();

  const pickerOptions = [
    {
      value: 'en-GB',
      label: t('common.english'),
    },
    {
      value: 'fr-FR',
      label: t('common.french'),
    },
  ];

  const handleChange = useCallback((value) => {
    onChange(value);
    if (user?.id === currentUser.id) {
      i18n.changeLanguage(value);
    }
  }, [onChange, i18n, user, currentUser]);

  return (
    <Select
      placeholder={t('common.selectLanguage')}
      options={pickerOptions}
      onChange={handleChange}
      {...rest}
    />
  );
};

LanguagePicker.propTypes = {
  onChange: PropTypes.func,
};

LanguagePicker.defaultProps = {
  onChange() {},
};

export default LanguagePicker;
