/* eslint-disable object-curly-newline */
import Button from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import AlertsContext from 'common/contexts/alerts';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useBeachEditor from 'sites/pages/BeachEditor/contexts/beachEditor';
import useWaiterArea from 'waiterAreas/contexts/waiterAreas';
import WaiterAreaForm from 'waiterAreas/pages/WaiterAreaFormPage/WaiterAreaForm';
import ContextualEditor from './ContextualEditor';

const WaiterAreaSelector = () => {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const {
    save, update, remove, fetchItems: fetchWaiterAreas, items: waiterAreas,
  } = useWaiterArea();

  const {
    updateSeatInformation, site,
    selectedSeats, setIncrementationSeat, incrementationSeat,
  } = useBeachEditor();

  const [showModal, setShowModal] = useState(false);
  const [selectedWaiterAreaModal, setSelectedWaiterAreaModal] = useState(null);

  const handleOpenModal = useCallback((waiterArea) => {
    setSelectedWaiterAreaModal(waiterArea);
    setShowModal(true);
  }, [setShowModal]);

  const handleDelete = useCallback(async (row) => {
    await remove(row.id);
    await fetchWaiterAreas();
  }, [remove, fetchWaiterAreas]);

  const handleSubmitModal = useCallback(async (data) => {
    const payload = { ...data };

    if (data.id) {
      const res = await update(payload.id, payload);

      if (!res.error) {
        setAlert(t('waiterAreas.successEdit'), 'success');
        fetchWaiterAreas();
        setShowModal(false);
      } else {
        setAlert(res.message, 'danger');
      }
    } else {
      const res = await save(payload);

      if (!res.error) {
        setAlert(t('waiterAreas.successAdd'), 'success');
        fetchWaiterAreas();
        setShowModal(false);
      } else {
        setAlert(res.message, 'danger');
      }
    }
  }, [fetchWaiterAreas, save, setAlert, t, update]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  useEffect(() => {
    fetchWaiterAreas();
  }, [fetchWaiterAreas]);

  useEffect(() => {
    if (waiterAreas?.length) {
      updateSeatInformation('waiter_area', waiterAreas);
    }
  }, [waiterAreas, updateSeatInformation]);

  return (
    <>
      <div className="selector-panel">
        { showModal && (
        <Modal confirmModal={() => {}} closeModal={handleCloseModal}>
          <WaiterAreaForm
            site={site.id}
            onSubmit={handleSubmitModal}
            onDelete={handleDelete}
            waiterArea={selectedWaiterAreaModal}
          />
        </Modal>
        )}

        <div className="editor-title-add">
          <b>{t('menu.waiterAreas')}</b>
          <Button
            color="primary"
            label={t('editor.titleAddType')}
            icon="fa-plus"
            onClick={handleOpenModal}
            className="editor-add-type"
          />
        </div>

        <div className="selectors">
          { waiterAreas && waiterAreas.map((item, index) => (
            <div
              key={index}
              alt={item.name}
              className={`selector ${item.id === incrementationSeat?.waiter_area?.id && 'selected'}`}
              onClick={
                () => {
                  setIncrementationSeat((current) => ({ ...current, waiter_area: item }));
                }
              }
            >
              <div className="circleSelectorWrapper">
                <div
                  className="circleSelector"
                  style={{ backgroundColor: item.color }}
                />
              </div>
              <div className="selectorName">
                { item.name }
                { item.id === incrementationSeat?.waiter_area?.id && (
                <p>
                  {t('editor.doubleClicOnMapToAdd')}
                </p>
                ) }
              </div>
              <div className="actionsButton">
                <Button
                  onClick={() => handleOpenModal(item)}
                  icon="fa-pencil-alt"
                  color="primary"
                  transparent
                />
              </div>

            </div>
          ))}

        </div>
      </div>
      { Boolean(selectedSeats.length) && (
      <ContextualEditor />
      )}
    </>
  );
};

export default WaiterAreaSelector;
