import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export const BeachVisualizerContext = createContext();

export const BeachVisualizerProvider = ({ children }) => {
  const [seats, setSeats] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [seatModal, setSeatModal] = useState(null);
  const [bookingModal, setBookingModal] = useState(null);
  const [date, setDate] = useState(moment().utc().startOf('day').format());
  const [stayDuration, setStayDuration] = useState('fullday');
  const [searchSeat, setSearchSeat] = useState('');

  const handleClickonSquare = useCallback((item, bookingId) => {
    if (bookingId) {
      setBookingModal(bookingId);
      setSeatModal(item);
    } else if (item.furniture && item.price_area) {
      setSelectedSeats((previousSelectedSeats) => {
        let newSeats = [...previousSelectedSeats];

        if (previousSelectedSeats.find(({ id }) => id.toString() === item.id.toString()) === undefined) {
          newSeats.push(item);
        } else {
          newSeats = newSeats.filter(({ id }) => id !== item.id);
        }
        return newSeats;
      });
    }
  }, []);

  const removeSelectedSeat = useCallback((item) => {
    setSelectedSeats((previousSelectedSeats) => previousSelectedSeats.filter(({ id }) => id !== item.id));
  }, []);

  const value = useMemo(() => ({
    date,
    setDate,
    stayDuration,
    setStayDuration,
    seats,
    setSeats,
    bookingModal,
    setBookingModal,
    seatModal,
    searchSeat,
    setSearchSeat,
    setSeatModal,
    selectedSeats,
    setSelectedSeats,
    removeSelectedSeat,
    handleClickonSquare,
  }), [
    date,
    stayDuration,
    seats,
    seatModal,
    bookingModal,
    searchSeat,
    selectedSeats,
    removeSelectedSeat,
    handleClickonSquare,
  ]);

  return (
    <BeachVisualizerContext.Provider value={value}>
      {children}
    </BeachVisualizerContext.Provider>
  );
};

BeachVisualizerProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default () => useContext(BeachVisualizerContext);
