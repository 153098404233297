/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

const processOptions = (options) => options.map(({ id, name }) => (
  { value: id, label: <TranslatedText value={name} displayEnglish /> }
));

const CategoryServiceSelect = (props) => {
  const { t } = useTranslation();

  return (
    <RemoteSelect
      filters={['site']}
      isFilter={props.isFilter}
      placeholder={t('services.category')}
      url="category-services"
      processOptions={processOptions}
      {...props}
    />
  );
};

export default CategoryServiceSelect;
