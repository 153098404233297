import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const WatchContext = createContext();

export const WatchesProvider = ({ children }) => (
  <ContextProvider url="watches" context={WatchContext}>{children}</ContextProvider>
);

WatchesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useWatch = () => useContext(WatchContext);

export default useWatch;
