import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const MapContext = createContext();

export const MapsProvider = ({ children }) => (
  <ContextProvider url="maps" context={MapContext}>{children}</ContextProvider>
);

MapsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useMap = () => useContext(MapContext);

export default useMap;
