import React from 'react';
import PropTypes from 'prop-types';

import { createCssUrl } from 'common/utils/createUrl';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import formatPrice from 'common/utils/formatPrice';
import useSite from 'sites/contexts/sites';

const OrderItemService = ({ item }) => {
  const { item: currentSite } = useSite();

  return (
    <div className="order-details--item">
      <div
        className="order-details--item-img"
        style={{ backgroundImage: createCssUrl(item.service.images[0]?.url) }}
        alt={item.service.name['en-GB']}
      />
      <div className="order-details--item-content">
        <div>
          <span className="order-details--item-name"><TranslatedText value={item.service.name} /></span>
          <span className="order-details--item-quantity">
            {`${item.quantity} x 
        ${formatPrice(item.service.price, currentSite?.currency)}`}

          </span>
        </div>
        <span className="order-details--item-total">
          {`${formatPrice(item.quantity * item.service.price, currentSite?.currency)}`}

        </span>
      </div>
    </div>
  );
};

OrderItemService.propTypes = {
  item: PropTypes.shape.isRequired,
};

export default OrderItemService;
