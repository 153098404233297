import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import FormInput from 'common/components/FormInput/FormInput';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';

import useSite from 'sites/contexts/sites';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import Button from 'common/components/Button/Button';
import fetchJSON from 'common/utils/fetchJSON';

const CatalogCategoryForm = ({ onSubmit, category }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();

  const handleTranslate = async (values, setFieldValue) => {
    let translated;
    const { name } = values;

    if (name) {
      translated = await fetchJSON({
        url: 'sites/translate',
        method: 'POST',
        payload: { items: { name }, site: null },
      });

      setFieldValue({
        field: 'name',
        value: translated.name,
      });
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={category?.id ? {
        ...category,
        site: category?.site?.id,
        parent: category?.parent?.id,
      } : { site: currentSite ? currentSite.id : null }}
      mutators={{
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      render={({
        values, handleSubmit, submitting, pristine, form,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />
          <Button
            style={{ marginBottom: '2rem' }}
            label="Translate"
            icon="fa-language"
            color="primary"
            confirm
            confirmMessage={t('common.translateConfirm')}
            onClick={() => handleTranslate(values, form.mutators.setFieldValue)}
          />
          <MultilingualInput
            name="name"
            label={t('common.name')}
            icon="heading"
            value={values.name}
            defaultLang="fr-FR"
            required
          />
          <FormInput
            name="reference"
            label={t('common.reference')}
            icon="tag"
          />
          <FormInput
            type="submit"
            label={t('common.save')}
            icon="tag"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

CatalogCategoryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  category: PropTypes.object,
};

CatalogCategoryForm.defaultProps = {
  category: {},
};

export default CatalogCategoryForm;
