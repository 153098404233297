/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';
import useTrans from 'common/hooks/use-trans';

const OptionSelect = (props) => {
  const { translateText } = useTrans();

  const processOptions = useCallback((options) => {
    let referenceOpt = false;

    let opts = options.map((option) => {
      if (props.opt?.id === option.id) {
        referenceOpt = true;
      }

      return ({
        value: option.id,
        label: translateText(option.name),
        option,
      });
    });

    if (!referenceOpt && props.opt) {
      opts = [{
        value: props.opt?.id,
        label: translateText(props.opt?.name),
        option: props.opt,
      }, ...opts];
    }
    return opts;
  }, [props.opt, translateText]);

  return (
    <RemoteSelect
      filters={['site_null', 'site']}
      url={props.reference ? 'options/reference' : 'options'}
      processOptions={processOptions}
      {...props}
    />
  );
};

export default OptionSelect;
