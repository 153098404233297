/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import Table from 'common/components/Table/Table';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Button from 'common/components/Button/Button';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';

import useOption from 'products/contexts/options';

const OptionsList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    fetchItems: fetchOptions, items: options, isFetching, pageCount, remove, filters, setFilter,
    itemsCount,
  } = useOption();
  const { currentSite } = useSite();

  const handleEdit = useCallback((row) => router.push(`/options/edit/${row.original.id}`), [router]);
  const handleDelete = useCallback(async (id) => {
    await remove(id);
    fetchOptions();
  }, [fetchOptions, remove]);

  useEffect(() => {
    setFilter('site_null', 0);
  }, [setFilter]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { id, name } } }) => (
        <span className="table-link" onClick={() => router.push(`/options/edit/${id}`)}>
          <TranslatedText value={name} />
        </span>
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { site } } }) => (
        <span>
          {site?.name}
        </span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            // eslint-disable-next-line react/prop-types
            onClick={() => handleDelete(row.original.id)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('orders.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, currentSite, handleEdit, handleDelete, router]);

  return (
    <>
      <PageHeader
        title={t('menu.listOptions')}
        link={{ url: '/options/add', icon: 'plus', label: t('options.addOption') }}
      />
      <section className="section list-page">
        <Table
          data={options}
          columns={columns}
          fetchData={fetchOptions}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default OptionsList;
