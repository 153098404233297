const filterBySite = (apiUrl, url, method, role, siteId) => {
  let baseUrl = `${apiUrl}/${url}${url.includes('?') ? '&' : '?'}`;

  if (siteId && role === 'superadmin' && method === 'GET') {
    if (!url.includes('sites') && !url.includes('vats') && !url.includes('option-variants')) {
      if (url.includes('products')) {
        baseUrl += `category.site=${siteId}`;
      } else if (url.includes('locations')) {
        baseUrl += `category_item.site=${siteId}`;
      } else {
        baseUrl += `site=${siteId}`;
      }
    }
  }

  return baseUrl;
};

export const fetchJSON = async ({ url, method, payload }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = window.localStorage.getItem('jwt');
  const role = JSON.parse(window.localStorage.getItem('user'))?.role.name;
  const siteId = JSON.parse(window.localStorage.getItem('currentSite'))?.id;

  const headers = new Headers();

  headers.append('Content-Type', 'application/json');

  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  const res = await fetch(
    filterBySite(apiUrl, url, method, role, siteId),
    {
      method,
      headers,
      body: JSON.stringify(payload),
    },
  );

  return res.json();
};

export default fetchJSON;
