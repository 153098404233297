import DebounceInput from 'common/components/DebounceInput/DebounceInput';
import Loader from 'common/components/PageLoader/PageLoader';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { usePagination, useSortBy, useTable } from 'react-table';

const Table = ({
  data,
  columns,
  fetchData,
  isFetching,
  pageCount: controlledPageCount,
  filters,
  setFilter,
  itemsCount,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      manualPagination: true,
      initialState: { pageIndex: 0, pageSize: 20 },
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
  );

  const handleChangeFilter = useCallback(
    (name, value) => {
      // TODO ca va faire deux fetchData, mais super compliqué a optimiser,
      // il faut un debounce mais aussi modifier le fetchData pour que la fonction ne change jamais
      gotoPage(0);
      setFilter(name, value);
    },
    [gotoPage, setFilter],
  );

  React.useEffect(() => {
    fetchData({
      pageIndex,
      pageSize,
    });
  }, [fetchData, pageIndex, pageSize, filters]);

  const { t } = useTranslation();

  // buttons pagination

  let buttons = [];

  if (controlledPageCount <= 7) {
    buttons = Array.from({ length: controlledPageCount }).map(
      (l, index) => index,
    );
  } else if (pageIndex < 4) {
    buttons = [
      ...Array.from({ length: 5 }).map((l, index) => index),
      -1,
      controlledPageCount - 1,
    ];
  } else if (pageIndex > controlledPageCount - 5) {
    buttons = [
      0,
      -1,
      ...Array.from({ length: 5 }).map(
        (l, index) => index + controlledPageCount - 5,
      ),
    ];
  } else {
    buttons = [
      0,
      -1,
      pageIndex - 1,
      pageIndex,
      pageIndex + 1,
      -1,
      controlledPageCount - 1,
    ];
  }

  return (
    <div>
      <table className="table is-striped is-fullwidth" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => column.hidden ? null : (
                <th key={`column-${index}`}>
                  <span
                    className="th-label"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {!column.filterName && column.render('Header')}
                    {column.isSorted
                        && (column.isSortedDesc ? (
                          <i className="ml fas fa-sort-down" />
                        ) : (
                          <i className="ml fas fa-sort-up" />
                        ))}
                  </span>
                  {column.filterName
                      && (column.filterComponent ? (
                        <column.filterComponent
                          value={filters[column.filterName]}
                          data-cy={column.filterName}
                          onChange={(value) => {
                            column.customFormat
                              ? handleChangeFilter(
                                column.filterName,
                                column.customFormat(value),
                              )
                              : handleChangeFilter(column.filterName, value);
                          }}
                        />
                      ) : (
                        <DebounceInput
                          className="input is-filter"
                          placeholder={column.render('Header')}
                          value={filters[column.filterName]}
                          onChange={(value) => handleChangeFilter(column.filterName, value)}
                        />
                      ))}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {!isFetching && page?.length < 1 && (
            <tr>
              <td style={{ position: 'relative', height: 300, justifyContent: 'center' }} colSpan="100">
                {t('common.noResult')}
              </td>
            </tr>
          )}
          {isFetching ? (
            <tr>
              <td style={{ position: 'relative', height: 300, justifyContent: 'center' }} colSpan="100">
                <Loader />
              </td>
            </tr>
          ) : (
            page.map((row) => {
              prepareRow(row);

              return (
                <tr data-tr={row.name} {...row.getRowProps()}>
                  {row.cells.map((cell) => cell.column.hidden ? null : (
                    <td
                      data-cy={row.name}
                      data-row={cell.column.name}
                      {...cell.getCellProps({
                        className: cell.column.className,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      <nav className="pagination is-right" style={{ justifyContent: 'flex-start' }}>

        {itemsCount > 0 && pageSize > 0 && (
        <div className="pagination-results-counter">

          <p>
            <span>
              {1 + pageIndex * pageSize}
            </span>
            /
            <span>
              {itemsCount < pageIndex * pageSize + pageSize
                ? itemsCount
                : pageIndex * pageSize + pageSize}
            </span>
            {' '}
            {t('common.of')}
            {' '}
            <span>
              {itemsCount}
            </span>
            {' '}
            {t('common.results')}
          </p>
        </div>
        )}

        <button
          onClick={() => {
            gotoPage(0);
          }}
          disabled={!canPreviousPage || isFetching}
          aria-label="first page"
          className="pagination-link"
        >
          <span className="icon">
            <i className="fas fa-angle-double-left" />
          </span>
        </button>

        <button
          onClick={() => {
            previousPage();
          }}
          disabled={!canPreviousPage || isFetching}
          aria-label="previous page"
          className="pagination-link"
        >
          <span className="icon">
            <i className="fas fa-angle-left" />
          </span>
        </button>

        {buttons.map((i) => i === -1 ? (
          <span key={`page-ellipsis-${i}`}>...</span>
        ) : (
          <button
            key={`page-${i}`}
            disabled={isFetching}
            onClick={() => {
              gotoPage(i);
            }}
            className={`pagination-link ${
              pageIndex === i && 'is-current'
            }`}
          >
            <span
              style={{
                padding: `0 ${(i + 1).toString().length >= 3 ? 4 : 0}px`,
              }}
            >
              {i + 1}
            </span>
          </button>
        ))}

        <button
          onClick={() => {
            nextPage();
          }}
          disabled={!canNextPage || isFetching}
          aria-label="next page"
          className="pagination-link"
        >
          <span className="icon">
            <i className="fas fa-angle-right" />
          </span>
        </button>

        <button
          onClick={() => {
            gotoPage(pageOptions.length - 1);
          }}
          disabled={!canNextPage || isFetching}
          aria-label="last page"
          className="pagination-link"
        >
          <span className="icon">
            <i className="fas fa-angle-double-right" />
          </span>
        </button>
      </nav>
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  fetchData: PropTypes.func,
  isFetching: PropTypes.bool,
  pageCount: PropTypes.number,
  filters: PropTypes.object,
  setFilter: PropTypes.func,
  itemsCount: PropTypes.number,
};

Table.defaultProps = {
  filters: {},
  setFilter() {},
  fetchData() {},
  isFetching: false,
  pageCount: 1,
  itemsCount: 0,
};

export default Table;
