import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import FormInput from 'common/components/FormInput/FormInput';

const VatForm = ({ onSubmit, vat }) => {
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={vat}
      render={({
        handleSubmit,
        submitting,
        pristine,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormInput
            type="number"
            name="percentage"
            label={t('vat.percentage')}
            icon="money-bill"
          />
          <FormInput
            type="submit"
            label={t('common.save')}
            icon="percentage"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

VatForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  vat: PropTypes.object,
};

VatForm.defaultProps = {
  vat: {},
};

export default VatForm;
