import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';
import ContextProvider from 'common/contexts/crud';
import fetchJSON from 'common/utils/fetchJSON';
import AlertsContext from 'common/contexts/alerts';
import useSite from 'sites/contexts/sites';

const ProductContext = createContext();

export const ProductsProvider = ({ children }) => {
  const [isImportFetching, setIsImportFetching] = useState(false);
  const { setAlert } = useContext(AlertsContext);
  const { currentSite } = useSite();

  const importProducts = useCallback(async (products, category) => {
    setIsImportFetching(true);
    try {
      const res = await fetchJSON({
        url: 'products/import',
        method: 'POST',
        payload: { products, category, site: currentSite.id },
      });

      if (res.error) {
        throw new Error('Error importing');
      }
      return res;
    } catch (e) {
      throw new Error('Error importing');
    } finally {
      setIsImportFetching(false);
    }
  }, [currentSite]);

  const importProductFromCSV = useCallback(async (products) => {
    // remove empty line
    const filteredProducts = products.filter((p) => p.reference !== undefined && p.reference !== '');

    console.log({ filteredProducts });
    setIsImportFetching(true);
    try {
      const res = await fetchJSON({
        url: 'products/import-csv',
        method: 'POST',
        payload: { products: filteredProducts, site: currentSite },
      });

      if (res.error) {
        throw new Error('Error importing');
      }
      return res;
    } catch (e) {
      throw new Error('Error importing');
    } finally {
      setIsImportFetching(false);
    }
  }, [currentSite]);

  const exportProductsToCSV = useCallback(async () => {
    try {
      const res = await fetchJSON({
        url: 'products/export-csv',
        method: 'POST',
        payload: { site: currentSite.id },
      });

      if (res.error) {
        throw new Error('Error exporting');
      }
      return res;
    } catch (e) {
      throw new Error('Error exporting');
    } finally {
      setIsImportFetching(false);
    }
  }, [currentSite]);

  const getRef = useCallback(async (filters = {}) => {
    const params = {
      ...filters,
    };

    const esc = encodeURIComponent;
    const queryParams = Object.keys(params).map((key) => `${esc(key)}=${esc(params[key])}`).join('&');

    setIsImportFetching(true);
    try {
      const res = await fetchJSON({ url: `products/reference${queryParams ? '?' : ''}${queryParams}`, method: 'GET' });

      return res;
    } catch (e) {
      setAlert(e.message, 'danger');
    } finally {
      setIsImportFetching(false);
    }
  }, [setAlert]);

  const value = useMemo(() => ({
    isImportFetching,
    importProducts,
    importProductFromCSV,
    exportProductsToCSV,
    getRef,
  }), [
    isImportFetching,
    importProducts,
    importProductFromCSV,
    exportProductsToCSV,
    getRef,
  ]);

  return (
    <ContextProvider
      url="products"
      value={value}
      context={ProductContext}
    >
      {children}
    </ContextProvider>
  );
};

ProductsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useProduct = () => useContext(ProductContext);

export default useProduct;
