import React, { useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import PageHeader from 'common/components/PageHeader/PageHeader';
import useUser from 'users/contexts/users';

import PageLoader from 'common/components/PageLoader/PageLoader';
import UserForm from './UserForm';

const UserFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: user, isFetching,
  } = useUser();
  const { id: userId } = router.match.params;

  useEffect(() => { fetchItem(userId); }, [fetchItem, userId]);

  const onSubmit = useCallback(async (user) => {
    if (user.id) {
      const res = await update(user.id, user);

      if (!res.error) {
        setAlert(t('users.successEdit'), 'success');
        router.push('/users');
      } else {
        res.message.forEach((error) => {
          error.messages.forEach((message) => {
            if (message.id === 'Auth.form.error.email.taken') {
              setAlert(t('users.emailAlreadyTaken'), 'danger');
            } else {
              setAlert(t('users.errorFormUser'), 'danger');
            }
          });
        });
      }
    } else {
      const res = await save({ ...user, confirmed: true });

      if (!res.error) {
        setAlert(t('users.successAdd'), 'success');
        router.push('/users');
      } else {
        res.message.forEach((error) => {
          error.messages.forEach((message) => {
            if (message.id === 'Auth.form.error.email.taken') {
              setAlert(t('users.emailAlreadyTaken'), 'danger');
            } else {
              setAlert(t('users.errorFormUser'), 'danger');
            }
          });
        });
      }
    }
  }, [router, update, save, setAlert, t]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`users.${user && user.id ? 'edit' : 'add'}User`)}
        subLink={{ url: '/users', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <UserForm
          onSubmit={onSubmit}
          user={user}
        />
      </section>
    </>
  );
};

export default UserFormPage;
