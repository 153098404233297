import React, { useCallback } from 'react';
import { QrReader } from 'react-qr-reader';
import Modal from 'common/components/Modal/Modal';
import { useTranslation } from 'react-i18next';

const QrCodeReaderModal = ({ showModal, setShowModal, handleSuccess }) => {
  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const { t } = useTranslation();

  return (
    showModal && (
      <Modal confirmModal={() => {}} closeModal={handleCloseModal}>
        <>
          <h2 className="is-size-4 has-text-centered ">{t('watch.scanTitle')}</h2>
          <div style={{
            width: '400px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          >
            <QrReader
              onResult={(result, error) => {
                if (result) {
                  handleSuccess(result?.text ?? '', showModal);
                }

                if (error) {
                  console.info(error);
                }
              }}
            />
          </div>
          <h5 className="is-size-5 has-text-centered">
            {t('watch.scanInstructions')}
          </h5>
        </>
      </Modal>

    )
  );
};

export default QrCodeReaderModal;
