import React, { useContext } from 'react';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'common/contexts/auth';
import AlertsContext from 'common/contexts/alerts';
import FormInput from 'common/components/FormInput/FormInput';

const SignIn = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const { setAlert } = useContext(AlertsContext);

  const onSubmit = async ({ email, password }) => {
    const user = await auth.signin(email, password);

    if (user) {
      setAlert(t('auth.loginSuccess'), 'success');
    }
  };

  return (
    <section className="section login-page">
      <div className="logo" style={{ backgroundImage: 'url(/assets/logo.png)' }} />
      <Form
        onSubmit={onSubmit}
        initialValues={{ email: '', password: '' }}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <form onSubmit={handleSubmit} noValidate>
              <FormInput
                type="email"
                name="email"
                label={t('common.email')}
                icon="envelope"
              />
              <FormInput
                type="password"
                name="password"
                label={t('common.password')}
                icon="lock"
              />
              <div className="form-footer">
                <FormInput
                  className="submit-btn"
                  type="submit"
                  label={t('auth.login')}
                  icon="sign-in-alt"
                  disabled={submitting || pristine}
                />
                <p>
                  <Link to="/forgotpassword" className="lead">{t('auth.forgotPassword')}</Link>
                </p>
              </div>
            </form>
          </>
        )}
      />
    </section>
  );
};

export default SignIn;
