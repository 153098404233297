import React, { useContext } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'common/contexts/auth';
import AlertsContext from 'common/contexts/alerts';
import FormInput from 'common/components/FormInput/FormInput';
import useRouter from 'common/hooks/use-router';
import Button from 'common/components/Button/Button';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { forgotPassword } = useAuth();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const onSubmit = async (payload) => {
    await forgotPassword(payload);

    setAlert(t('auth.newPasswordSent'), 'success');

    router.push('/');
  };

  return (
    <section className="section login-page">
      <Form
        onSubmit={onSubmit}
        initialValues={{ email: '' }}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <FormInput
              type="email"
              name="email"
              label={t('common.email')}
              icon="envelope"
            />
            <Button
              type="submit"
              label={t('common.send')}
              icon="fa-sign-in-alt"
              onClick={handleSubmit}
              color="primary"
              disabled={submitting || pristine}
            />
          </>
        )}
      />
    </section>
  );
};

export default ForgotPassword;
