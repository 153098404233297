import React, { useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import PageHeader from 'common/components/PageHeader/PageHeader';

import PageLoader from 'common/components/PageLoader/PageLoader';
import useWatch from 'watches/contexts/watches';
import WatchForm from './WatchForm';

const WatchFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: watch, isFetching,
  } = useWatch();
  const { id: watchId } = router.match.params;

  useEffect(() => { fetchItem(watchId); }, [fetchItem, watchId]);

  const onSubmit = useCallback(async (watch) => {
    if (watch.id) {
      const res = await update(watch.id, watch);

      if (!res.error) {
        setAlert(t('watch.successEditWatch'), 'success');
        router.push('/watches');
      } else {
        setAlert(t('watch.errorFormWatch'), 'danger');
      }
    } else {
      const res = await save(watch);

      if (!res.error) {
        setAlert(t('watch.successAddWatch'), 'success');
        router.push('/watches');
      } else {
        setAlert(t('watch.errorFormWatch'), 'danger');
      }
    }
  }, [router, update, save, setAlert, t]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`watch.${watch && watch.id ? 'edit' : 'add'}Watch`)}
        subLink={{ url: '/watches', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <WatchForm
          onSubmit={onSubmit}
          watch={watch}
        />
      </section>
    </>
  );
};

export default WatchFormPage;
