/* eslint-disable object-curly-newline */
import useFurniture from 'furnitures/contexts/furnitures';
import usePriceArea from 'priceAreas/contexts/priceAreas';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useBeachEditor from 'sites/pages/BeachEditor/contexts/beachEditor';
import useWaiterArea from 'waiterAreas/contexts/waiterAreas';
import EditChoice from './EditChoice';
import SeatAvailabilityChoice from './SeatAvailabitiyChoice';

const ContextualEditor = () => {
  const { t } = useTranslation();
  const {
    mode, selectedSeats, setSelectedSeats,
  } = useBeachEditor();

  const { items: priceAreas } = usePriceArea();
  const { items: waiterAreas } = useWaiterArea();

  const {
    items: furnitures,
  } = useFurniture();

  return (
    <div className="selector-panel contextual-panel">
      <div className="editor-head">
        <p>
          <b>{selectedSeats.length}</b>
          {' '}
          {t('editor.selectedLength')}
        </p>

        <button onClick={() => setSelectedSeats([])} className="quit">
          <i className="fas fa-times" />
        </button>
      </div>

      {/* 1rst part */}

      {/* 2nd part */}

      {mode === 'furniture' && Boolean(furnitures?.length) && (
      <EditChoice type="furniture" choices={furnitures} />
      )}

      {mode === 'price' && Boolean(priceAreas?.length) && (
      <EditChoice type="price" choices={priceAreas} />
      )}

      {mode === 'waiter' && Boolean(waiterAreas?.length) && (
      <EditChoice type="waiter" choices={waiterAreas} />
      )}

      {/* todo : seats toggle options -if furniture- */}

      <SeatAvailabilityChoice />

    </div>
  );
};

export default ContextualEditor;
