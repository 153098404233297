/* eslint-disable react/prop-types */
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback, useEffect, useMemo, useState
} from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Button from 'common/components/Button/Button';
import DatePicker from 'common/components/DatePicker/DatePicker';
import Modal from 'common/components/Modal/Modal';
import PageHeader from 'common/components/PageHeader/PageHeader';
import RelativeDate from 'common/components/RelativeDate/RelativeDate';
import Table from 'common/components/Table/Table';
import { createUploadUrl } from 'common/utils/createUrl';
import getUserDisplayName from 'common/utils/getUserDisplayName';
import OrderDetails from 'orders/components/OrderDetails/OrderDetails';
import OrderOfflineMethodSelect from 'orders/components/OrderOfflineMethodSelect/OrderOfflineMethodSelect';
import OrderStateSelect from 'orders/components/OrderStateSelect/OrderStateSelect';
import useOrder from 'orders/contexts/orders';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';
import formatPrice from 'common/utils/formatPrice';

const ArchivedOrdersList = () => {
  const { t } = useTranslation();
  const { currentSite } = useSite();
  const {
    fetchItem: fetchOrder,
    fetchItems: fetchOrders,
    items: orders, isFetching,
    pageCount, remove, setFilters,
    filters, setFilter, setSort,
    updateOrderState,
    itemsCount,
  } = useOrder();
  const [modalActive, setModalActive] = useState(false);
  const [detailedOrder, setDetailedOrder] = useState(null);

  const openModal = useCallback(async (row) => {
    const order = await fetchOrder(row.original.id);

    setDetailedOrder(order);
    setModalActive(true);
  }, [fetchOrder]);

  const closeModal = () => {
    setModalActive(false);
  };

  const handleStateChange = async (id, value) => {
    const order = orders && orders.find((p) => p.id === id);

    if (order) {
      await updateOrderState(order.id, { state: value });
      await fetchOrders();
    }
  };

  const handleDelete = useCallback(async (id) => {
    await remove(id);
    await fetchOrders();
    closeModal();
  }, [fetchOrders, remove]);

  const columns = useMemo(() => [
    {
      Header: t('orders.user'),
      filterName: 'user.username_contains',
      Cell: ({ row: { original: { user, booking } } }) => (
        <span>
          {booking?.clientName || (user ? getUserDisplayName(user) : t('users.unregistered'))}
        </span>
      ),
    },
    {
      Header: t('bookings.booking'),
      filterName: 'booking.code_contains',
      Cell: ({ row: { original: { booking } } }) => (
        <span>
          {booking?.code}
        </span>
      ),
    },
    {
      Header: t('orders.date'),
      filterName: 'created_at',
      filterComponent: (props) => <DatePicker isFilter {...props} />,
      customFormat: (value) => value ? moment(value).utc().startOf('day').format() : '',
      Cell: ({ row: { original: { created_at: createdAt } } }) => (
        <RelativeDate value={createdAt} />
      ),
    },
    {
      Header: t('orders.amount'),
      filterName: 'price',
      Cell: ({ row: { original: { price } } }) => (
        <span>
          {`${formatPrice(price, currentSite?.currency)}`}
        </span>
      ),
    },
    {
      Header: t('orders.seatNumber'),
      filterName: 'seat.name',
      Cell: ({ row: { original: { seat, booking } } }) => {
        const s = seat || {};
        const b = booking || {};

        return (
          <>
            <span>
              {t('orders.seat', { seatNumber: s.name })}
            </span>
            <br />
            { b.roomID && (
            <span>
              {t('orders.room', { roomId: b.roomID })}
            </span>
            )}
          </>
        );
      },
    },
    {
      Header: t('invoices.invoiceNumber'),
      filterName: 'payment.invoiceNumber',
      Cell: ({ row: { original: { payment } } }) => {
        const pay = payment || {};
        const { invoiceNumber } = pay;

        return (
          <span>
            {invoiceNumber}
          </span>
        );
      },
    },
    {
      Header: t('orders.offlineMethod'),
      filterName: 'payment.offline_method',
      filterComponent: OrderOfflineMethodSelect,
      Cell: ({ row: { original: { payment } } }) => {
        const pay = payment || {};
        const { offline_method: offlineMethod } = pay;

        let icon = null;

        switch (offlineMethod) {
          case 'CASH': icon = 'money-bill'; break;
          case 'CARD': icon = 'credit-card'; break;
          case 'ROOM': icon = 'bed'; break;
          default: break;
        }

        return icon ? (
          <span>
            <i className={`has-text-primary fas fa-${icon}`} />
          </span>
        ) : (
          <></>
        );
      },
    },
    {
      Header: t('orders.state'),
      filterName: 'state',
      filterComponent: OrderStateSelect,
      Cell: ({ row: { original: { state } } }) => (
        <span className={`order-state-tag ${state === 'done' ? 'is-done' : ''}`}>
          <i className={`is-size-7 fas fa-${state === 'done' ? 'check' : 'hourglass'}`} />
          <span>
            {t(`orders.${state}`)}
          </span>
        </span>
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      Cell: ({ row: { original: { site } } }) => (
        <span>
          {site?.name}
        </span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => openModal(row)}
            icon="fa-eye"
            color="primary"
            transparent
          />
          {row.original?.payment?.invoicePath && (
            <Button
              onClick={() => window.open(createUploadUrl(row.original.payment.invoicePath))}
              icon="fa-file-invoice"
              color="primary"
              transparent
            />
          )}
          <Button
            onClick={() => handleDelete(row.original.id)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('orders.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, currentSite, openModal, handleDelete]);

  useEffect(() => {
    setFilters({});
    setSort('created_at:DESC');
  }, [setFilters, setSort]);

  return (
    <>
      <PageHeader title={t('menu.archivedListOrders')} />
      <section className="section list-page has-overflow-x-table">
        <Table
          data={orders}
          columns={columns}
          fetchData={fetchOrders}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
      {modalActive && (
        <Modal closeModal={closeModal}>
          <OrderDetails handleDelete={handleDelete} handleStateChange={handleStateChange} order={detailedOrder} />
        </Modal>
      )}
    </>
  );
};

export default ArchivedOrdersList;
