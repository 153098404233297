import Button from 'common/components/Button/Button';
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import Tabs, { Tab } from 'common/components/Tabs/Tabs';
import useAuth from 'common/contexts/auth';
import fetchJSON from 'common/utils/fetchJSON';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import useSite from 'sites/contexts/sites';
import SiteCompanyForm from './subforms/SiteCompanyForm';
import SitePresentationForm from './subforms/SitePresentationForm';
import SiteStoreForm from './subforms/SiteStoreForm';
import SiteSmsForm from './subforms/SiteSmsForm';

const SiteForm = ({ onSubmit, site }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();
  const { user } = useAuth();

  const handleTranslate = async (values, setFieldValue) => {
    let translated;
    const { description } = values;

    if (description) {
      translated = await fetchJSON({
        url: 'sites/translate',
        method: 'POST',
        payload: { items: { description }, site: currentSite.id },
      });

      setFieldValue({
        field: 'description',
        value: translated.description,
      });
    }
  };

  const handleDupplicateSchedule = (valueSchedules, dayKey, setFieldValue) => {
    const startToDupplicate = valueSchedules[dayKey].startHour;
    const endToDupplicate = valueSchedules[dayKey].endHour;
    // eslint-disable-next-line no-unused-vars
    const newSchedules = Object.entries(valueSchedules).map(([_key, value]) => ({
      ...value, startHour: startToDupplicate, endHour: endToDupplicate,
    }));

    setFieldValue({
      field: 'schedule',
      value: {
        monday: newSchedules[0],
        tuesday: newSchedules[1],
        wednesday: newSchedules[2],
        thursday: newSchedules[3],
        friday: newSchedules[4],
        saturday: newSchedules[5],
        sunday: newSchedules[6],
      },
    });
  };

  if (currentSite && site && site.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  // FIX : au 1er lancement, site.vat est un ID, mais si on enregistre sans toucher "values.vat", site.vat devient un {}
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={site?.id ? {
        ...site,
        vat: site.vat.id || site.vat || null,
        bookingVat: site.bookingVat?.id,
      } : {
        anonymousMode: true,
      }}
      mutators={{
        // eslint-disable-next-line no-unused-vars
        setGeolocation: ([_, geo], state, utils) => {
          utils.changeValue(state, 'lat', () => geo && geo.location?.lat());
          utils.changeValue(state, 'lng', () => geo && geo.location?.lng());
        },
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      render={({
        form,
        handleSubmit,
        submitting,
        pristine,
        values,
      }) => {
        const tabs = [
          {
            key: 'company',
            label: t('sites.companyInformation'),
            children: <SiteCompanyForm
              onSubmit={onSubmit}
              site={site}
              changeSetGeolocation={form.mutators.setGeolocation}
            />,
          },
          {
            key: 'site',
            label: t('sites.siteInformation'),
            children: <SitePresentationForm
              onSubmit={onSubmit}
              site={site}
              values={values}
              handleDupplicateSchedule={handleDupplicateSchedule}
              setFieldValue={form.mutators.setFieldValue}
            />,
          },
          {
            key: 'store',
            label: t('sites.settings'),
            children: <SiteStoreForm onSubmit={onSubmit} site={site} values={values} user={user} />,
          },
          {
            key: 'sms',
            label: t('menu.sms'),
            children: <SiteSmsForm onSubmit={onSubmit} site={site} values={values} user={user} />,
          },
        ];

        return (
          <form onSubmit={handleSubmit} noValidate>
            <FormScrollError />
            <Button
              style={{ marginBottom: '2rem' }}
              label="Translate"
              icon="fa-language"
              color="primary"
              confirm
              confirmMessage={t('common.translateConfirm')}
              onClick={() => handleTranslate(values, form.mutators.setFieldValue)}
            />

            <Tabs>
              {tabs.map((tab) => (
                <Tab {...tab}>
                  {tab.children}
                </Tab>
              ))}
            </Tabs>

            <FormInput
              type="submit"
              label={t('common.save')}
              icon="map-pin"
              disabled={submitting || pristine}
            />

          </form>
        );
      }}
    />
  );
};

SiteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  site: PropTypes.object,
};

SiteForm.defaultProps = {
  site: {},
};

export default SiteForm;
