import React, { useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import useCategoryItem from 'categoryItems/contexts/categoryItems';

import PageHeader from 'common/components/PageHeader/PageHeader';
import PageLoader from 'common/components/PageLoader/PageLoader';
import CategoryItemForm from './CategoryItemForm';

const CategoryItemFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: categoryItem, isFetching,
  } = useCategoryItem();

  const { id: categoryItemId } = router.match.params;

  useEffect(() => { fetchItem(categoryItemId); }, [fetchItem, categoryItemId]);

  const onSubmit = useCallback(async (categoryItem) => {
    if (categoryItem.id) {
      const res = await update(categoryItem.id, categoryItem);

      if (!res.error) {
        setAlert(t('categoryItems.successEdit'), 'success');
        router.push('/category-items');
      } else {
        setAlert(res.message, 'danger');
      }
    } else {
      const res = await save(categoryItem);

      if (!res.error) {
        setAlert(t('categoryItems.successAdd'), 'success');
        router.push('/category-items');
      } else {
        setAlert(res.message, 'danger');
      }
    }
  }, [router, update, save, setAlert, t]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`categoryItems.${categoryItem && categoryItem.id ? 'edit' : 'add'}CategoryItem`)}
        subLink={{ url: '/category-items', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <CategoryItemForm
          onSubmit={onSubmit}
          categoryItem={categoryItem}
        />
      </section>
    </>
  );
};

export default CategoryItemFormPage;
