/* eslint-disable object-curly-newline */
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import React, { useCallback } from 'react';
import useBeachEditor from 'sites/pages/BeachEditor/contexts/beachEditor';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const EditChoice = ({ choices, type }) => {
  const {
    updateSeat, selectedSeats, seats,
  } = useBeachEditor();

  const { t } = useTranslation();

  const currentSeats = selectedSeats.map((selected) => seats.find((seat) => seat.id === selected));

  const handleReplaceChoice = useCallback((newChoice) => {
    currentSeats.map((seat) => {
      if (type === 'price') {
        updateSeat(seat.id, { price_area: newChoice });
      } else if (type === 'waiter') {
        updateSeat(seat.id, { waiter_area: newChoice });
      } else {
        updateSeat(seat.id, { furniture: newChoice });
      }

      return null;
    });
  }, [currentSeats, updateSeat, type]);

  return (
    <div className="selectors">
      <h3 className="editor-subtitle">{t('editor.editChoice')}</h3>
      { choices.map((choice, index) => (
        <div
          key={index}
          alt={choice.name}
          className="selector selector-choice"
          onClick={
            () => handleReplaceChoice(choice)
          }
        >
          <div className="circleSelectorWrapper">
            <div
              className="circleSelector"
              style={{
                backgroundColor: choice.color || undefined,
                backgroundImage: choice.image?.url
                  ? `url(${process.env.REACT_APP_API_URL}${choice.image.url})` : undefined,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
          <div className="selectorName">
            {type === 'furniture' ? (
              <TranslatedText value={choice.name} />
            ) : choice.name}
          </div>
        </div>
      ))}

    </div>
  );
};

export default EditChoice;

EditChoice.propTypes = {
  choices: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};
