import { useState, useEffect, useCallback } from 'react';
import fetchJSON from 'common/utils/fetchJSON';

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async (url) => {
    setIsFetching(true);

    try {
      const res = await fetchJSON({ url, method: 'GET' });

      setData(res);
      return res;
    } catch (err) {
      setError(err);
    }

    setIsFetching(false);
  }, [setIsFetching, setData, setError]);

  useEffect(() => {
    url && fetchData(url);
  }, [fetchData, url]);

  return {
    data, isFetching, error, fetchData,
  };
};

export default useFetch;
