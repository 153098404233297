import Button from 'common/components/Button/Button';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Table from 'common/components/Table/Table';
import useRouter from 'common/hooks/use-router';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCallType from 'callTypes/context/callTypes';
import useSite from 'sites/contexts/sites';
import i18n from '../../i18n';

const CallTypesList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    fetchItems: fetchCallTypes,
    items: callTypes,
    isFetching,
    pageCount,
    remove,
    filters,
    setFilter,
    itemsCount,
  } = useCallType();

  const { currentSite } = useSite();
  const defaultLang = currentSite ? currentSite.default_language : i18n.language;

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    fetchCallTypes();
  }, [remove, fetchCallTypes]);

  const handleEdit = useCallback((row) => router.push(`/call-types/edit/${row.original.id}`), [router]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      accessor: 'name',
      filterName: 'name_contains',
      Cell: ({ row: { original: { name } } }) => (
        <span>
          {name[defaultLang]}
        </span>
      ),
    },
    /* {
      Header: t('common.isActive'),
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { active } } }) => (
        <span>
          {active ? <i className="fas fa-check has-text-success" /> : <i className="fas fa-times has-text-danger" />}
        </span>
      ),
    }, */
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('callTypes.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, defaultLang, handleEdit, handleDelete]);

  return (
    <>
      <PageHeader
        title={t('callTypes.listCallTypes')}
        link={{ url: '/call-types/add', icon: 'map-pin', label: t('callTypes.addCallType') }}
      />
      <section className="section list-page">
        <Table
          data={callTypes}
          columns={columns}
          fetchData={fetchCallTypes}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default CallTypesList;
