import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
} from 'react';
import ContextProvider from 'common/contexts/crud';

const SeasonContext = createContext();

export const SeasonsProvider = ({ children }) => (
  <ContextProvider
    url="seasons"
    context={SeasonContext}
  >
    {children}
  </ContextProvider>
);

SeasonsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useSeason = () => useContext(SeasonContext);

export default useSeason;
