import React, { useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useOrders from 'orders/contexts/orders';
import AlertsContext from 'common/contexts/alerts';
import OrderItem from 'orders/components/OrderItem/OrderItem';
import OrderItemService from 'orders/components/OrderItemService/OrderItemService';
import Button from 'common/components/Button/Button';
import RelativeDate from 'common/components/RelativeDate/RelativeDate';
import formatPrice from 'common/utils/formatPrice';
import useSite from 'sites/contexts/sites';
import OrderStateSelect from '../OrderStateSelect/OrderStateSelect';

const OrderDetails = ({ order, handleDelete, handleStateChange }) => {
  const { t } = useTranslation();
  const { save: saveOrder, fetchItems: fetchOrders } = useOrders();
  const { setAlert } = useContext(AlertsContext);
  const [orderState, setOrderState] = useState(order.state);
  const { item: currentSite } = useSite();

  const handleChange = (value) => {
    setOrderState(value);
    handleStateChange(order.id, value);
  };

  const reOrder = useCallback(async () => {
    const payload = { ...order };

    delete payload.id;
    delete payload.payment.id;
    const newPayload = {
      ...payload,
      booking: order.booking.id,
      state: 'created',
      site: (typeof order.site === 'object') ? order.site.id : order.site,
      payment: {
        status: 'PENDING',
        amount: payload.payment.amount,
        offline_method: 'CASH',
        site: order.site.id,
        transactionFees: payload.payment.transactionFees,
        user: payload.payment.user,
      },

    };

    try {
      await saveOrder(newPayload);
      setAlert(t('common.success'), 'success');
      fetchOrders();
    } catch (e) {
      setAlert(t('common.error'), 'danger');
    }
  }, [order, saveOrder, fetchOrders, setAlert, t]);

  return (
    <div className="order-details">
      <div className="order-details--header">
        <div className="order-details--meta">
          <h2 className="order-details--id">{`${t('orders.orderNumber')}${order.id}`}</h2>
          <div className="order-details--date">
            <i className="far fa-clock mr" />
            <RelativeDate value={order.created_at} />
          </div>
        </div>
        <>
          <span>
            {t('orders.seat', { seatNumber: order.seat?.name })}
          </span>
          <br />
          { order.booking?.roomID && (
            <span>
              {t('orders.room', { roomId: order.booking.roomID })}
            </span>
            )}
        </>
        <OrderStateSelect
          className="order-details--state"
          value={orderState}
          onChange={handleChange}
          clearable={false}
        />
      </div>
      <div className="order-details--items">
        {order.order_items.filter((i) => i.product !== null).map((i) => <OrderItem key={i.id} item={i} />)}
        {order.order_items.filter((i) => i.service !== null).map((i) => <OrderItemService key={i.id} item={i} />)}
      </div>
      { order.comments && (
      <div className="order-details--comments">
        {order.comments}
      </div>
      )}
      <div className="order-details--footer">
        <div className="order-details--total">
          <span>{`${t('orders.total')} :`}</span>
          <span className="order-details--total-price">{`${formatPrice(order.price, currentSite?.currency)}`}</span>
        </div>
        <div>
          <Button
            style={{ marginRight: 20 }}
            onClick={reOrder}
            icon="fa-plus"
            label={t('orders.reorder')}
            color="primary"
            outlined
            confirm
          />
          <Button
            onClick={() => handleDelete(order.id)}
            icon="fa-trash-alt"
            label={t('common.delete')}
            color="danger"
            outlined
            confirm
          />
        </div>
      </div>
    </div>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.shape.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleStateChange: PropTypes.func.isRequired,
};

export default OrderDetails;
