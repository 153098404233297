import React from 'react';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';

const processOptions = (options) => options.map(({ id, name }) => ({ label: name, value: id }));

const SeatSelect = (props) => (
  <RemoteSelect
    url="seats/available"
    filters={['date', 'stayDuration']}
    processOptions={processOptions}
    {...props}
  />
);

export default SeatSelect;
