import React, { useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import useDecoration from 'decorations/contexts/decorations';

import PageHeader from 'common/components/PageHeader/PageHeader';
import PageLoader from 'common/components/PageLoader/PageLoader';
import DecorationForm from './DecorationForm';

const DecorationFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: decoration, isFetching,
  } = useDecoration();

  const { id: decorationId } = router.match.params;

  useEffect(() => { fetchItem(decorationId); }, [fetchItem, decorationId]);

  const onSubmit = useCallback(async (decoration) => {
    if (decoration.id) {
      const res = await update(decoration.id, decoration);

      if (!res.error) {
        setAlert(t('decorations.successEdit'), 'success');
        router.push('/decorations');
      } else {
        setAlert(res.message, 'danger');
      }
    } else {
      const res = await save(decoration);

      if (!res.error) {
        setAlert(t('decorations.successAdd'), 'success');
        router.push('/decorations');
      } else {
        setAlert(res.message, 'danger');
      }
    }
  }, [router, update, save, setAlert, t]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`decorations.decoration${decoration && decoration.id ? 'Edit' : 'Add'}`)}
        subLink={{ url: '/decorations', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <DecorationForm
          onSubmit={onSubmit}
          furniture={decoration}
        />
      </section>
    </>
  );
};

export default DecorationFormPage;
