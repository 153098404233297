/* eslint-disable object-curly-newline */
import AlertsContext from 'common/contexts/alerts';
import useMap from 'maps/contexts/maps';
import MapForm from 'maps/pages/MapFormPage/MapForm';
import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useBeachEditor from 'sites/pages/BeachEditor/contexts/beachEditor';

const ConceptionSelector = () => {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);
  const {
    setSelectedSeats,
  } = useBeachEditor();

  const {
    item: map,
    update: updateMap,
  } = useMap();

  useEffect(() => {
    setSelectedSeats([]);
  }, [setSelectedSeats]);

  const onConceptionSubmit = useCallback(async (data) => {
    const map = {
      ...data,
    };
    const updatedMap = await updateMap(map.id, map);

    if (updatedMap) {
      setAlert(t('maps.successEdit'), 'success');
    }
  }, [updateMap, setAlert, t]);

  return (
    <>
      <div className="selector-panel">

        <div style={{ padding: 8 }}>
          <MapForm
            onSubmit={onConceptionSubmit}
            map={map}
            isConceptionEditor
          />
        </div>

      </div>

    </>
  );
};

export default ConceptionSelector;
