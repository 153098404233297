import React, { useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from 'common/components/PageHeader/PageHeader';
import UserForm from 'users/pages/UserFormPage/UserForm';
import useAuth from 'common/contexts/auth';
import AlertsContext from 'common/contexts/alerts';
import useUser from 'users/contexts/users';

const Profile = () => {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);
  const { user, refreshUser } = useAuth();
  const { update: updateUser, fetchItem: fetchUser } = useUser();

  useEffect(() => { fetchUser(user.id); }, [user, fetchUser]);

  const onSubmit = useCallback(async (user) => {
    const res = await updateUser(user.id, user);

    refreshUser();
    if (!res.error) {
      setAlert(t('users.successEdit'), 'success');
    } else {
      setAlert(res.message, 'danger');
    }
  }, [updateUser, setAlert, t, refreshUser]);

  return (
    <>
      <PageHeader title={t('common.profile')} />
      <section className="section form-page">
        <UserForm
          onSubmit={onSubmit}
          user={user}
        />
      </section>
    </>
  );
};

export default Profile;
