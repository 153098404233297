/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { SketchPicker } from 'react-color';

const ColorPicker = ({
  value, onChange,
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [inputColor, setInputColor] = useState({ hex: value });

  const handleClick = useCallback(() => {
    setDisplayColorPicker(!displayColorPicker);
  }, [displayColorPicker]);

  const handleChange = useCallback((color) => {
    onChange(color.hex);
    setInputColor(color);
  }, [onChange]);

  const handleClose = useCallback(() => {
    setDisplayColorPicker(false);
  }, [setDisplayColorPicker]);

  return (
    <div className="color-picker">
      <div className="swatch" onClick={handleClick}>
        <div style={{ backgroundColor: `${value}` }} className="color" />
      </div>
      { displayColorPicker ? (
        <div className="popover">
          <div className="cover" onClick={handleClose} />
          <SketchPicker color={inputColor} onChange={handleChange} />
        </div>
      ) : null }
    </div>
  );
};

export default ColorPicker;
