import React from 'react';
import { useTranslation } from 'react-i18next';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

const processOptions = (options) => options.map(({ id, name }) => (
  { value: id, label: <TranslatedText value={name} /> }
));

const CategoryItemSelect = (props) => {
  const { t } = useTranslation();

  return (
    <RemoteSelect
      filters={['site']}
      url="category-items"
      isFilter
      placeholder={t('locations.categoryItem')}
      processOptions={processOptions}
      {...props}
    />
  );
};

export default CategoryItemSelect;
