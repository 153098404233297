import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const UserContext = createContext();

export const UsersProvider = ({ children }) => (
  <ContextProvider url="users" context={UserContext}>{children}</ContextProvider>
);

UsersProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useUser = () => useContext(UserContext);

export default useUser;
