import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from 'common/components/PageHeader/PageHeader';
import useSite from 'sites/contexts/sites';
import Button from 'common/components/Button/Button';
import useRouter from 'common/hooks/use-router';
import fetchJSON from 'common/utils/fetchJSON';
import AlertsContext from 'common/contexts/alerts';
import useAuth from 'common/contexts/auth';
import Stats from 'orders/pages/Stats/Stats';

const Welcome = () => {
  const { t } = useTranslation();
  const { currentSite, selectCurrentSite } = useSite();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const { user: currentUser } = useAuth();

  const [options, setOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetchItems = async () => {
      setIsFetching(true);
      try {
        const res = await fetchJSON({
          url: 'sites',
          method: 'GET',
        });

        if (currentUser.role.type !== 'superadmin') {
          setOptions(res.find((site) => (site.id === currentUser?.site?.id)));
        } else {
          setOptions(res);
        }

        return res;
      } catch (e) {
        setAlert(e.message, 'danger');
      } finally {
        setIsFetching(false);
      }
    };

    fetchItems();
  }, [currentUser, setAlert, setIsFetching, setOptions]);

  return !currentSite ? (
    <>
      <PageHeader title={t('menu.welcome')} />
      <section className="section list-page">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="box">
              <article className="media">
                <div className="media-content has-text-centered">
                  <strong
                    className="title px-2"
                    style={{ color: '#009ad4' }}
                  >
                    {t('welcome.welcomeMsg')}
                  </strong>

                </div>
              </article>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">

            <div>
              <article className="media">
                <div className="media-content has-text-centered mb-1">
                  {!isFetching && options?.length > 0
                    && options
                      .map((site) => (
                        <Button
                          key={`site-${site.id}`}
                          label={site.name}
                          className={site.id === currentSite?.id
                            ? 'button is-warning'
                            : 'button is-outlined has-text-warning'}
                          onClick={() => {
                            if (site.id !== currentSite?.id) {
                              selectCurrentSite(site);
                            } else {
                              selectCurrentSite(null);
                            }
                          }}
                          style={{ margin: 2, boxShadow: '0 2px 3px rgb(0 0 0 / 10%), 0 0 0 1px rgb(0 0 0 / 10%)' }}
                          outlined
                        />
                      ))}
                </div>

              </article>
            </div>

            {(currentUser.role.type === 'superadmin') && (
              <div className="box">
                <article className="media">
                  <div className="media-content">
                    <h3 className="subtitle">{t('welcome.sites')}</h3>
                    <Button
                      label={t('welcome.manage')}
                      className="button is-primary is-outlined mr-1"
                      onClick={() => router.push('/sites')}
                    />
                    <Button
                      label={t('welcome.add')}
                      className="button is-primary is-outlined mr-1"
                      onClick={() => router.push('/sites/add')}
                    />
                  </div>
                </article>
              </div>
            )}

            {currentSite?.id && (
            <div className="box">
              <article className="media">
                <div className="media-content">
                  <h3 className="subtitle">{t('welcome.bookings')}</h3>
                  <Button
                    label={t('welcome.manage')}
                    className="button is-primary is-outlined mr-1"
                    onClick={() => router.push('/bookings')}
                  />
                  <Button
                    label={t('welcome.add')}
                    className="button is-primary is-outlined mr-1"
                    onClick={() => router.push('/bookings/add')}
                  />
                </div>
              </article>
            </div>
            )}

            {currentSite?.id && (
            <div className="box">
              <article className="media">
                <div className="media-content">
                  <h3 className="subtitle">{t('welcome.orders')}</h3>
                  <Button
                    label={t('welcome.activeOrders')}
                    className="button is-primary is-outlined mr-1"
                    onClick={() => router.push('/active-orders')}
                  />
                  <Button
                    label={t('welcome.archivedOrders')}
                    className="button is-primary is-outlined mr-1"
                    onClick={() => router.push('/archived-orders')}
                  />
                </div>
              </article>
            </div>
            )}

            <div className="box">
              <article className="media">
                <div className="media-content">
                  <h3 className="subtitle">{t('welcome.products')}</h3>
                  {' '}
                  <Button
                    label={t('welcome.manage')}
                    className="button is-primary is-outlined mr-1"
                    onClick={() => router.push('/products')}
                  />
                  <Button
                    label={t('welcome.categorize')}
                    className="button is-primary is-outlined mr-1"
                    onClick={() => router.push('/categories')}
                  />
                  <Button
                    label={t('welcome.options')}
                    className="button is-primary is-outlined mr-1"
                    onClick={() => router.push('/options')}
                  />
                </div>
              </article>
            </div>

            <div className="box">
              <article className="media">
                <div className="media-content">
                  <h3 className="subtitle">{t('welcome.users')}</h3>
                  <Button
                    label={t('welcome.manage')}
                    className="button is-primary is-outlined mr-1"
                    onClick={() => router.push('/users')}
                  />
                  <Button
                    label={t('welcome.add')}
                    className="button is-primary is-outlined mr-1"
                    onClick={() => router.push('/users/add')}
                  />
                </div>

              </article>
            </div>
          </div>

        </div>
      </section>
    </>
  ) : (
    <Stats />
  );
};

export default Welcome;
