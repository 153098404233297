import React, {
  useEffect, useCallback, useState,
} from 'react';

import useMap from 'maps/contexts/maps';
import useRouter from 'common/hooks/use-router';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import useSite from 'sites/contexts/sites';
import usePriceArea from 'priceAreas/contexts/priceAreas';
import useWaiterArea from 'waiterAreas/contexts/waiterAreas';
import useFurniture from 'furnitures/contexts/furnitures';
import useDecoration from 'decorations/contexts/decorations';
import PageLoader from 'common/components/PageLoader/PageLoader';
import SelectorPanel from './components/SelectorPanel/SelectorPanel';
import useBeachEditor from './contexts/beachEditor';
import Board from './components/Board/Board';
import EditorHeader from './components/EditorHeader/EditorHeader';
import EditorFooter from './components/EditorFooter/EditorFooter';

const BeachEditor = () => {
  const router = useRouter();
  const {
    fetchItem: fetchMap, item: map,
  } = useMap();
  const [mapScale, setMapScale] = useState(0);
  const { id: mapId } = router.match.params;
  const {
    setSeats, mode, setSite, setMap, selectedSeats, isUpdating,
  } = useBeachEditor();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { currentSite } = useSite();

  const {
    fetchItems: fetchPriceAreas,
  } = usePriceArea();

  const {
    fetchItems: fetchWaiterAreas,
  } = useWaiterArea();

  const {
    fetchItems: fetchFurnitures,
  } = useFurniture();

  const {
    fetchItems: fetchDecorations,
  } = useDecoration();

  useEffect(() => {
    fetchPriceAreas();
    fetchWaiterAreas();
    fetchFurnitures();
    fetchDecorations();
  }, [fetchPriceAreas, fetchWaiterAreas, fetchFurnitures, fetchDecorations]);

  const areaRef = useCallback((node) => {
    if (node) {
      setDimensions({ width: node.getBoundingClientRect().width, height: node.getBoundingClientRect().height });
    }
  }, []);

  useEffect(() => {
    const getDatas = (async () => {
      const res = await fetchMap(mapId);

      let seats = res?.seats ? res.seats : [];

      seats = seats.filter(({ active }) => active);

      setSeats(seats);
      setMap(res);
      setSite(res?.site);
      setMapScale(res.mapBackOfficeScale);
    });

    getDatas();
  }, [setSeats, setMap, setSite, fetchMap, mapId]);

  // Pour éviter d'afficher une autre plage (a cause du cache du Context)
  if (!map || map.id !== parseInt(mapId, 10)) {
    return null;
  }

  const backImage = map?.backgroundImage ? (
    process.env.REACT_APP_API_URL + map.backgroundImage.url)
    : '/assets/haut-de-plage.png';

  let mapScreenDimensions = {
    width: 800,
    height: 800,
  };

  let scale = 1;

  /* disons que 1m = 80px
  (a prendre en compte pour la taille absolue des textes et input dans les transats)
  avec 60, on peut faire rentrer 4 chiffres dans un n° de meuble d'1x1m
  */
  const meterToPx = 80;

  if (dimensions) {
    mapScreenDimensions = {
      height: map?.backgroundHeight * meterToPx,
      width: map?.backgroundWidth * meterToPx,
    };

    const scaleH = dimensions.height / mapScreenDimensions.height;
    const scaleW = dimensions.width / mapScreenDimensions.width;

    scale = scaleH < scaleW ? scaleH : scaleW;
  }

  return (
    <TransformWrapper
      pan={{ disableOnTarget: ['BoardItem'] }}
      doubleClick={{ disabled: true }}
      defaultScale={mapScale || 1}
      defaultPositionX={map.mapBackOfficePositionX ? map.mapBackOfficePositionX : 0}
      defaultPositionY={map.mapBackOfficePositionY ? map.mapBackOfficePositionY : 0}
      options={{ limitToBounds: false }}
    >
      {({
        zoomIn, zoomOut, positionX, positionY, previousScale,
      }) => (
        <>
          <EditorHeader mapScale={mapScale} />
          <div className="beach-organizer">
            <SelectorPanel />

            <div className="beach-editor" ref={areaRef}>
              {Boolean(map?.id) && (
              <>
                <TransformComponent>
                  <div style={dimensions}>
                    <Board
                      className="beach-visualizer"
                      scale={scale}
                      previousScale={previousScale}
                      siteId={currentSite.id}
                      mapId={map.id}
                      mapScreenDimensions={mapScreenDimensions}
                      mapTrueDimensions={{
                        width: map.backgroundWidth,
                        height: map.backgroundHeight,
                      }}
                      style={{
                        backgroundImage: `url(${backImage})`,
                        width: mapScreenDimensions.width,
                        height: mapScreenDimensions.height,
                        transform: `scale(${scale})`,
                        cursor: (selectedSeats?.length || mode === 'conception') ? 'pointer' : 'cell',

                      }}
                    />
                  </div>
                </TransformComponent>

                <div className="zoom">
                  <button
                    className="zoom-plus"
                    onClick={zoomIn}
                  >
                    +
                  </button>
                  <button
                    className="zoom-minus"
                    onClick={zoomOut}
                  >
                    -
                  </button>
                </div>
                { mode === 'plan' && (
                <div className="map-scale">
                  <button
                    className="zoom-plus"
                    onClick={() => setMapScale(Math.round((mapScale + 0.1) * 10) / 10)}
                  >
                    +
                  </button>
                  <span>{mapScale}</span>
                  <button
                    className="zoom-minus"
                    onClick={() => mapScale > 0.1 && setMapScale(Math.round((mapScale - 0.1) * 10) / 10)}
                  >
                    -
                  </button>
                </div>
                )}
                <div className="mapInfo">
                  <div>{`x : ${positionX}, y : ${positionY}, scale :${previousScale}`}</div>
                </div>

              </>
              )}
            </div>

          </div>
          <EditorFooter />

        </>
      )}
    </TransformWrapper>

  );
};

export default BeachEditor;
