/* eslint-disable object-curly-newline */
import Button from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import AlertsContext from 'common/contexts/alerts';
import useDecoration from 'decorations/contexts/decorations';
import DecorationForm from 'decorations/pages/DecorationFormPage/DecorationForm';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useBeachEditor from 'sites/pages/BeachEditor/contexts/beachEditor';

const DecorationSelector = () => {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const {
    save, update, remove, fetchItems: fetchDecorations, items: decorations,
  } = useDecoration();

  console.log('useDecoration', useDecoration());

  const {
    site, incrementationSeat, setIncrementationSeat,
  } = useBeachEditor();

  const [showModal, setShowModal] = useState(false);
  const [selectedDecorationModal, setSelectedDecorationModal] = useState(null);

  const handleOpenModal = useCallback((decoration) => {
    setSelectedDecorationModal(decoration);
    setShowModal(true);
  }, [setShowModal]);

  const handleDelete = useCallback(async (row) => {
    await remove(row.id);
    await fetchDecorations();
    setShowModal(false);
  }, [remove, fetchDecorations]);

  const handleSubmitModal = useCallback(async (data) => {
    const payload = { ...data };

    if (data.id) {
      const res = await update(payload.id, payload);

      if (!res.error) {
        setAlert(t('decorations.successEdit'), 'success');
        fetchDecorations();
        setShowModal(false);
      } else {
        setAlert(res.message, 'danger');
      }
    } else {
      const res = await save(payload);

      if (!res.error) {
        setAlert(t('decorations.successAdd'), 'success');
        fetchDecorations();
        setShowModal(false);
      } else {
        setAlert(res.message, 'danger');
      }
    }
  }, [fetchDecorations, save, setAlert, t, update]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  // useEffect(() => {
  //   if (furnitures?.length) {
  //     updateSeatInformation('furniture', furnitures);
  //   }
  // }, [furnitures, updateSeatInformation]);

  console.log(decorations);

  return (
    <>
      <div className="selector-panel">
        { showModal && (
        <Modal confirmModal={() => {}} closeModal={handleCloseModal}>
          <DecorationForm
            site={site.id}
            onSubmit={handleSubmitModal}
            decoration={selectedDecorationModal}
            onDelete={handleDelete}
          />
        </Modal>
        )}

        <div className="editor-title-add">
          <b>{t('menu.decorations')}</b>
          <Button
            color="primary"
            label={t('editor.titleAddType')}
            icon="fa-plus"
            onClick={handleOpenModal}
            className="editor-add-type"
          />
        </div>

        <div className="selectors">
          { Boolean(decorations?.length) && decorations.map((item, index) => (
            <div
              key={index}
              alt={item.name}
              className={`selector ${item.id === incrementationSeat?.decoration?.id && 'selected'}`}
              onClick={
                () => {
                  setIncrementationSeat((current) => ({ ...current, decoration: item }));
                }
              }
            >
              <img
                alt={item.name}
                src={`${process.env.REACT_APP_API_URL}${item.image.url}`}
              />
              <div className="selectorName">
                <TranslatedText value={item.name} />
                {item.id === incrementationSeat?.decoration?.id && (
                <p>
                  {t('editor.doubleClicOnMapToAdd')}
                </p>
                )}
              </div>
              <div className="actionsButton">
                <Button
                  onClick={() => handleOpenModal(item)}
                  icon="fa-pencil-alt"
                  color="primary"
                  transparent
                />
              </div>

            </div>
          ))}

        </div>
      </div>
      {/* { Boolean(selectedSeats.length) && (
      <ContextualEditor />
      )} */}
    </>
  );
};

export default DecorationSelector;
