import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useSite from 'sites/contexts/sites';

const useTrans = () => {
  const { t, i18n } = useTranslation();
  const { currentSite } = useSite();

  const translateText = useCallback((text) => {
    if (text) {
      let translation = '';

      if (text[i18n.language]) {
        translation = text[i18n.language];
      } else if (currentSite && text[currentSite.default_language]) {
        translation = text[currentSite.default_language];
      } else {
        translation = text['en-GB'];
      }
      return translation || t('translation.missing');
    }
  }, [currentSite, i18n.language, t]);

  return { translateText };
};

export default useTrans;
