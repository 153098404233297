import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'common/hooks/use-router';
import Table from 'common/components/Table/Table';
import Button from 'common/components/Button/Button';
import PageHeader from 'common/components/PageHeader/PageHeader';
import useSitesList from 'sitesList/contexts/sitesList';

const ListList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    fetchItems: fetchVats, items: lists, isFetching, pageCount, remove, filters, setFilter,
    itemsCount,
  } = useSitesList();

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    fetchVats();
  }, [remove, fetchVats]);
  const handleEdit = useCallback((row) => router.push(`/list/edit/${row.original.id}`), [router]);

  const columns = useMemo(() => [
    {
      Header: t('list.name'),
      accessor: 'name',
      filterName: 'name_contains',
    },
    {
      Header: t('list.sites'),
      accessor: 'sites',
      // filterName: 'sites_contains',
      Cell: ({ row: { original: { sites } } }) => (
        <span>{sites?.length}</span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('list.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, handleEdit, handleDelete]);

  return (
    <>
      <PageHeader
        title={t('menu.listLists')}
        link={{ url: '/list/add', icon: 'user-plus', label: t('list.addList') }}
      />
      <section className="section list-page">
        <Table
          data={lists}
          columns={columns}
          fetchData={fetchVats}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default ListList;
