import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const CategoryItemContext = createContext();

export const CategoryItemsProvider = ({ children }) => (
  <ContextProvider url="category-items" context={CategoryItemContext}>{children}</ContextProvider>
);

CategoryItemsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useCategoryItem = () => useContext(CategoryItemContext);

export default useCategoryItem;
