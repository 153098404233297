import React, { useState } from 'react';

import fetchJSON from 'common/utils/fetchJSON';
import { Form } from 'react-final-form';
import FormInput from 'common/components/FormInput/FormInput';
import SyntaxHighlighter from 'react-syntax-highlighter';
import PageHeader from 'common/components/PageHeader/PageHeader';

const VoidTransaction = () => {
  const [result, setResult] = useState(null);
  const onSubmit = async (values) => {
    const query = new URLSearchParams(values).toString();

    setResult(null);
    const result = await fetchJSON({
      url: `pos/voidtransaction?${query}`,
      method: 'GET',
    });

    setResult(result);
  };

  return (
    <>
      <PageHeader
        title="VoidTransaction"
      />
      <section className="section list-page">
        <Form
          onSubmit={onSubmit}
          initialValues={{
            revenue: 80,
            employee: 90002,
            checkNumber: 27,
            table: 123,
            checkSeq: 207634649,
          }}
          render={({
            handleSubmit,
            submitting,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <FormInput
                name="revenue"
                label="revenue"
                required
              />
              <FormInput
                name="table"
                label="table"
                required
              />
              <FormInput
                name="employee"
                label="employee"
                required
              />
              <FormInput
                name="checkNumber"
                label="checkNumber"
                required
              />
              <FormInput
                name="checkSeq"
                label="checkSeq"
                required
              />
              <FormInput
                type="submit"
                label="Transaction"
                icon="map-marker-alt"
                disabled={submitting}
              />
            </form>
          )}
        />
        <div className="columns">
          Request:
          <div className="column">
            <SyntaxHighlighter language="xml">{result?.body}</SyntaxHighlighter>
          </div>
        </div>
        <div className="columns">
          Response:
          <div className="column">
            <SyntaxHighlighter language="xml">
              {result?.response}
              {result?.error}
            </SyntaxHighlighter>
          </div>
        </div>
      </section>
    </>
  );
};

export default VoidTransaction;
