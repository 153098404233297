import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useState,
} from 'react';
import ContextProvider from 'common/contexts/crud';
import fetchJSON from 'common/utils/fetchJSON';

const OrderContext = createContext();

export const OrdersProvider = ({ children }) => {
  const [isOrderStateUpdating, setIsOrderStateUpdating] = useState(false);

  const updateOrderState = useCallback(async (id, payload) => {
    setIsOrderStateUpdating(true);

    try {
      const res = await fetchJSON({ url: `orders/${id}/update-state`, method: 'PUT', payload });

      return res;
    } catch (e) {
      throw new Error('BAD_REQUEST');
    } finally {
      setIsOrderStateUpdating(false);
    }
  }, [setIsOrderStateUpdating]);

  const fetchActiveOrders = useCallback(async () => {
    try {
      const res = await fetchJSON({
        url: 'orders/active',
        method: 'GET',
      });

      return res;
    } catch (e) {
      throw new Error('BAD_REQUEST');
    }
  }, []);

  const value = useMemo(() => ({
    updateOrderState,
    fetchActiveOrders,
    isOrderStateUpdating,
    setIsOrderStateUpdating,
  }), [updateOrderState, fetchActiveOrders, isOrderStateUpdating]);

  return (
    <ContextProvider
      url="orders"
      context={OrderContext}
      value={value}
    >
      {children}
    </ContextProvider>
  );
};

OrdersProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useOrder = () => useContext(OrderContext);

export default useOrder;
