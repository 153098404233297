/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import useCustomer from 'customers/contexts/customers';
import CustomersImportModal from 'customers/pages/CustomersImport/CustomersImportModal';
import Select from 'common/components/Select/Select';
import useRouter from '../../../common/hooks/use-router';
import useSite from '../../../sites/contexts/sites';
import SiteSelect from '../../../sites/components/SiteSelect';
import Button from '../../../common/components/Button/Button';
import PageHeader from '../../../common/components/PageHeader/PageHeader';
import Table from '../../../common/components/Table/Table';

const CustomersList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentSite } = useSite();
  const {
    fetchItems: fetchCustomers, items: customers, isFetching: isFetchingCustomers,
    pageCount: pageCountCustomers, filters: filtersCustomers, setFilter: setFilterCustomers,
    itemsCount: itemsCountCustomers, remove, setSort: setSortCustomers,
  } = useCustomer();

  useEffect(() => {
    setSortCustomers('name:ASC');
  }, [setSortCustomers]);

  const handleEditCustomer = useCallback((row) => router.push(`/customers/edit/${row.original.id}`), [router]);

  const handleDeleteCustomer = useCallback(async (row) => {
    await remove(row.original.id);
    fetchCustomers();
  }, [remove, fetchCustomers]);

  const [showImportModal, setShowImportModal] = useState(false);

  const columnsCustomers = useMemo(() => [
    {
      Header: t('customers.civility'),
      accessor: 'civility',
      filterName: 'civility_contains',
      filterComponent: (props) => (
        <Select
          isFilter
          placeholder={t('customers.civility')}
          options={[
            { value: null, label: t('customers.allCivilities') },
            { value: 'M', label: t('customers.civilityM') },
            { value: 'W', label: t('customers.civilityW') },
            { value: 'A', label: t('customers.civilityA') },
          ]}
          {...props}
        />
      ),
      Cell: ({
        row: {
          original: {
            id, civility,
          },
        },
      }) => (
        <span className="table-link" onClick={() => router.push(`/customers/edit/${id}`)}>
          {civility ? t(`customers.civility${civility}`) : '-'}
        </span>
      ),
    },
    {
      Header: t('common.name'),
      accessor: 'name',
      filterName: 'name_contains',
      Cell: ({
        row: {
          original: {
            id, name,
          },
        },
      }) => (
        <span className="table-link" onClick={() => router.push(`/customers/edit/${id}`)}>
          {name}
        </span>
      ),
    },
    {
      Header: t('common.firstName'),
      accessor: 'firstName',
      filterName: 'firstName_contains',
      Cell: ({
        row: {
          original: {
            id, firstName,
          },
        },
      }) => (
        <span className="table-link" onClick={() => router.push(`/customers/edit/${id}`)}>
          {firstName || '-'}
        </span>
      ),
    },
    {
      Header: t('common.email'),
      accessor: 'email',
      filterName: 'email_contains',
      Cell: ({ row: { original: { id, email } } }) => (
        <span className="table-link" onClick={() => router.push(`/customers/edit/${id}`)}>
          {email}
        </span>
      ),
    },
    {
      Header: t('common.phoneNumber'),
      accessor: 'phone',
      filterName: 'phone_contains',
      Cell: ({ row: { original: { id, phone } } }) => (
        <span className="table-link" onClick={() => router.push(`/customers/edit/${id}`)}>
          {phone}
        </span>
      ),
    },
    {
      Header: t('customers.noShow'),
      accessor: 'noShow',
      filterName: 'noShow_contains',
      Cell: ({ row: { original: { id, noShow } } }) => (
        <span className="table-link" onClick={() => router.push(`/customers/edit/${id}`)}>
          {noShow}
        </span>
      ),
    },
    {
      Header: t('customers.customerCategory'),
      accessor: 'customer_category',
      filterName: 'customer_category_contains',
      Cell: ({ row: { original: { id, customer_category: customerCategory } } }) => (
        <span className="table-link" onClick={() => router.push(`/customers/edit/${id}`)}>
          {customerCategory?.name || ''}
        </span>
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      Cell: ({ row: { original: { site } } }) => (
        <span>
          {site?.name}
        </span>
      ),
    },

    {
      Header: t('common.actions'),
      className: 'table-actions',
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEditCustomer(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDeleteCustomer(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('customers.confirmDelete')}
          />
        </>
      ),
    },
  ], [handleEditCustomer, handleDeleteCustomer, router, t, currentSite]);

  return (
    <>
      <PageHeader
        title={t('customers.customers')}
        link={{ url: '/customers/add', icon: 'user-plus', label: t('customers.addCustomer') }}
        customHeader={(
          <div className="headerlink" onClick={() => setShowImportModal(true)}>
            <span className="page-header--link-label">{t('customers.importCustomers')}</span>
            <span className="icon page-header--link-button button is-light">
              <i className="fas fa-upload" />
            </span>
          </div>
        )}
      />

      <section className="section list-page has-overflow-x-table">
        <Table
          data={customers}
          columns={columnsCustomers}
          fetchData={fetchCustomers}
          isFetching={isFetchingCustomers}
          pageCount={pageCountCustomers}
          filters={filtersCustomers}
          setFilter={setFilterCustomers}
          itemsCount={itemsCountCustomers}
        />

      </section>
      <CustomersImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
      />
    </>
  );
};

export default CustomersList;
