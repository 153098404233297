import FormInput from 'common/components/FormInput/FormInput';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Button from 'common/components/Button/Button';

const SitePresentationForm = ({ values, handleDupplicateSchedule, setFieldValue }) => {
  const { t, i18n: { language } } = useTranslation();

  const weekdays = useMemo(() => (
    Array.from({ length: 7 }, (_, i) => ({
      key: `${moment(i, 'e').locale('en').format('dddd')}`.toLowerCase(),
      label: `${moment(i, 'e').locale(language).format('dddd')}`,
    }))
  ), [language]);

  return (
    <section className="section" style={{ marginTop: 20, marginBottom: 20 }}>
      <div className="row">
        <div style={{ flex: 1 }}>
          <FormInput
            label={t('sites.companyLogo')}
            name="company_logo"
            type="image"
            maxWidth={800}
            maxHeight={800}
          />
        </div>

        <div style={{ flex: 2 }}>
          <FormInput
            name="name"
            label={t('common.name')}
            icon="heading"
            required
          />

        </div>
      </div>

      <div className="row" style={{ marginTop: 32 }}>
        <div style={{ flex: 1 }}>
          <FormInput
            label={t('sites.imagesGalery')}
            name="images"
            type="image"
            multiple
            nbCols={2}
            maxWidth={800}
            maxHeight={1200}
          />
        </div>
        <div style={{ flex: 2, marginTop: -36 }}>
          <MultilingualInput
            name="description"
            label={t('sites.description')}
            type="htmlEditor"
            value={values.description}
            defaultLang={values.default_language}
            required
          />
        </div>
      </div>

      <div className="row" style={{ marginTop: 16 }}>
        <div className="row" style={{ flex: 1 }}>
          <FormInput type="date" icon="calendar" name="dateBegin" label={t('sites.dateBegin')} />
          <FormInput type="date" icon="calendar" name="dateEnd" label={t('sites.dateEnd')} />
        </div>

        <div style={{ flex: 2 }}>
          <FormInput
            name="company_description"
            label={t('sites.moreInformation')}
            type="textarea"
          />
        </div>
      </div>

      <div className="columns" style={{ marginTop: 30 }}>
        <div className="column">
          {t('sites.day')}
        </div>
        <div className="column">
          {t('sites.openingTime')}
        </div>
        <div className="column">
          {t('sites.closingTime')}
        </div>
        <div className="column">
          {t('sites.isClosed')}
        </div>
        <div className="column" />
      </div>

      {weekdays.map((day) => (
        <div key={day.key} className="columns">
          <div className="column">
            {day.label}
          </div>
          <>
            <div className="column">
              <FormInput
                type="time"
                name={`schedule.${day.key}.startHour`}
                disabled={values.schedule?.[day.key]?.isClosed}
              />
            </div>
            <div className="column">
              <FormInput
                type="time"
                name={`schedule.${day.key}.endHour`}
                disabled={values.schedule?.[day.key]?.isClosed}
              />
            </div>
          </>
          <div className="column">
            <FormInput
              name={`schedule.${day.key}.isClosed`}
              type="checkbox"
            />
          </div>
          <div className="column">
            <Button
              label={t('sites.applyOnWeek')}
              onClick={() => handleDupplicateSchedule(
                values.schedule,
                day.key,
                setFieldValue,
              )}
            />
          </div>
        </div>
      ))}
    </section>
  );
};

SitePresentationForm.propTypes = {
  values: PropTypes.object,
  handleDupplicateSchedule: PropTypes.func.isRequired,
  setFieldValue: PropTypes.any.isRequired,
  // changeSetGeolocation: PropTypes.func,

};

SitePresentationForm.defaultProps = {
  values: {},
  // changeSetGeolocation: null,
};

export default SitePresentationForm;
