import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import FormInput from 'common/components/FormInput/FormInput';
import PropTypes from 'prop-types';
import useSite from 'sites/contexts/sites';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';

const CustomerCategoryForm = ({ onSubmit, customerCategory }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();

  const initialValues = useMemo(() => customerCategory?.id ? {
    ...customerCategory,
    site: customerCategory.site?.id,
  }
    : { site: currentSite ? currentSite.id : null },
  [currentSite, customerCategory]);

  // const handlePassword = () => toggleUpdatePassword((val) => !val);

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({
          handleSubmit, submitting, pristine,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormScrollError />
            <FormInput
              name="name"
              label={t('common.name')}
              required
            />

            <FormInput
              type="submit"
              label={t('common.save')}
              icon="save"
              disabled={submitting || pristine}
            />
          </form>
        )}
      />
    </>
  );
};

CustomerCategoryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  customerCategory: PropTypes.object,
};

CustomerCategoryForm.defaultProps = {
  customerCategory: {},
};

export default CustomerCategoryForm;
