import React, { useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import useService from 'services/contexts/services';

import PageHeader from 'common/components/PageHeader/PageHeader';
import PageLoader from 'common/components/PageLoader/PageLoader';
import ServiceForm from './ServiceForm';

const ServiceFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: service, isFetching,
  } = useService();

  const { id: serviceId } = router.match.params;

  useEffect(() => { fetchItem(serviceId); }, [fetchItem, serviceId]);

  const onSubmit = useCallback(async (data) => {
    const service = { ...data };

    // En attendant de faire un vrai champ InputNumber
    service.price = parseFloat(service.price);

    if (service.id) {
      const updatedService = await update(service.id, service);

      if (updatedService) {
        setAlert(t('services.successEdit'), 'success');
        router.push('/services');
      }
    } else {
      const newService = await save({ ...service });

      if (newService) {
        setAlert(t('services.successAdd'), 'success');
        router.push('/services');
      }
    }
  }, [router, update, save, setAlert, t]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`services.${service && service.id ? 'edit' : 'add'}Service`)}
        subLink={{ url: '/services', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <ServiceForm
          onSubmit={onSubmit}
          service={service}
        />
      </section>
    </>
  );
};

export default ServiceFormPage;
