import React, { useState } from 'react';

import fetchJSON from 'common/utils/fetchJSON';
import { Form } from 'react-final-form';
import FormInput from 'common/components/FormInput/FormInput';
import SyntaxHighlighter from 'react-syntax-highlighter';
import PageHeader from 'common/components/PageHeader/PageHeader';

const Test = () => {
  const [result, setResult] = useState(null);
  const onSubmit = async (values) => {
    const query = new URLSearchParams(values).toString();

    setResult(null);
    const result = await fetchJSON({
      url: `pos/test?${query}`,
      method: 'GET',
    });

    setResult(result);
  };

  console.log({ result });

  return (
    <>
      <PageHeader
        title="test"
      />
      <section className="section list-page">
        <Form
          onSubmit={onSubmit}
          initialValues={{
            employee: 90002,
            revenue: 80,
            product: 600000001,
            line1: 'Mybea Booking',
            line2: 'Date Example',
            price: '10',
            tender: '9001',
            table: '101',
            CheckNumber: '0',
            CheckID: '',
            CheckOrderType: '1',
            CheckSeq: '0',
            CheckStatusBits: '0',
            EventObjectNum: '0',
          }}
          render={({
            handleSubmit,
            submitting,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <FormInput
                name="employee"
                label="employee"
                required
              />
              <FormInput
                name="revenue"
                label="revenue"
                required
              />
              <FormInput
                name="product"
                label="product"
                required
              />
              <FormInput
                name="line1"
                label="line1"
              />
              <FormInput
                name="line2"
                label="line2"
              />
              <FormInput
                name="price"
                label="price"
              />
              <FormInput
                name="tender"
                label="tender"
                required
              />
              <FormInput
                name="table"
                label="table"
                required
              />
              <FormInput
                name="CheckNumber"
                label="CheckNumber"
              />
              <FormInput
                name="CheckID"
                label="CheckID"
              />
              <FormInput
                name="CheckOrderType"
                label="CheckOrderType"
              />
              <FormInput
                name="CheckSeq"
                label="CheckSeq"
              />
              <FormInput
                name="CheckStatusBits"
                label="CheckStatusBits"
              />
              <FormInput
                name="EventObjectNum"
                label="EventObjectNum"
              />
              <FormInput
                type="submit"
                label="Transaction"
                icon="map-marker-alt"
                disabled={submitting}
              />
            </form>
          )}
        />
        <div className="columns">
          Request:
          <div className="column">
            <SyntaxHighlighter language="xml">{result?.body}</SyntaxHighlighter>
          </div>
        </div>
        <div className="columns">
          Response:
          <div className="column">
            <SyntaxHighlighter language="xml">
              {result?.response}
              {result?.error}
            </SyntaxHighlighter>
            {result?.error}
          </div>
        </div>
      </section>
    </>
  );
};

export default Test;
