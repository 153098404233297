import React from 'react';
import PropTypes from 'prop-types';

const Overlay = ({
  color, stayDuration, isSeveralDays, ...rest
}) => (
  <div
    className={`SquareOverlay overlay-${color} ${stayDuration}`}
    {...rest}
  >
    {isSeveralDays && (
      <div
        style={{
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'inline-block',
          height: 0,
          width: 0,
          borderBottom: '50px solid black',
          borderLeft: '50px solid transparent',
          opacity: 0.6,
        }}
      />
    )}
  </div>
);

Overlay.propTypes = {
  color: PropTypes.string.isRequired,
  stayDuration: PropTypes.string,
  isSeveralDays: PropTypes.bool,
};

Overlay.defaultProps = {
  stayDuration: '',
  isSeveralDays: false,
};

export default Overlay;
