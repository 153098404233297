import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from 'common/components/Modal/Modal';

const CropModal = ({
  tmpFiles, setTmpFiles, confirmModal, closeModal,
}) => {
  const handleCropChange = useCallback((index, crop) => {
    // Bug React Crop Image, qui appelle OnChange avec crop vide
    if (crop.width === 0 && crop.x === 0 && crop.y === 0) return;

    tmpFiles[index].crop = crop;
    setTmpFiles([...tmpFiles]);
  }, [tmpFiles, setTmpFiles]);

  const handleImageLoaded = useCallback((file, image) => {
    let width;

    let height;

    const imageRatio = image.width / image.height;

    if (file.crop.aspect === 0) {
      height = image.height;
      width = image.width;
    } else if (imageRatio > file.crop.aspect) {
      height = image.height;
      width = Math.round(image.height * file.crop.aspect);
    } else {
      width = image.width;
      height = Math.round(image.width / file.crop.aspect);
    }

    const crop = { ...file.crop };

    crop.width = width;
    crop.height = height;
    crop.x = Math.round((image.width - width) / 2);
    crop.y = Math.round((image.height - height) / 2);
    tmpFiles[file.index].crop = crop;
    tmpFiles[file.index].image = image;
    setTmpFiles([...tmpFiles]);
  }, [tmpFiles, setTmpFiles]);

  return (
    <Modal isConfirm confirmModal={confirmModal} closeModal={closeModal}>
      <div style={{ height: '100%', overflowY: 'scroll' }}>
        { tmpFiles.map((file, key) => (
          <ReactCrop
            key={key}
            src={file.url}
            onChange={(newCrop) => handleCropChange(file.index, newCrop)}
            crop={file.crop}
            onImageLoaded={(image) => handleImageLoaded(file, image)}
          />
        ))}
      </div>
    </Modal>
  );
};

CropModal.propTypes = {
  tmpFiles: PropTypes.array.isRequired,
  setTmpFiles: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default CropModal;
