import React, {
  useEffect, useContext, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import useVat from 'vat/contexts/vat';
import PageHeader from 'common/components/PageHeader/PageHeader';
import PageLoader from 'common/components/PageLoader/PageLoader';
import VatForm from './VatForm';

const VatFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: vat, isFetching,
  } = useVat();

  const { id: vatId } = router.match.params;

  useEffect(() => { fetchItem(vatId); }, [fetchItem, vatId]);

  const onSubmit = useCallback(async (vat) => {
    if (vat.message) {
      return;
    }

    if (vat.id) {
      const res = await update(vat.id, vat);

      if (!res.error) {
        setAlert(t('vat.successEdit'), 'success');
        router.push('/vat');
      } else {
        setAlert(res.message, 'danger');
      }
    } else {
      const res = await save(vat);

      if (!res.error) {
        setAlert(t('vat.successAdd'), 'success');
        router.push('/vat');
      } else {
        setAlert(res.message, 'danger');
      }
    }
  }, [update, setAlert, t, router, save]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`vat.${vat && vat.id ? 'edit' : 'add'}Vat`)}
        subLink={{ url: '/vat', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <VatForm
          onSubmit={onSubmit}
          vat={vat}
        />
      </section>
    </>
  );
};

export default VatFormPage;
