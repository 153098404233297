/* eslint-disable object-curly-newline */
import Button from 'common/components/Button/Button';
import FormInput from 'common/components/FormInput/FormInput';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import AlertsContext from 'common/contexts/alerts';
import fetchJSON from 'common/utils/fetchJSON';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';

const FurnitureForm = ({ onSubmit, onDelete, furniture, site }) => {
  const { t, i18n } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [priceAreas, setPriceAreas] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const { setAlert } = useContext(AlertsContext);
  const { currentSite, fetchItem: fetchSite, item } = useSite();

  useEffect(() => { fetchSite(site); }, [fetchSite, site]);

  const initialValues = useMemo(() => {
    const values = furniture?.id ? {
      ...furniture,
      site: furniture?.site?.id,
    } : { prices: [], site, width: 1, height: 1 };

    if (seasons && priceAreas) {
      const prices = [];

      seasons.forEach((season) => {
        priceAreas.forEach((area) => {
          let price = values.prices.find((p) => p.price_area?.id === area.id && p.season?.id === season.id);

          if (price) {
            price = {
              ...price,
              price_area: price.price_area.id,
              season: price.season.id,
            };
          } else {
            price = {
              season: season.id,
              price_area: area.id,
              price: null,
            };
          }
          prices.push(price);
        });
      });
      values.prices = prices;
    }
    return values;
  }, [furniture, site, priceAreas, seasons]);

  useEffect(() => {
    const fetchItems = async () => {
      setIsFetching(true);
      try {
        const res = await fetchJSON({
          url: 'price-areas',
          method: 'GET',
        });

        setPriceAreas(res);
      } catch (e) {
        setAlert(e.message, 'danger');
      } finally {
        setIsFetching(false);
      }
    };
    const fetchSeasons = async () => {
      setIsFetching(true);
      try {
        const res = await fetchJSON({
          url: 'seasons',
          method: 'GET',
        });

        setSeasons(res);
      } catch (e) {
        setAlert(e.message, 'danger');
      } finally {
        setIsFetching(false);
      }
    };

    fetchItems();
    fetchSeasons();
  }, [setAlert, setIsFetching, setPriceAreas, setSeasons]);

  // eslint-disable-next-line camelcase
  const defaultLang = currentSite ? currentSite.default_language : i18n.language;

  const handleTranslate = async (values, setFieldValue) => {
    let translated;
    const { name, description } = values;

    if (name && description) {
      translated = await fetchJSON({
        url: 'sites/translate',
        method: 'POST',
        payload: { items: { name, description }, site: currentSite.id },
      });

      setFieldValue({
        field: 'name',
        value: translated.name,
      });
      setFieldValue({
        field: 'description',
        value: translated.description,
      });
    }
  };

  if (currentSite && furniture && furniture.id && furniture.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  if (isFetching) { return null; }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      render={({
        values, handleSubmit, submitting, pristine, form,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Button
            style={{ marginBottom: '2rem' }}
            label="Translate"
            icon="fa-language"
            color="primary"
            confirm
            confirmMessage={t('common.translateConfirm')}
            onClick={() => handleTranslate(values, form.mutators.setFieldValue)}
          />
          <MultilingualInput
            name="name"
            label={t('common.name')}
            icon="heading"
            value={values.name}
            defaultLang={defaultLang}
            required
          />
          <MultilingualInput
            name="description"
            label={t('common.description')}
            icon="heading"
            type="textarea"
            defaultLang={defaultLang}
            required
          />
          {/* je sais pas s'il faut le garder au cas où on voudrait éditer dans les deux sens plus tard,
          mais pour l'instant les données sont complètement fausses et reposent sur d'anciennes données
      alors j'enlève */}
          {/* seasons.map((season, seasonIndex) => (
            <div
              key={seasonIndex}
              style={{ border: '1px solid #ddd', padding: 15, marginBottom: 15 }}
            >
              {<h2 className="formtitle" style={{ fontSize: 20, marginTop: 0 }}>{season.name[defaultLang]}</h2>}
              { }
              {priceAreas.map((area, areaIndex) => (
                <div className="price-area mb-1" key={area.id}>
                  <h2 className="formtitle" style={{ fontSize: 16, marginTop: 0 }}>
                    {area.name}
                  </h2>
                  <div className="row">
                    <FormInput
                      type="number"
                      name={`prices[${areaIndex + seasonIndex * priceAreas.length}].price`}
                      icon="money-bill"
                      label={t('common.priceBase')}
                      required
                    />
                    <FormInput
                      type="number"
                      name={`prices[${areaIndex + seasonIndex * priceAreas.length}].priceHalfday`}
                      icon="money-bill"
                      label={t('common.priceBaseHalfday')}
                      required
                    />
                    {currentSite?.hotel && (
                      <>
                        <FormInput
                          type="number"
                          name={`prices[${areaIndex + seasonIndex * priceAreas.length}].hotelPrice`}
                          label={t('common.priceHotel')}
                          icon="money-bill"
                          required
                        />
                        <FormInput
                          type="number"
                          name={`prices[${areaIndex + seasonIndex * priceAreas.length}].hotelPriceHalfday`}
                          label={t('common.priceHotelHalfday')}
                          icon="money-bill"
                          required
                        />
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )) */}

          <FormInput
            name="image"
            type="image"
            nbCols={5}
            maxWidth={500}
            maxHeight={500}
            required
          />

          <div className="row">
            <FormInput
              name="width"
              label={t('common.width')}
              required
              type="number"
            />
            <FormInput
              name="height"
              label={t('common.height')}
              required
              type="number"
            />
          </div>

          {item && item.isLinkedToPos && (
            <FormInput
              name="posNumber"
              label={t('furnitures.posNumber')}
            />
          )}

          {/* double emploi avec le champs "image", semble pas utilisé
          <FormInput
            name="picture"
            type="image"
            keepRatio={false}
            nbCols={5}
            maxWidth={500}
            maxHeight={500}
                  /> */}
          {!currentSite && (
            <FormInput
              type="custom"
              name="site"
              label={t('common.site')}
              required
            >
              <SiteSelect />
            </FormInput>
          )}

          <div className="editor-type-modal-buttons">
            {furniture?.id && (
            <Button
              onClick={() => onDelete(furniture)}
              confirmMessage={t('furnitures.confirmDelete')}
              confirm
              color="danger"
              label={t('editor.deleteType')}
              icon="fa-trash-alt"
            >
              <i className="fa fa-trash" />
              {t('editor.deleteType')}
            </Button>
            )}

            <FormInput
              type="submit"
              label={t(`furnitures.furniture${furniture && furniture.id ? 'Edit' : 'Add'}`)}
              icon="chair"
              disabled={submitting || pristine}
            />
          </div>

        </form>
      )}
    />
  );
};

FurnitureForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  furniture: PropTypes.object,
  site: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
};

FurnitureForm.defaultProps = {
  furniture: {},
  site: null,
};

export default FurnitureForm;
