import React, {
  useMemo, useCallback, useState, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'common/components/Table/Table';
import Button from 'common/components/Button/Button';
import PageHeader from 'common/components/PageHeader/PageHeader';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import AlertsContext from 'common/contexts/alerts';

import useCategory from 'categories/contexts/categories';
import CategorySelect from 'categories/components/CategorySelect';

const ImportCategories = () => {
  const { setAlert } = useContext(AlertsContext);
  const { t } = useTranslation();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedRootCategory, setSelectedRootCategory] = useState(null);
  const {
    setItems: setCategories,
    items: categories,
    isFetching,
    pageCount,
    filters,
    setFilter,
    importCategories,
    isImportFetching,
    getRef,
    itemsCount,
  } = useCategory();
  const processCategoryOptions = (options) => options.filter(({ parent }) => parent === null)
    .map(({ id, name }) => ({ value: id, label: <TranslatedText value={name} /> }));

  const fetchAndSetRefCategories = useCallback(async () => {
    const refProducts = await getRef(filters);

    setCategories(refProducts);
  }, [filters, getRef, setCategories]);

  const handleMinus = useCallback(async (row) => {
    setSelectedCategories((sCategories) => sCategories.filter((p) => p.id !== row.original.id));
  }, []);

  const handlePlus = useCallback(async (row) => {
    const alreadySelected = selectedCategories.find((p) => p.id === row.original.id);

    if (!alreadySelected) {
      const product = categories && categories.find((p) => p.id === row.original.id);

      setSelectedCategories([...selectedCategories, product]);
    }
  }, [selectedCategories, categories]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { name } } }) => (<TranslatedText value={name} />),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handlePlus(row)}
            icon="fa-plus"
            color="primary"
            transparent
          />
        </>
      ),
    },
  ], [t, handlePlus]);

  const rightColumns = useMemo(() => [
    {
      Header: t('common.name'),
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { name } } }) => (<TranslatedText value={name} />),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleMinus(row)}
            icon="fa-minus"
            color="primary"
            transparent
          />
        </>
      ),
    },
  ], [t, handleMinus]);

  const handleImport = useCallback(async () => {
    try {
      await importCategories(selectedCategories.map(({ id }) => id), selectedRootCategory);
      setSelectedCategories([]);
      setSelectedRootCategory(null);
      setAlert(t('categories.successImport'), 'success');
    } catch {
      setAlert(t('categories.errorImport'), 'danger');
    }
  }, [setAlert, t, importCategories, selectedCategories, selectedRootCategory]);

  return (
    <>
      <PageHeader
        title={t('menu.importCategories')}
        subLink={{ url: '/categories', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section list-page">
        <div className="columns">
          <div className="column">
            <Table
              data={categories}
              columns={columns}
              fetchData={fetchAndSetRefCategories}
              isFetching={isFetching}
              pageCount={pageCount}
              filters={filters}
              setFilter={setFilter}
              itemsCount={itemsCount}
            />
          </div>
          <div className="column">
            <Table
              data={selectedCategories}
              columns={rightColumns}
            />
            <div className="alignLine">
              <CategorySelect
                value={selectedRootCategory}
                onChange={(val) => setSelectedRootCategory(val)}
                processOptions={processCategoryOptions}
              />
              <Button
                onClick={handleImport}
                disabled={isImportFetching || selectedCategories.length === 0}
                icon="fa-file-import"
                color="primary"
                label={t('common.import')}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImportCategories;
