import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
} from 'react';
import ContextProvider from 'common/contexts/crud';

const CallTypeContext = createContext();

export const CallTypesProvider = ({ children }) => (
  <ContextProvider
    url="call-types"
    context={CallTypeContext}
  >
    {children}
  </ContextProvider>
);

CallTypesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useCallType = () => useContext(CallTypeContext);

export default useCallType;
