/* eslint-disable react/prop-types */
import React, { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import Table from 'common/components/Table/Table';
import Button from 'common/components/Button/Button';
import PageHeader from 'common/components/PageHeader/PageHeader';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import useCategory from 'categories/contexts/categories';
import CategorySelect from 'categories/components/CategorySelect';

const CatalogCategoriesList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    fetchItems: fetchCategories, items: categories, isFetching, pageCount, remove, filters, setFilter,
    itemsCount,
  } = useCategory();

  useEffect(() => {
    setFilter('site_null', 1);
  }, [setFilter]);

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    fetchCategories();
  }, [remove, fetchCategories]);

  const handleEdit = useCallback((row) => router.push(`/catalog-categories/edit/${row.original.id}`), [router]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { name } } }) => (<TranslatedText value={name} />),
    },
    {
      Header: t('common.reference'),
      accessor: 'reference',
      filterName: 'reference_contains',
    },
    {
      Header: t('common.parent'),
      filterName: 'parent.id',
      filterComponent: (props) => <CategorySelect isFilter {...props} />,
      Cell: ({ row: { original: { parent } } }) => (
        <span>
          {parent && <TranslatedText value={parent?.name} />}
        </span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('categories.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, handleDelete, handleEdit]);

  return (
    <>
      <PageHeader
        title={t('menu.listCatalogCategories')}
        link={{ url: '/catalog-categories/add', icon: 'tag', label: t('categories.addCatalogCategory') }}
      />
      <section className="section list-page">
        <Table
          data={categories}
          columns={columns}
          fetchData={fetchCategories}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default CatalogCategoriesList;
