import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import Table from 'common/components/Table/Table';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Button from 'common/components/Button/Button';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import useFurniture from 'furnitures/contexts/furnitures';

const FurnituresList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    fetchItems: fetchFurnitures, items: furnitures, isFetching, pageCount, remove, filters, setFilter,
    itemsCount,
  } = useFurniture();

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    await fetchFurnitures();
  }, [remove, fetchFurnitures]);

  const handleEdit = useCallback((row) => router.push(`/furnitures/edit/${row.original.id}`), [router]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { name } } }) => (<TranslatedText value={name} />),
    },
    {
      Header: t('common.price'),
      accessor: 'price',
      filterName: 'price',
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('furnitures.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, handleDelete, handleEdit]);

  return (
    <>
      <PageHeader
        title={t('menu.listFurnitures')}
        link={{ url: '/furnitures/add', icon: 'chair', label: t('furnitures.furnitureAdd') }}
      />
      <section className="section list-page">
        <Table
          data={furnitures}
          columns={columns}
          fetchData={fetchFurnitures}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default FurnituresList;
