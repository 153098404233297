import React, { useState, useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import FormInput from 'common/components/FormInput/FormInput';
import PropTypes from 'prop-types';
import RoleSelect from 'users/components/RoleSelect';
import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';
import WaiterAreaSelect from 'waiterAreas/components/WaiterAreaSelect';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import useAuth from 'common/contexts/auth';
import LanguagePicker from 'common/components/LanguagePicker/LanguagePicker';

const UserForm = ({ onSubmit, user }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();
  const [rolesId, setRolesId] = useState({});
  const [shouldUpdatePassword, toggleUpdatePassword] = useState(false);
  const { user: currentUser } = useAuth();

  const handleRolesLoaded = useCallback(({ roles }) => {
    const roleslist = {};

    roles.forEach((r) => {
      roleslist[r.name] = r.id;
    });
    setRolesId(roleslist);
  }, []);

  const initialValues = useMemo(() => user?.id ? {
    ...user,
    role: user.role.id,
    site: user.site?.id,
    areas: user.areas?.map(({ id }) => id),
  }
    : { site: currentSite ? currentSite.id : null },
  [currentSite, user]);

  if (currentUser?.id !== user?.id && currentSite && user && user.id && user.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  const handlePassword = () => toggleUpdatePassword((val) => !val);

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({
          values, handleSubmit, submitting, pristine,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormScrollError />
            <FormInput
              name="username"
              label={t('common.name')}
              required
              icon="user"
            />
            <FormInput
              type="email"
              name="email"
              required
              label={t('common.email')}
              icon="envelope"
            />
            <FormInput
              type="custom"
              name="language"
              label={t('common.language')}
              required
            >
              <LanguagePicker />
            </FormInput>
            {values.role === rolesId.barman && (
              <FormInput
                type="color"
                name="color"
                label={t('users.color')}
              />
            )}
            {user?.id && (currentUser.role?.type === 'superadmin' || currentUser.role?.type === 'admin') && (
              <FormInput
                name="shouldUpdatePassword"
                value={shouldUpdatePassword}
                checked={shouldUpdatePassword}
                onClick={handlePassword}
                label={t('auth.updatePassword')}
                type="checkbox"
              />
            )}
            {(!user?.id || shouldUpdatePassword) && (
              <FormInput
                placeholder={user?.id ? t('auth.newPassword') : t('auth.password')}
                type="password"
                name="password"
                required={!user?.id}
                label={user?.id ? t('auth.newPassword') : t('auth.password')}
                icon="lock"
              />
            )}
            {user?.id !== currentUser.id && (
              <FormInput
                type="custom"
                name="role"
                label={t('users.role')}
                required
              >
                <RoleSelect onDataLoaded={handleRolesLoaded} />
              </FormInput>
            )}
            {rolesId.length && values.role !== rolesId.superadmin && (
            <FormInput
              disabled={!!currentSite}
              type="custom"
              name="site"
              label={t('common.site')}
              required
            >
              <SiteSelect />
            </FormInput>
            )}
            {values.role === rolesId.waiter && (
              <>
                <FormInput
                  type="custom"
                  name="areas"
                  label={t('users.areas')}
                >
                  <WaiterAreaSelect
                    site={values.site}
                    multiple
                  />
                </FormInput>

                <FormInput
                  name="showOutOfService"
                  label={t('users.showOutOfService')}
                  type="checkbox"
                />
              </>
            )}
            <FormInput
              type="submit"
              label={t('common.save')}
              icon={`user-${user && user.id ? 'edit' : 'plus'}`}
              disabled={submitting || pristine}
            />
          </form>
        )}
      />
    </>
  );
};

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
};

UserForm.defaultProps = {
  user: {},
};

export default UserForm;
