import React, {
  useCallback,
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import { uniq } from 'lodash';

import FormInput from 'common/components/FormInput/FormInput';
import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';
import fetchJSON from 'common/utils/fetchJSON';
import AlertsContext from 'common/contexts/alerts';
import i18n from 'i18n';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useFurniture from 'furnitures/contexts/furnitures';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import useBeachEditor from 'sites/pages/BeachEditor/contexts/beachEditor';
import Select from 'common/components/Select/Select';
import useTrans from 'common/hooks/use-trans';
import Button from 'common/components/Button/Button';

const PriceAreaForm = ({
  onSubmit, onDelete, priceArea, site,
}) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();
  const { setAlert } = useContext(AlertsContext);
  const [isFetching, setIsFetching] = useState(false);
  const [seasons, setSeasons] = useState([]);
  const [furnitures, setFurnitures] = useState([]);
  const { fetchItem: fetchFurniture } = useFurniture();
  const { seats } = useBeachEditor();

  // eslint-disable-next-line camelcase
  const defaultLang = currentSite ? currentSite.default_language : i18n.language;
  const { translateText } = useTrans();

  useEffect(() => {
    const fetchFurnitures = async () => {
      setIsFetching(true);
      try {
        const res = await fetchJSON({
          url: 'furnitures',
          method: 'GET',
        });

        setFurnitures(res);
      } catch (e) {
        setAlert(e.message, 'danger');
      } finally {
        setIsFetching(false);
      }
    };
    const fetchSeasons = async () => {
      setIsFetching(true);
      try {
        const res = await fetchJSON({
          url: 'seasons',
          method: 'GET',
        });

        setSeasons(res);
      } catch (e) {
        setAlert(e.message, 'danger');
      } finally {
        setIsFetching(false);
      }
    };

    fetchFurnitures();
    fetchSeasons();
  }, [setAlert, setIsFetching, setSeasons]);

  const [usedFurnitures, setUsedFurnitures] = useState([]);

  const initialValues = useMemo(() => {
    const values = priceArea?.id ? {
      ...priceArea,
      site: priceArea?.site?.id,
    } : {
      prices: [], site, width: 50, height: 50,
    };

    // const usedFurnituresOnMap = uniq(seats.map(({ furniture: furnitureItem }) => furnitureItem));

    const usedFurnituresOnMap = seats.filter((seat, index, self) => (
      seat.furniture && index === self.findIndex((current) => (
        current.furniture?.id === seat.furniture?.id
      )))).map(({ furniture: furnitureItem }) => furnitureItem);

    setUsedFurnitures(usedFurnituresOnMap);

    if (seasons && furnitures) {
      const prices = [];

      seasons.forEach((parentSeason) => {
        furnitures.forEach((furniture) => {
          let price = values.prices.find(
            (priceItem) => priceItem.furniture?.id === furniture.id && priceItem.season?.id === parentSeason.id,
          );

          if (price) {
            price = {
              ...price,
              season: price.season.id,
            };
            prices.push(price);
          }
        });

        // inject furniture used in the map

        const usedFurnitureInSeasonList = values.prices.filter((p) => p.season.id === parentSeason.id).map(
          ({ furniture: furnitureItem }) => furnitureItem.id,
        );

        usedFurnituresOnMap?.forEach((usedFurniture) => {
          if (!usedFurnitureInSeasonList.includes(usedFurniture.id)) {
            const newPrice = {
              furniture: usedFurniture,
              season: parentSeason.id,
              price: 0,
              priceHalfday: 0,
              hotelPrice: 0,
              hotelPriceHalfday: 0,
            };

            prices.push(newPrice);
          }
        });
      });

      values.prices = prices;
    }

    return values;
  }, [priceArea, site, furnitures, seasons, seats, setUsedFurnitures]);

  const handleFormSubmit = useCallback(async (priceArea) => {
    const filteredPrices = priceArea.prices.filter((price) => (
      price.price
      || price.priceHalfday
      || price.hotelPrice
      || price.hotelPriceHalfday
    ));

    onSubmit({
      ...priceArea,
      prices: filteredPrices,
    });
  }, [onSubmit]);

  if (currentSite && priceArea && priceArea.id && priceArea.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  if (isFetching) { return null; }

  const emptyStyle = { backgroundColor: '#fff0d1', border: '1px solid orange' };

  return (
    <Form
      onSubmit={handleFormSubmit}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      initialValues={initialValues}
      render={({
        values,
        handleSubmit,
        submitting,
        pristine,
      }) => {
        let missingPrices = 0;

        values.prices.map((p) => {
          if (!p.price) {
            missingPrices += 1;
          }
          if (!p.priceHalfday) {
            missingPrices += 1;
          }
          if (currentSite?.hotel && !p.hotelPrice) {
            missingPrices += 1;
          }
          if (currentSite?.hotel && !p.hotelPriceHalfday) {
            missingPrices += 1;
          }
          return null;
        });

        return (
          <form onSubmit={handleSubmit} noValidate>
            <FormInput
              name="name"
              label={t('common.name')}
              icon="heading"
              required
            />
            <FormInput
              name="color"
              type="circle-color"
              required
            />
            {!currentSite && (
            <FormInput
              type="custom"
              name="site"
              label={t('common.site')}
              required
            >
              <SiteSelect />
            </FormInput>
            )}
            <MultilingualInput
              name="description"
              label={t('common.description')}
              icon="heading"
              value={values.description}
              defaultLang={defaultLang}
            />

            {missingPrices > 0 && (
            <p>
              {missingPrices}
              {' '}
              prix manquants
            </p>
            )}

            {seasons.map((season, seasonIndex) => (
              <div
                key={seasonIndex}
                style={{
                  border: '1px solid #ddd',
                  padding: 15,
                  marginBottom: 15,
                  backgroundColor: season.active ? 'white' : '#eee',
                }}
              >
                <h2 className="formtitle" style={{ fontSize: 20, marginTop: 0 }}>
                  {!season.active && (
                    <i className="fas fa-eye-slash" style={{ marginRight: 8 }} />
                  )}
                  {season.name[defaultLang]}
                </h2>
                <FieldArray name="prices">
                  {({ fields }) => (
                    <div>
                      {fields.map((priceField, priceIndex) => {
                        const isCurrentSeason = fields.value[priceIndex].season === season.id;

                        return isCurrentSeason && (
                        <div className="price-area mb-1" key={`s${seasonIndex}f${priceIndex}`}>
                          <div className="row">
                            <h2 className="formtitle" style={{ fontSize: 16 }}>
                              <TranslatedText value={fields.value[priceIndex].furniture?.name} />
                            </h2>

                            <FormInput
                              type="number"
                              name={`${priceField}.price`}
                              icon="money-bill"
                              label={t('common.priceBase')}
                              style={fields.value[priceIndex].price === 0 ? emptyStyle : {}}
                            />
                            <FormInput
                              type="number"
                              name={`${priceField}.priceHalfday`}
                              icon="money-bill"
                              label={t('common.priceBaseHalfday')}
                              style={fields.value[priceIndex].priceHalfday === 0 ? emptyStyle : {}}
                            />
                            {currentSite?.hotel && (
                            <>
                              <FormInput
                                type="number"
                                name={`${priceField}.hotelPrice`}
                                label={t('common.priceHotel')}
                                icon="money-bill"
                                style={fields.value[priceIndex].hotelPrice === 0 ? emptyStyle : {}}
                              />
                              <FormInput
                                type="number"
                                name={`${priceField}.hotelPriceHalfday`}
                                label={t('common.priceHotelHalfday')}
                                icon="money-bill"
                                style={fields.value[priceIndex].hotelPriceHalfday === 0 ? emptyStyle : {}}
                              />
                            </>
                            )}

                            {!usedFurnitures?.find(
                              (used) => used.id === fields.value[priceIndex].furniture.id,
                            ) && (
                            <Button
                              onClick={() => fields.remove(priceIndex)}
                              icon="fa-trash-alt"
                              color="danger"
                              style={{
                                borderRadius: '100%',
                                maxWidth: 30,
                                minWidth: 30,
                                height: 30,
                                fontSize: 12,
                                padding: 0,
                                top: 35,
                                position: 'relative',
                              }}
                            />
                            )}

                          </div>
                        </div>
                        );
                      })}
                      <Select
                        options={furnitures?.map(({ id: furnId, name }) => (
                          { value: furnId, label: translateText(name) })).filter(
                          (furnitureOption) => {
                            const seasonRows = fields.value.filter((row) => row.season === season.id);
                            const usedFurnitures = seasonRows.map((row) => row.furniture?.id);

                            return !usedFurnitures.includes(furnitureOption?.value);
                          },
                        )}
                        onChange={async (value) => {
                          const newFurniture = await fetchFurniture(value);

                          fields.push({
                            furniture: newFurniture,
                            price: 0,
                            priceHalfday: 0,
                            hotelPrice: 0,
                            hotelPriceHalfday: 0,
                            season: season.id,
                          });
                        }}
                        placeholder={t('common.addFurniture')}
                      />
                    </div>
                  )}
                </FieldArray>
              </div>
            ))}

            <div className="editor-type-modal-buttons">
              {!priceArea?.default && priceArea?.id && (
              <Button
                onClick={() => onDelete(priceArea)}
                confirm
                confirmMessage={t('priceAreas.confirmDelete')}
                color="danger"
                label={t('editor.deleteType')}
                icon="fa-trash-alt"
              />
              )}

              <FormInput
                type="submit"
                label={t('common.save')}
                icon="check"
                disabled={submitting || pristine}
              />
            </div>

          </form>
        );
      }}
    />
  );
};

PriceAreaForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  priceArea: PropTypes.object,
  site: PropTypes.number,
};

PriceAreaForm.defaultProps = {
  priceArea: {},
  site: null,
};

export default PriceAreaForm;
