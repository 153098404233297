import React, { useMemo, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import FormInput from 'common/components/FormInput/FormInput';
import PropTypes from 'prop-types';
import useSite from 'sites/contexts/sites';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import useAuth from 'common/contexts/auth';
import useCustomerCategory from 'customers/contexts/customerCategories';
import Select from 'common/components/Select/Select';
import ToggleThreeStates from '../../../common/components/ToggleThreeStates/ToggleThreeStates';

const CustomerForm = ({ onSubmit, customer }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();
  // const [shouldUpdatePassword, toggleUpdatePassword] = useState(false);
  const { customer: currentUser } = useAuth();
  const {
    items: customerCategories, fetchItems: fetchCategories,
  } = useCustomerCategory();

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const initialValues = useMemo(() => customer?.id ? {
    ...customer,
    site: customer.site?.id,
    customer_category: customer.customer_category?.id,
  }
    : { site: currentSite ? currentSite.id : null },
  [currentSite, customer]);

  if (currentUser?.id !== customer?.id && currentSite && customer
    && customer.id && customer.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  const categoriesOptions = customerCategories?.length ? [...customerCategories.map((cat) => ({
    value: cat.id,
    label: cat.name,
  })), { value: null, label: t('customers.noCategory') }] : [{ value: null, label: t('customers.noCategory') }];

  // const handlePassword = () => toggleUpdatePassword((val) => !val);

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({
          handleSubmit, submitting, pristine,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormScrollError />
            <div className="row">
              <FormInput
                type="custom"
                name="civility"
                label={t('customers.civility')}
              >
                <Select
                  placeholder={t('customers.civility')}
                  options={[
                    { value: null, label: t('customers.allCivilities') },
                    { value: 'M', label: t('customers.civilityM') },
                    { value: 'W', label: t('customers.civilityW') },
                    { value: 'A', label: t('customers.civilityA') },
                  ]}

                />
              </FormInput>

              <FormInput
                name="name"
                label={t('common.name')}
                required
                icon="user"
              />

              <FormInput
                name="firstName"
                label={t('common.firstName')}
                required
                icon="user"
              />
            </div>

            <div className="row">
              <FormInput
                type="number"
                name="phone"
                label={t('common.phoneNumber')}
                icon="phone"
              />
              <FormInput
                type="email"
                name="email"
                required
                label={t('common.email')}
                icon="envelope"
              />
            </div>

            {Boolean(customerCategories?.length) && categoriesOptions && (
            <FormInput
              type="custom"
              name="customer_category"
              label={t('customers.customerCategory')}
            >
              <ToggleThreeStates
                options={categoriesOptions}
              />
            </FormInput>
            )}
            <FormInput
              type="textarea"
              name="notes"
              label={t('customers.notes')}
            />
            {/* <FormInput
              disabled={!!currentSite}
              type="custom"
              name="site"
              label={t('common.site')}
              required
            >
              <SiteSelect />
              </FormInput> */}

            <FormInput
              type="submit"
              label={t('common.save')}
              icon={`user-${customer && customer.id ? 'pen' : 'plus'}`}
              disabled={submitting || pristine}
            />
          </form>
        )}
      />
    </>
  );
};

CustomerForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  customer: PropTypes.object,
};

CustomerForm.defaultProps = {
  customer: {},
};

export default CustomerForm;
