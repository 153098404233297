import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const DecorationContext = createContext();

export const DecorationsProvider = ({ children }) => (
  <ContextProvider url="decorations" context={DecorationContext}>{children}</ContextProvider>
);

DecorationsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useDecoration = () => useContext(DecorationContext);

export default useDecoration;
