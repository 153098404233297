import React from 'react';
import './RectangleSelection.css';

export default class ReactRectangleSelection extends React.Component {
  constructor(props) {
    super(props);
    this.animationInProgress = null;
    this.state = {
      hold: false,
      selectionBox: false,
      selectionBoxOrigin: [0, 0],
      selectionBoxTarget: [0, 0],
      animation: '',
    };
  }

  handleTransformBox() {
    const { selectionBoxOrigin, selectionBoxTarget } = this.state;

    if (
      selectionBoxOrigin[1] > selectionBoxTarget[1]
      && selectionBoxOrigin[0] > selectionBoxTarget[0]
    ) { return 'scaleY(-1) scaleX(-1)'; }

    if (selectionBoxOrigin[1] > selectionBoxTarget[1]) return 'scaleY(-1)';
    if (selectionBoxOrigin[0] > selectionBoxTarget[0]) return 'scaleX(-1)';
    return null;
  }

  handleMouseDown(e) {
    if (!e.ctrlKey) {
      return;
    }

    e.stopPropagation();
    if (this.props.disabled) return;

    clearTimeout(this.animationInProgress);
    this.animationInProgress = null;
    this.setState({ selectionBox: false, animation: '' });

    if (
      this.state.animation.length > 0
      && e.target.id === 'react-rectangle-selection'
    ) {
      this.setState({ selectionBox: false, animation: '' });
    }
    const containerPosition = this.props.container.getBoundingClientRect();
    const cursorLeft = Math.round((e.clientX - containerPosition.x) / this.props.scale);
    const cursorTop = Math.round((e.clientY - containerPosition.y) / this.props.scale);

    this.setState({
      hold: true,
      selectionBoxOrigin: [cursorLeft, cursorTop],
      selectionBoxTarget: [cursorLeft, cursorTop],
    });
  }

  closeSelectionBox() {
    if (this.props.onMouseUp) {
      this.props.onMouseUp({
        origin: this.state.selectionBoxOrigin,
        target: this.state.selectionBoxTarget,
      });
    }
    this.setState({
      hold: false,
      animation: 'react-rectangle-selection--fadeout',
      selectionBoxOrigin: [0, 0],
      selectionBoxTarget: [0, 0],
    });
    this.animationInProgress = setTimeout(() => {
      this.setState({ animation: '' });
      this.setState({ selectionBox: false });
      this.animationInProgress = null;
    }, 300);
  }

  render() {
    const baseStyle = {
      zIndex: 10,
      left: this.state.selectionBoxOrigin[0],
      top: this.state.selectionBoxOrigin[1],
      height: Math.abs(
        this.state.selectionBoxTarget[1] - this.state.selectionBoxOrigin[1] - 1,
      ),
      width: Math.abs(
        this.state.selectionBoxTarget[0] - this.state.selectionBoxOrigin[0] - 1,
      ),
      userSelect: 'none',
      transformOrigin: 'top left',
      transform: this.handleTransformBox(),
    };

    return (
      <div
        style={{ height: 'inherit', width: 'inherit' }}
        onMouseLeave={() => {
          this.closeSelectionBox();
        }}
        onMouseDown={(e) => this.handleMouseDown(e)}
        onMouseUp={() => this.closeSelectionBox()}
        onMouseMove={(evt) => {
          if (this.state.hold && !this.state.selectionBox) {
            if (this.props.onMouseDown) this.props.onMouseDown();
            this.setState({ selectionBox: true });
          }
          if (this.state.selectionBox && !this.animationInProgress) {
            const containerPosition = this.props.container.getBoundingClientRect();
            const cursorLeft = Math.round((evt.clientX - containerPosition.x) / this.props.scale);
            const cursorTop = Math.round((evt.clientY - containerPosition.y) / this.props.scale);

            this.setState({
              selectionBoxTarget: [cursorLeft, cursorTop],
            });

            if (this.props.onSelect) {
              this.props.onSelect(evt, {
                origin: this.state.selectionBoxOrigin,
                target: this.state.selectionBoxTarget,
              });
            }
          }
        }}
      >
        {this.state.selectionBox && (
          <div
            className={`react-rectangle-selection ${this.state.animation}`}
            id="react-rectangle-selection"
            style={Object.assign(baseStyle, this.props.style)}
          />
        )}
        {this.props.children}
      </div>
    );
  }
}
