import React, {
  useEffect, useContext, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import PageHeader from 'common/components/PageHeader/PageHeader';
import PageLoader from 'common/components/PageLoader/PageLoader';
import useSeason from 'seasons/context/seasons';
import useSite from 'sites/contexts/sites';
import useFurniture from 'furnitures/contexts/furnitures';
import SeasonForm from './SeasonForm';

const SeasonFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save,
    update,
    fetchItem,
    item: season,
    fetchItems,
    isFetching,
  } = useSeason();
  const {
    fetchItems: fetchFurnitures,
  } = useFurniture();

  const { id: seasonId } = router.match.params;

  const { currentSite } = useSite();

  useEffect(() => {
    fetchFurnitures();
  }, [fetchFurnitures]);

  useEffect(() => {
    fetchItem(seasonId);
    fetchItems();
  }, [fetchItem, fetchItems, seasonId]);

  const onSubmit = useCallback(async (season) => {
    const payload = { ...season };

    if (season.id) {
      const res = await update(payload.id, payload);

      if (!res.error) {
        setAlert(t('seasons.successEdit'), 'success');
        router.push('/seasons');
      } else {
        setAlert(res.message, 'danger');
      }
    } else {
      const res = await save(payload);

      if (!res.error) {
        setAlert(t('seasons.successAdd'), 'success');
        router.push('/seasons');
      } else {
        setAlert(res.message, 'danger');
      }
    }
  }, [update, setAlert, t, router, save]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`seasons.${season && season.id ? 'edit' : 'add'}Season`)}
        subLink={{ url: '/seasons', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <SeasonForm
          onSubmit={onSubmit}
          season={season}
          currentSite={currentSite}
          isFetching={isFetching}
        />
      </section>
    </>
  );
};

export default SeasonFormPage;
