import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import FormInput from 'common/components/FormInput/FormInput';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import useSite from 'sites/contexts/sites';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import Button from 'common/components/Button/Button';
import QrCodeReaderModal from 'common/components/QrCodeReader/QrCodeReader';
import UserSelect from 'users/components/UserSelect';

const WatchForm = ({ onSubmit, watch }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();

  const [showQrCodeReader, setShowQrCodeReader] = useState(false);

  const initialValues = useMemo(() => watch?.id ? {
    ...watch,
    site: watch.site?.id,
  }
    : {
      site: currentSite ? currentSite.id : null,
    },
  [currentSite, watch]);

  const handleValidQrCode = (value, { setFieldValue }) => {
    setShowQrCodeReader(false);
    setFieldValue({
      field: 'pushToken',
      value,
    });
  };

  return (
    <div className="watches">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{
          setFieldValue: ([field], state, utils) => {
            utils.changeValue(state, field.field, () => field.value);
          },
          ...arrayMutators,
        }}
        render={({
          handleSubmit, submitting, pristine, form,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormScrollError />
            <FormInput
              name="name"
              label={t('common.name')}
              required
            />
            <FormInput
              name="comments"
              label={t('watch.comments')}
            />

            <label className="label">{t('watch.id')}</label>
            <div className="row">
              <div className="col-button">
                <Button
                  color="primary"
                  onClick={() => setShowQrCodeReader(form.mutators)}
                  icon="fa-qrcode"
                />
              </div>
              <div className="col-field">
                <FormInput
                  name="pushToken"
                  required
                />
              </div>
            </div>
            <FormInput
              type="custom"
              name="user.id"
              label={t('watch.userName')}
              required
            >
              <UserSelect
                site_null={0}
                site={currentSite.site}
              />
            </FormInput>
            <FormInput
              name="waiterAreaOnly"
              type="checkbox"
              label={t('watch.area')}
            />

            <FormInput
              type="submit"
              label={t('common.save')}
              icon="save"
              disabled={submitting || pristine}
            />
            <QrCodeReaderModal
              showModal={showQrCodeReader}
              setShowModal={setShowQrCodeReader}
              handleSuccess={handleValidQrCode}
            />
          </form>
        )}
      />
    </div>
  );
};

WatchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  watch: PropTypes.object,
};

WatchForm.defaultProps = {
  watch: {},
};

export default WatchForm;
