import React, {
  useCallback, useContext, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CSVReader } from 'react-papaparse';
import PageHeader from '../../../common/components/PageHeader/PageHeader';
import Button from '../../../common/components/Button/Button';
import useProduct from '../../contexts/products';
import AlertsContext from '../../../common/contexts/alerts';

// TODO: supprimer le fichier une fois qu'il est importer

const ImportProductsCSV = () => {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);
  const [data, setData] = useState(null);
  const {
    importProductFromCSV,
  } = useProduct();

  const importFile = useCallback(async () => {
    try {
      if (data) {
        await importProductFromCSV(data);
        setAlert(t('products.successImport'), 'success');
        setData(null);
      }
    } catch (error) {
      setAlert(error, 'danger');
      console.log(error);
      setData(null);
    }
  }, [data, importProductFromCSV, setAlert, t]);

  const handleOnDrop = useCallback(async (data) => {
    const headers = data[0].data;

    data.shift();
    const finalDatas = [];

    data.forEach((product) => {
      const obj = {};

      headers.forEach((key, index) => {
        obj[key] = product.data[index];
      });
      finalDatas.push(obj);
    });

    setData(finalDatas);
  }, []);

  const handleOnError = (err) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log(data);
  };

  return (
    <>
      <PageHeader
        title={t('menu.importProducts')}
        subLink={{ url: '/products', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section">
        <CSVReader
          onDrop={handleOnDrop}
          onError={handleOnError}
          addRemoveButton
          onRemoveFile={handleOnRemoveFile}
        >
          <span>Drop CSV file here or click to upload.</span>
        </CSVReader>

        <div style={{ marginTop: 16, display: 'flex', justifyContent: 'center' }}>
          <Button
            icon="fa-file-import"
            color="primary"
            disabled={!data}
            onClick={() => importFile()}
            label={t('common.import')}
          />
        </div>

      </section>

    </>
  );
};

export default ImportProductsCSV;
