import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useMemo, useCallback,
} from 'react';
import createPersistedState from 'use-persisted-state';

import ContextProvider from 'common/contexts/crud';
import useRouter from 'common/hooks/use-router';

const SiteContext = createContext();
const usePersistedState = createPersistedState('currentSite');

export const SitesProvider = ({ children }) => {
  const [currentSite, setCurrentSite] = usePersistedState(null);
  const router = useRouter();

  const selectCurrentSite = useCallback((site) => {
    setCurrentSite(site);
    router.push('/');
  }, [setCurrentSite, router]);

  const value = useMemo(() => ({
    currentSite,
    selectCurrentSite,
    setCurrentSite,
  }), [
    currentSite,
    selectCurrentSite,
    setCurrentSite,
  ]);

  return (
    <ContextProvider
      url="sites"
      value={value}
      context={SiteContext}
    >
      {children}
    </ContextProvider>
  );
};

SitesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useSite = () => useContext(SiteContext);

export default useSite;
