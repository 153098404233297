import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import Table from 'common/components/Table/Table';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Button from 'common/components/Button/Button';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import useLocation from 'aroundMe/contexts/locations';
import SiteSelect from 'sites/components/SiteSelect';
import CategoryItemSelect from 'categoryItems/components/CategoryItemSelect';
import useSite from 'sites/contexts/sites';

const AroundMeList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    fetchItems: fetchLocations, items: locations, isFetching, pageCount, remove, filters, setFilter,
    itemsCount,
  } = useLocation();
  const { currentSite } = useSite();

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    await fetchLocations();
  }, [remove, fetchLocations]);

  const handleEdit = useCallback((row) => router.push(`/aroundme/edit/${row.original.id}`), [router]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { id, name } } }) => (
        <span className="table-link" onClick={() => router.push(`/aroundme/edit/${id}`)}>
          <TranslatedText value={name} />
        </span>
      ),
    },
    {
      Header: t('common.address'),
      accessor: 'address',
    },
    {
      Header: t('locations.distance'),
      accessor: 'distance',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { distance } } }) => (
        <>{`${distance} km`}</>
      ),
    },
    {
      Header: t('products.category'),
      filterName: 'category_item.id',
      filterComponent: (props) => <CategoryItemSelect isFilter {...props} />,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { category_item: category } } }) => (
        <TranslatedText value={category?.name} />
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'category_item.site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { category_item: category } } }) => (
        <span>
          {category?.site?.name}
        </span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('locations.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, handleDelete, handleEdit, router, currentSite]);

  return (
    <>
      <PageHeader
        title={t('menu.listLocations')}
        link={{ url: '/aroundme/add', icon: 'pizza-slice', label: t('locations.addLocation') }}
      />
      <section className="section list-page">
        <Table
          data={locations}
          columns={columns}
          fetchData={fetchLocations}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default AroundMeList;
