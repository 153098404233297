import FormInput from 'common/components/FormInput/FormInput';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import useLang from 'common/hooks/use-lang';

const frontUrl = `${process.env.REACT_APP_FRONT_URL}` ?? window.location.origin;

console.log('Front url:', frontUrl);

const SiteCompanyForm = ({ site, changeSetGeolocation }) => {
  const { t } = useTranslation();
  const { langs } = useLang();
  const currencyOptions = [
    { value: 'EUR', label: 'Euro' },
    { value: 'USD', label: 'US Dollar' },
    { value: 'AED', label: 'UAE Dirham' },
    { value: 'GBP', label: 'Pound Sterling' },
    { value: 'MXN', label: 'Mexican Peso' },
  ];

  return (
    <>
      <section className="section" style={{ marginTop: 20, marginBottom: 20 }}>
        <div className="row">
          <div>
            <FormInput
              name="company_name"
              label={t('sites.companyName')}
              icon="heading"
            />
            {/* <FormInput
              name="company_address"
              label={t('sites.companyAddress')}
  /> */}
            <FormInput
              name="address"
              type="geolocation"
              onChange={changeSetGeolocation}
              label={t('common.address')}
              required
            />
            <FormInput
              name="phone"
              label={t('sites.phone')}
              icon="phone"
            />
            <div className="row">
              <FormInput
                type="select"
                name="default_language"
                label={t('common.language')}
                options={langs.map((lang) => ({ value: lang, label: t(`common.${lang}`) }))}
                required
              />
              <FormInput
                type="select"
                name="currency"
                label={t('common.currency')}
                options={currencyOptions}
                required
              />
            </div>
          </div>
          <div style={{
            textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          >
            {site?.id ? (
              <QRCode
                size={200}
                value={`${frontUrl}?site=${site?.id}`}
              />
                ) : null}
          </div>
        </div>

      </section>
    </>
  );
};

SiteCompanyForm.propTypes = {
  site: PropTypes.object,
  changeSetGeolocation: PropTypes.func,
};

SiteCompanyForm.defaultProps = {
  site: {},
  changeSetGeolocation: null,
};

export default SiteCompanyForm;
