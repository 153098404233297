import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';
import SeatSelect from 'sites/components/SeatSelect/SeatSelect';
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import ToggleThreeStates from 'common/components/ToggleThreeStates/ToggleThreeStates';

import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import useBooking from 'bookings/contexts/bookings';
import Button from 'common/components/Button/Button';
import { useHistory } from 'react-router-dom';

const updateDates = (dates, values, mutators) => {
  if (values.periodType === 'range' && dates.length < 2) {
    return;
  }
  const items = [];

  dates.forEach((date) => {
    const index = values.booking_items.findIndex(({ date: bdate }) => moment(date).isSame(bdate, 'day'));

    if (index !== -1) {
      items.push(values.booking_items[index]);
    } else {
      items.push({ date: moment(date).format('YYYY-MM-DD'), active: true, valid: false });
    }
  });
  items.sort((a, b) => a.date.localeCompare(b.date));
  mutators.setFieldValue({ field: 'booking_items', value: items });
};

const BookingForm = ({ onSubmit, booking }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();
  const {
    remove,
  } = useBooking();

  const history = useHistory();

  const handleDeleteBooking = useCallback(async (bookingId) => {
    await remove(bookingId);
    history.goBack();
  }, [remove, history]);

  const initialValues = useMemo(() => {
    const formValues = booking?.id
      ? {
        stayDuration: booking.stayDuration ?? 'fullday', // default if not set
        ...booking,
        hotel: true,
        dates: booking.booking_items?.map((i) => i.date)
          .sort((a, b) => a.localeCompare(b)).map((d) => moment(d).startOf('day').toDate()),
        seats: booking.seats?.map(({ id }) => id),
        site: booking.site.id,
      }
      : {
        stayDuration: 'fullday', // default if not set
        ...booking,
        booking_items: booking?.booking_items ?? [],
        dates: booking?.dates ?? [],
        site: currentSite ? currentSite.id : null,
        seats: booking?.seats ? booking.seats.map(({ id }) => id) : [],
        hotel: true,
        active: true,
        valid: true,
        payment: {
          online: false,
          offline_method: 'CASH',
          site: currentSite ? currentSite.id : null,
          status: 'PENDING',
        },
      };

    formValues.booking_items?.sort((a, b) => a.date.localeCompare(b.date));

    return formValues;
  }, [booking, currentSite]);

  if (currentSite && booking && booking.id && booking.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  const paymentMethods = [{ value: 'CASH', label: t('bookings.CASH') }, { value: 'CB', label: t('bookings.CB') }];

  if (currentSite?.hotel) {
    paymentMethods.push({ value: 'ROOM', label: t('bookings.ROOM') });
  }

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      render={({
        handleSubmit, submitting, pristine, values, form,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          {booking?.id && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginBottom: 16,
                paddingBottom: 16,
                borderBottom: '1px solid #eaeaea',
              }}
            >
              <Button
                onClick={() => handleDeleteBooking(booking.id)}
                icon="fa-trash-alt"
                color="danger"
                confirm
                label={t('beachVisualizer.deleteBooking')}
                confirmMessage={t('beachVisualizer.confirmDelete')}
              />
            </div>
          )}
          <FormScrollError />
          <div className="columns">
            <div className="column">
              <FormInput
                type="custom"
                name="stayDuration"
                label={t('bookings.stayDuration')}
                required
              >
                <ToggleThreeStates
                  options={[
                    { value: 'fullday', label: t('bookings.fullday') },
                    { value: 'morning', label: t('bookings.morning') },
                    { value: 'afternoon', label: t('bookings.afternoon') },
                  ]}
                />
              </FormInput>
              <FormInput
                type="custom"
                name="periodType"
                label={t('bookings.periodType')}
                required
              >
                <ToggleThreeStates
                  options={[
                    { value: 'single', label: t('bookings.singleDate') },
                    { value: 'multiple', label: t('bookings.multipleDate') },
                    { value: 'range', label: t('bookings.rangeDate') },
                  ]}
                  // onCustomChange={() => { form.change('dates', undefined); }}
                />
              </FormInput>
              {values.periodType && (
                <div className={values?.stayDuration || ''}>
                  <FormInput
                    type="date"
                    icon="calendar"
                    name="dates"
                    label={values.periodType === 'single' ? t('bookings.date') : t('bookings.dates')}
                    showCalendar
                    onChange={(dates) => { updateDates(dates, values, form.mutators); }}
                    required
                    mode={values.periodType}
                  />
                </div>
              )}

              <FieldArray name="booking_items">
                {({ fields }) => (
                  <div style={{ marginTop: 20 }}>
                    {fields && fields.map((name, index) => (
                      <>
                        <div key={name} className="row" style={{ alignItems: 'center' }}>
                          <div>
                            <span className="fa fa-calendar" style={{ marginRight: 4, color: '#bdc3c7' }} />
                            { moment(values.booking_items[index].date).format('L') }
                          </div>
                          <div>
                            <FormInput
                              type="checkbox"
                              name={`${name}.active`}
                              label={t('bookings.activate')}
                            />
                          </div>
                          <div>
                            <FormInput
                              type="checkbox"
                              name={`${name}.valid`}
                              label={t('bookings.validate')}
                            />
                          </div>
                        </div>
                        {index < fields.length - 1 && (
                          <hr style={{ margin: '8px 0' }} />
                        )}
                      </>
                    ))}

                    {fields.length > 0 && (
                      <div
                        className="row"
                        style={{
                          backgroundColor: '#eaeaea',
                          padding: 10,
                          fontSize: 12,
                          fontStyle: 'italic',
                          borderRadius: 4,
                          marginTop: 16,
                        }}
                      >
                        <span>{t('bookings.activateLegend')}</span>
                        <span>{t('bookings.validateLegend')}</span>
                      </div>
                    )}
                  </div>
                ) }
              </FieldArray>

            </div>
            <div className="column">
              <FormInput
                name="clientName"
                label={t('bookings.clientName')}
                icon="user"
                required
              />
              {currentSite && currentSite.askPhone && (
              <FormInput
                name="phoneNumber"
                label={t('bookings.phoneNumber')}
                icon="phone"
                type="text"
                required
              />
              )}
              <FormInput
                name="description"
                label={t('common.description')}
                type="textarea"
              />
              { currentSite?.hotel && (
              <>
                <FormInput
                  name="hotel"
                  label={t('bookings.hotelCustomer')}
                  type="checkbox"
                  disabled={!!currentSite.restrictedToHotelClients}
                />

                { (values.hotel || !!currentSite.restrictedToHotelClients) && (
                <FormInput
                  name="roomID"
                  label={t('bookings.roomID')}
                  icon="user"
                  type="text"
                  required={!!currentSite.restrictedToHotelClients}
                />
                )}
              </>
            )}

              {/* <FormInput
              name="checkin"
              label={t('bookings.checkInTime')}
              type="datetime"
            /> */}

              {!currentSite && (
              <FormInput
                type="custom"
                name="site"
                label={t('common.site')}
                required
              >
                <SiteSelect />
              </FormInput>
              )}
              { /* <FormInput
              type="select"
              name="payment.offline_method"
              label={t('bookings.paymentMethod')}
              options={paymentMethods}
              required
            />
            <FormInput
              type="select"
              name="payment.status"
              data-cy="payment.status"
              label={t('bookings.paymentStatus')}
              options={[
                {
                  value: 'PENDING',
                  label: t('bookings.paymentPending'),
                }, {
                  value: 'PAID',
                  label: t('bookings.paymentPaid'),
                }]}
              required
            />
              */ }

              <FormInput
                label={t('bookings.seatsSelect')}
                type="custom"
                name="seats"
                multiple
                required
                disabled={!(values.dates?.length > 0)}
              >
                <SeatSelect
                  date={values.dates?.map((d) => moment(d).format('YYYY-MM-DD')).join(',')}
                  site={values.site}
                  stayDuration={values.stayDuration}
                  additionnalOptions={booking?.id != null ? booking?.seats : []}
                  multiple
                />
              </FormInput>
            </div>
          </div>
          { /* booking?.id && booking_items?.map((bi, biIndex) => (
            <div key={biIndex} className="columns">
              <div className="column">
                <FormInput
                  type="checkbox"
                  name={`booking_item[${biIndex}].active`}
                  label={t('bookings.activate')}
                />
              </div>

              <div className="column">
                <FormInput
                  type="checkbox"
                  name={`booking_item[${biIndex}].valid`}
                  label={t('bookings.validate')}
                />
              </div>

              <div className="column">
                <Button
                  onClick={() => { console.log('remove') }}
                  icon="fa-trash-alt"
                  color="danger"
                  style={{
                    borderRadius: '100%',
                    maxWidth: 30,
                    minWidth: 30,
                    height: 30,
                    fontSize: 12,
                    padding: 0,
                    top: 35,
                    position: 'relative',
                  }}
                />
              </div>
            </div>
          )) */ }

          <FormInput
            type="submit"
            label={t('common.save')}
            icon="book-open"
            disabled={submitting || pristine}
          />

        </form>
      )}
    />
  );
};

BookingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  booking: PropTypes.object,
};

BookingForm.defaultProps = {
  booking: {},
};

export default BookingForm;
