import { useTranslation } from 'react-i18next';
import useSite from 'sites/contexts/sites';

const useLang = (defaultLang) => {
  const { i18n } = useTranslation();
  const { currentSite } = useSite();
  // TODO load langs from API
  const langs = ['en-GB', 'fr-FR', 'de-DE', 'es-ES', 'pt-PT', 'it-IT', 'ru-RU', 'ar', 'zh'];
  const langsOrdered = [];

  if (langs.includes(defaultLang)) {
    langsOrdered.push(defaultLang);
    langs.splice(langs.indexOf(defaultLang), 1);
  }

  if (currentSite && langs.includes(currentSite.default_language)) {
    langsOrdered.push(currentSite.default_language);
    langs.splice(langs.indexOf(currentSite.default_language), 1);
  }

  if (langs.includes(i18n.language)) {
    langsOrdered.push(i18n.language);
    langs.splice(langs.indexOf(i18n.language), 1);
  }

  return { langs: [...langsOrdered, ...langs] };
};

export default useLang;
