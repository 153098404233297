import FormInput from 'common/components/FormInput/FormInput';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import VatSelect from 'vat/components/VatSelect';

const SiteStoreForm = ({ values, user }) => {
  const { t } = useTranslation();

  return (
    <>
      <section className="section" style={{ marginTop: 20 }}>
        {user?.role.name === 'superadmin' && (
          <>
            <h2 className="subtitle">MyBea</h2>
            <div style={{ display: 'flex' }}>
              <FormInput
                isHorizontal
                type="checkbox"
                name="active"
                label={t('common.active')}
              />
              <FormInput
                isHorizontal
                type="checkbox"
                name="is_open"
                label={t('sites.isOpen')}
              />
              <FormInput
                name="hotel"
                type="checkbox"
                label={t('sites.isHotel')}
              />
            </div>

            {/*
          <div className="row">
            <FormInput
              name="isDemo"
              type="checkbox"
              label={t('sites.isDemo')}
            />
          </div>
          */}

            <h2 className="subtitle" style={{ marginTop: 20 }}>{t('customers.customers')}</h2>

            <div style={{ display: 'flex' }}>
              <FormInput
                name="phoneRequired"
                type="checkbox"
                label={t('sites.requirePhoneNumber')}
              />
              <FormInput
                name="emailRequired"
                type="checkbox"
                label={t('sites.requireEmail')}
              />
              <FormInput
                name="chatIsActive"
                type="checkbox"
                label={t('sites.chatIsActive')}
              />

            </div>

            <h2 className="subtitle" style={{ marginTop: 20 }}>{t('sites.bookMethod')}</h2>

            <div style={{ marginBottom: 12 }}>
              <FormInput
                name="canBookOnline"
                type="checkbox"
                label={t('sites.canBookOnline')}
              />
              <FormInput
                name="restrictedToHotelClients"
                type="checkbox"
                label={t('sites.restrictToHotelClients')}
              />

              <FormInput
                name="waiterAuthorRequired"
                type="checkbox"
                label={t('sites.requireWaiterAuthor')}
              />
            </div>
            {values?.canBookOnline && (
              <FormInput
                type="toggleThreeStates"
                options={[
                  { value: false, label: t('sites.bookingsWithoutSeats') },
                  { value: true, label: t('sites.bookingsWithSeats') },
                ]}
                name="bookingsWithSeats"
                label={t('sites.bookingsType')}
              />
            )}

            {/*
          <div className="row">
            <FormInput
              name="anonymousMode"
              type="checkbox"
              label={t('sites.anonymousMode')}
            />
            <FormInput
              name="autoValidation"
              type="checkbox"
              label={t('sites.autoValidation')}
            />
          </div>
          */}
            {/*
          <div className="row">

            <FormInput
              isHorizontal
              type="checkbox"
              name="allowChangeSeat"
              label={t('sites.allowChangeSeat')}
            />

          </div>  */}

            <h2 className="subtitle" style={{ marginTop: 16 }}>{t('menu.orders')}</h2>
            <FormInput
              type="toggleThreeStates"
              options={[
                { value: 'hide', label: t('sites.shopProductsHide') },
                { value: 'read', label: t('sites.shopProductsRead') },
                { value: 'buy', label: t('sites.shopProductsBuy') },
              ]}
              name="shopProductsMode"
              label={t('sites.shopProductsMode')}
            />

            <h2 className="subtitle" style={{ marginTop: 20 }}>{t('orders.paymentMethod')}</h2>
            <div style={{ marginTop: 16 }} className="row">
              <FormInput
                type="number"
                name="min_price"
                label={t('sites.minPrice')}
                icon="money-bill-wave"
                required
              />

              <FormInput
                type="custom"
                name="vat"
                label={t('vat.percentage')}
                required
              >
                <VatSelect />
              </FormInput>

              <FormInput
                type="custom"
                name="bookingVat"
                label={t('vat.bookingPercentage')}
                required
              >
                <VatSelect />
              </FormInput>
            </div>

            {/* values?.canPayOnline && (
            <div className="row" style={{ marginTop: 16 }}>
              <FormInput
                name="onlineMinPrice"
                type="number"
                label={t('sites.onlineMinPrice')}
                required
              />
              <FormInput
                name="onlineMaxPrice"
                type="number"
                label={t('sites.onlineMaxPrice')}
                required
              />
              <FormInput
                name="onlineFees"
                type="number"
                label={t('sites.onlineFees')}
                required
              />
              <FormInput
                name="onlineTransactionFees"
                type="number"
                label={t('sites.transactionFees')}
                required
              />
            </div>
          ) */}

            {/*
          <div className="row" style={{ marginTop: 16 }}>
            <FormInput
              name="canPayOnline"
              type="checkbox"
              label={t('sites.canPayOnline')}
              disabled={values?.canPayOnline && !values?.canPayOffline}
            />
            <FormInput
              isHorizontal
              type="checkbox"
              name="isLinkedToPos"
              label={t('sites.isLinkedToPos')}
            />
          </div>*
          {values.isLinkedToPos && (
            <div style={{ marginTop: 16 }}>
              <FormInput
                name="posURL"
                label={t('sites.posURL')}
              />
              <FormInput
                name="posInfo.employee"
                label="employee"
              />
              <FormInput
                name="posInfo.revenueCenterBooking"
                label="revenueCenterBooking"
              />
              <FormInput
                name="posInfo.revenueCenterShop"
                label="revenueCenterShop"
              />
              <FormInput
                name="posInfo.seatItemNumber"
                label="seatItemNumber"
              />
              <FormInput
                name="posInfo.tenderMedia"
                label="tenderMedia"
              />
            </div>
          )}
          */}

            <h2 className="subtitle" style={{ marginTop: 16 }}>{t('orders.clientInfos')}</h2>
            <div className="row">
              <FormInput
                name="isMandatoryName"
                type="checkbox"
                label={t('sites.isMandatoryName')}
              />
              <FormInput
                name="isMandatoryEmail"
                type="checkbox"
                label={t('sites.isMandatoryEmail')}
              />
              <FormInput
                name="isMandatoryPhone"
                type="checkbox"
                label={t('sites.isMandatoryPhone')}
              />
              <span className="spacer" />
            </div>

            <h2 className="subtitle" style={{ marginTop: 20 }}>{t('sites.alerts')}</h2>
            <FormInput
              type="email"
              name="email"
              label={t('common.email')}
            />
            <FormInput
              type="checkbox"
              name="alertBookingDeletionByMail"
              label={t('sites.bookingDeletionMailAlert')}
            />
          </>
      )}
        {(user?.role.name === 'superadmin' || user?.role.name === 'admin') && (
        <>
          <h2 className="subtitle" style={{ marginTop: 20 }}>{t('sites.printers')}</h2>
          <FormInput
            name="printerName"
            label={t('common.printerName')}
          />
          <FormInput
            name="thermalPrinterHost"
            label={t('common.thermalPrinterHost')}
          />
        </>
        )}
      </section>

    </>
  );
};

SiteStoreForm.propTypes = {
  values: PropTypes.object,
  user: PropTypes.object,
};

SiteStoreForm.defaultProps = {
  values: {},
  user: {},
};

export default SiteStoreForm;
