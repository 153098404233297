import React, {
  useEffect, useContext, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import PageHeader from 'common/components/PageHeader/PageHeader';
import PageLoader from 'common/components/PageLoader/PageLoader';
import useCallType from 'callTypes/context/callTypes';
import useSite from 'sites/contexts/sites';
import useFurniture from 'furnitures/contexts/furnitures';
import CallTypeForm from './CallTypeForm';

const CallTypeFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save,
    update,
    fetchItem,
    item: callType,
    fetchItems,
    isFetching,
  } = useCallType();
  const {
    fetchItems: fetchFurnitures,
  } = useFurniture();

  const { id: callTypeId } = router.match.params;

  const { currentSite } = useSite();

  useEffect(() => {
    fetchFurnitures();
  }, [fetchFurnitures]);

  useEffect(() => {
    fetchItem(callTypeId);
    fetchItems();
  }, [fetchItem, fetchItems, callTypeId]);

  console.log(callTypeId, callType);
  const onSubmit = useCallback(async (callType) => {
    const payload = { ...callType };

    if (callType.id) {
      const res = await update(payload.id, payload);

      if (!res.error) {
        setAlert(t('callTypes.successEdit'), 'success');
        router.push('/call-types');
      } else {
        setAlert(res.message, 'danger');
      }
    } else {
      const res = await save(payload);

      if (!res.error) {
        setAlert(t('callTypes.successAdd'), 'success');
        router.push('/call-types');
      } else {
        setAlert(res.message, 'danger');
      }
    }
  }, [update, setAlert, t, router, save]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`callTypes.${callType && callType.id ? 'edit' : 'add'}CallType`)}
        subLink={{ url: '/call-types', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <CallTypeForm
          onSubmit={onSubmit}
          callType={callType}
          currentSite={currentSite}
        />
      </section>
    </>
  );
};

export default CallTypeFormPage;
