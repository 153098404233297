import React, {
  useCallback,
  useContext,
  createContext,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import createPersistedState from 'use-persisted-state';
import { useTranslation } from 'react-i18next';

import fetchJSON from 'common/utils/fetchJSON';
import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import useSite from 'sites/contexts/sites';

const AuthContext = createContext();
const usePersistedState = createPersistedState('user');

const resetPassword = async (payload) => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (res.status === 200) {
    const resJson = await res.json();

    return resJson;
  }

  throw new Error();
};

const forgotPassword = async (payload) => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (res.status === 200) {
    const resJson = await res.json();

    return resJson;
  }

  throw new Error();
};

export const AuthProvider = ({ children }) => {
  const { t } = useTranslation();
  const [user, setUser] = usePersistedState(null);
  const { setAlert } = useContext(AlertsContext);
  const router = useRouter();
  const { currentSite, setCurrentSite } = useSite();
  const [isUserRefreshing, setIsUserRefreshing] = useState(true);

  const signin = useCallback(async (email, password) => {
    const payload = {
      identifier: email,
      password,
    };

    const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/local`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (res.ok) {
      const resJson = await res.json();

      window.localStorage.setItem('jwt', resJson.jwt);
      setUser(resJson.user);
      if (resJson.user.role !== 'superadmin') {
        setCurrentSite(resJson.user.site);
      }
      router.push('/');
    } else {
      setAlert(t('auth.badCredentials'), 'danger');
    }
  }, [router, setAlert, setCurrentSite, setUser, t]);

  const signup = useCallback(async ({ name, email, password }) => {
    const payload = {
      username: email,
      name,
      email,
      password,
    };

    const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/local/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (res.ok) {
      const user = await res.json();

      setUser(user);
      router.push('/');
    }
    // TODO handle register error
  }, [router, setUser]);

  const signout = useCallback(() => {
    window.localStorage.removeItem('jwt');
    user && setUser(null);
    currentSite && setCurrentSite(null);
  }, [currentSite, setCurrentSite, user, setUser]);

  const refreshUser = useCallback(async () => {
    try {
      const token = window.localStorage.getItem('jwt');

      if (token) {
        const res = await fetchJSON({
          url: 'users/me',
          method: 'GET',
        });

        if (res && res.role) {
          setUser(res);
          if (res.role.name !== 'superadmin') {
            setCurrentSite(res.site);
          }
        } else {
          signout();
        }
      } else {
        signout();
      }
    } catch {
      // if users/me is not accessible then the token expired
      signout();
    } finally {
      setIsUserRefreshing(false);
    }
  }, [setCurrentSite, setUser, signout]);

  const value = useMemo(() => ({
    user,
    signin,
    signup,
    signout,
    forgotPassword,
    refreshUser,
    isUserRefreshing,
    resetPassword,
  }), [
    user,
    signin,
    signup,
    signout,
    refreshUser,
    isUserRefreshing,
  ]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useAuth = () => useContext(AuthContext);

export default useAuth;
