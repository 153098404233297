/* eslint-disable react/prop-types */
import React, { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'common/hooks/use-router';

import Table from 'common/components/Table/Table';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Button from 'common/components/Button/Button';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import useSpecialOffer from 'specialOffers/contexts/specialOffers';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';

const SpecialOffersList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentSite } = useSite();
  const {
    fetchItems: fetchSpecialOffers,
    items: specialOffers,
    isFetching,
    pageCount,
    remove,
    update,
    filters,
    setFilter: setSpecialOffersFilter,
    itemsCount,
  } = useSpecialOffer();

  useEffect(() => {
    setSpecialOffersFilter('site_null', 0);
  }, [setSpecialOffersFilter]);

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    await fetchSpecialOffers();
  }, [remove, fetchSpecialOffers]);

  const handleEdit = useCallback((row) => router.push(`/special-offers/edit/${row.original.id}`), [router]);

  const handleToggle = useCallback(async (id) => {
    const specialOffer = specialOffers && specialOffers.find((s) => s.id === id);

    await update(specialOffer.id, { ...specialOffer, active: !specialOffer.active });
    await fetchSpecialOffers();
  }, [update, fetchSpecialOffers, specialOffers]);

  const columns = useMemo(() => [
    {
      Header: t('specialOffers.message'),
      filterName: 'message_contains',
      Cell: ({ row: { original: { id, message } } }) => (
        <span className="table-link" onClick={() => router.push(`/special-offers/edit/${id}`)}>
          <TranslatedText value={message} />
        </span>
      ),
    },
    {
      Header: t('specialOffers.discountRate'),
      Cell: ({ row: { original: { discount_rate: discountRate } } }) => (
        <span>
          {`${discountRate} %`}
        </span>
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      Cell: ({ row: { original: { site } } }) => (
        <span>
          {site?.name}
        </span>
      ),
    },
    {
      Header: t('common.status'),
      accessor: 'active',
      Cell: ({ row: { original: { active, id } } }) => (
        <Button
          onClick={() => handleToggle(id)}
          icon={active ? 'fa-check' : 'fa-times'}
          color={active ? 'success' : 'danger'}
          transparent
        />
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('specialOffers.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, currentSite, handleToggle, handleEdit, handleDelete, router]);

  return (
    <>
      <PageHeader
        title={t('menu.listSpecialOffers')}
        link={{ url: '/special-offers/add', icon: 'gift', label: t('specialOffers.addSpecialOffer') }}
      />
      <section className="section list-page">
        <Table
          data={specialOffers}
          columns={columns}
          fetchData={fetchSpecialOffers}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setSpecialOffersFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default SpecialOffersList;
