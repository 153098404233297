/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import useBooking from 'bookings/contexts/bookings';
import Button from 'common/components/Button/Button';
import DatePicker from 'common/components/DatePicker/DatePicker';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Table from 'common/components/Table/Table';
import useRouter from 'common/hooks/use-router';
import { createUploadUrl } from 'common/utils/createUrl';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';
import print from 'common/utils/print';
import Select from 'common/components/Select/Select';

// ^FO50,50^BQN,2,6^FDhttps://youtube.com/^FS

/* const getPrintContent = (booking) => `^XA

  ^FX Top section with logo, name and address.
  ^CF0,60

  ^FO50,50
  ^BQN,2,6
  ^FDQA,https://app.mybea.fr/?code=${booking.code}&site=${booking.site.id}^FS

  ^FO270,50^FDCode: ${booking.code}^FS
  ^CF0,30
  ^FO270,115^FDSeat: ${booking.seats[0].name}^FS
  ^FO270,155^FDRoom Number: ${booking.roomID}^FS
  ^FO270,195^FDDate: ${moment(booking.date).format('DD/MM/YYYY')}^FS
  ^FO50,270^GB700,3,3^FS
  ^XZ`; */

const getPrintContent = (booking) => {
  const startDate = booking.booking_items[0].date;

  let content = `^XA

  ^FX Top section with logo, name and address.
  ^CF0,60

  ^FO50,50^FDSeat: ${booking.seats[0].name}^FS
  ^CF0,30
  ^FO50,115^FDCode: ${booking.code}^FS
  ^FO50,155^FDRoom Number: ${booking.roomID}^FS
  ^FO50,195^GB700,3,3^FS
  `;

  if (booking.booking_items.length > 1) {
    const endDate = booking.booking_items[booking.booking_items.length - 1].date;

    content += `
    ^FO50,215^FDFrom: ${moment(startDate).format('DD/MM/YYYY')}^FS
    ^FO50,255^FDTo: ${moment(endDate).format('DD/MM/YYYY')}^FS
    `;
  } else {
    content += `
    ^FO50,215^FDDate: ${moment(startDate).format('DD/MM/YYYY')}^FS
    `;
  }

  content += '^XZ';

  return content;
};

const BookingsList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentSite } = useSite();
  const {
    fetchItems: fetchBookings, items: bookings, isFetching, pageCount,
    filters, setFilter, setFilters, setSort, remove,
    itemsCount,
  } = useBooking();

  const printerName = currentSite?.printerName;

  /* const handleToggle = useCallback(async (id, field) => {
    const booking = bookings && bookings.find((p) => p.id === id);

    if (field === 'active') {
      await update(booking.id, { ...booking, active: !booking.active });
    } else if (field === 'valid') {
      await update(booking.id, { ...booking, valid: !booking.valid });
    }
    await fetchBookings();
  }, [update, fetchBookings, bookings]); */

  const handleEdit = useCallback((row) => router.push(`/bookings/edit/${row.original.id}`), [router]);
  const handleRemove = useCallback((row) => {
    const deleteBooking = async (id) => {
      await remove(id);
      fetchBookings();
    };

    deleteBooking(row.original.id);
  }, [fetchBookings, remove]);

  useEffect(() => {
    setSort('created_at:DESC');
    return () => {
      setFilters({});
    };
  }, [setFilters, setSort]);

  const columns = useMemo(() => [
    {
      Header: t('common.code'),
      accessor: 'code',
      filterName: 'code_contains',
      Cell: ({ row: { original: { id, code } } }) => (
        <span className="table-link" onClick={() => router.push(`/bookings/edit/${id}`)}>
          {code}
        </span>
      ),
    },
    {
      Header: t('bookings.clientName'),
      accessor: 'clientName',
      filterName: 'clientName_contains',
      Cell: ({ row: { original: { clientName } } }) => (
        <span>
          {clientName}
        </span>
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      Cell: ({ row: { original: { site: { name } } } }) => (
        <span>
          {name}
        </span>
      ),
    },
    {
      Header: t('bookings.stayDuration'),
      accessor: 'stayDuration',
      filterName: 'stayDuration',
      filterComponent: (props) => (
        <Select
          placeholder={t('bookings.stayDuration')}
          options={[
            {
              value: 'fullday',
              label: t('bookings.fullday'),
            },
            {
              value: 'morning',
              label: t('bookings.morning'),
            },
            {
              value: 'afternoon',
              label: t('bookings.afternoon'),
            },
          ]}
          {...props}
        />
      ),
      Cell: ({ row: { original: { stayDuration } } }) => (
        <span>
          {stayDuration ? t(`bookings.${stayDuration}`) : t('bookings.fullday')}
        </span>
      ),
    },
    {
      Header: t('bookings.date'),
      accessor: 'date',
      filterName: 'booking_items.date',
      filterComponent: (props) => <DatePicker isFilter {...props} />,
      customFormat: (value) => value ? moment(value).format('YYYY-MM-DD') : '',
      Cell: ({ row: { original } }) => (
        <div>
          {(original.periodType === 'single' || original.booking_items?.length === 1) && (
            <p>{moment(original.booking_items[0]?.date).format('DD/MM/YYYY')}</p>
          )}

          {original.periodType === 'range' && original.booking_items?.length > 1 && (
            <>
              <p>
                {moment(original.booking_items[0]?.date).format('DD/MM/YYYY')}
              </p>
              <p>
                <span className="fa fa-arrow-right" style={{ marginRight: 4, fontSize: 12 }} />
                {moment(original.booking_items[original.booking_items.length - 1]?.date).format('DD/MM/YYYY')}
              </p>
            </>
          )}

          {original.periodType === 'multiple' && (
            <>
              {original.booking_items?.length > 1 && original.booking_items.map((bi, biIndex) => (
                <>
                  {biIndex < 3 && (
                    <p>
                      {biIndex > 0 && (
                        <span className="fa fa-plus" style={{ marginRight: 4, fontSize: 12 }} />
                      )}
                      {moment(bi.date).format('DD/MM/YYYY')}
                    </p>
                  )}
                </>
              ))}

              {original.booking_items?.length > 3 && (
                <p style={{ color: '#777777' }}>
                  <span className="fa fa-plus" style={{ marginRight: 4, fontSize: 12 }} />
                  {original.booking_items?.length - 3}
                  &nbsp;
                  {t('bookings.otherDates')}
                </p>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      Header: t('bookings.roomID'),
      accessor: 'roomID',
      filterName: 'roomID',
      Cell: ({ row: { original: { roomID } } }) => (
        <span>{roomID}</span>
      ),
    },
    {
      Header: t('bookings.seats'),
      accessor: 'seats',
      filterName: 'seats.name',
      Cell: ({ row: { original: { seats } } }) => (
        <span>{seats.map((seat) => seat.name).join(', ')}</span>
      ),
    },
    /* {
      Header: t('invoices.invoiceNumber'),
      filterName: 'payment.invoiceNumber',
      Cell: ({ row: { original: { payment } } }) => {
        const pay = payment || {};
        const { invoiceNumber } = pay;

        return (
          <span>
            {invoiceNumber}
          </span>
        );
      },
    }, */
    {
      Header: t('common.actions'),
      className: 'table-actions',
      Cell: ({ row }) => (
        <>
          { printerName && (
          <Button
            onClick={() => print(printerName, getPrintContent(row.original))}
            icon="fa-print"
            color="primary"
            transparent
          />
          )}
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          {/* eslint-disable-next-line react/prop-types */}
          {row.original?.payment?.invoicePath && (
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={() => window.open(createUploadUrl(row.original.payment.invoicePath))}
              icon="fa-file-invoice"
              color="primary"
              transparent
            />
          )}
          <Button
            onClick={() => handleRemove(row)}
            confirm
            confirmMessage={`${t('beachVisualizer.deleteBooking')} ${row.original.code}`}
            icon="fa-trash"
            color="primary"
            transparent
          />
        </>
      ),
    },
  ], [t, currentSite, router, printerName, handleEdit, handleRemove]);

  return (
    <>
      <PageHeader
        title={t('menu.listBookings')}
        link={{ url: '/bookings/add', icon: 'book-open', label: t('bookings.addBooking') }}
      />
      <section className="section list-page has-overflow-x-table">
        <Table
          data={bookings}
          columns={columns}
          fetchData={fetchBookings}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default BookingsList;
