import React from 'react';
import PropTypes from 'prop-types';
import useTrans from 'common/hooks/use-trans';

const TranslatedText = ({ value }) => {
  const { translateText } = useTrans();

  return <span>{translateText(value)}</span>;
};

TranslatedText.propTypes = {
  value: PropTypes.object,
};

TranslatedText.defaultProps = {
  value: '',
};

export default TranslatedText;
