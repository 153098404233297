import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const FurnitureContext = createContext();

export const FurnituresProvider = ({ children }) => (
  <ContextProvider url="furnitures" context={FurnitureContext}>{children}</ContextProvider>
);

FurnituresProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useFurniture = () => useContext(FurnitureContext);

export default useFurniture;
