/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';
import useAuth from 'common/contexts/auth';

const RoleSelect = (props) => {
  const { user: currentUser } = useAuth();
  const { t } = useTranslation();

  const allowedRoles = useMemo(() => {
    if (currentUser?.role?.type === 'superadmin') {
      return ['waiter', 'barman', 'authenticated', 'admin'];
    }
    return ['waiter', 'barman'];
  }, [currentUser]);

  const processOptions = useCallback((result) => result.roles
    .filter((role) => allowedRoles.includes(role.type))
    .map((r) => ({ value: r.id, label: t(`common.${r.type}`) })), [allowedRoles, t]);

  return (
    <RemoteSelect
      url="users-permissions/roles"
      isFilter={props.isFilter}
      placeholder={props.isFilter ? t('users.role') : null}
      processOptions={processOptions}
      {...props}
    />
  );
};

export default RoleSelect;
