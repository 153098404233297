import React from 'react';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';

const processOptions = (options) => options.map(({
  id, seats, clientName, roomID,
}) => ({
  value: id,
  label: `
      ${seats[0]?.name ? `${seats[0]?.name} - ` : ''}
      ${clientName}
      ${roomID ? ` (${roomID})` : ''}
    `,
}));

const BookingSelect = (props) => (
  <RemoteSelect
    filters={['id_ne', 'site_null', 'site']}
    url="bookings"
    processOptions={processOptions}
    {...props}
  />
);

export default BookingSelect;
