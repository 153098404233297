import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import RelativeDate from 'common/components/RelativeDate/RelativeDate';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import formatPrice from 'common/utils/formatPrice';
import useSite from 'sites/contexts/sites';
import NotFoundImage from '../../../../../assets/image-not-found.jpg';

const BookingModalOrder = ({ order }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();

  if (!order) {
    return (<span>{t('orders.noOrder')}</span>);
  }

  return (
    <div className="booking-modal--order">
      <div className="booking-modal--order-text">
        <span>{`N° ${order.id} - `}</span>
        <RelativeDate value={order.created_at} />
        <span>{` - ${t(`orders.${order.state}`)}`}</span>
        <span className="ml tag">{t(`orders.${order.payment.status.toLowerCase()}`)}</span>
      </div>
      <div className="booking-modal--order-items">
        {order.order_items.map((item) => (
          <>
            <div key={item.id} className="booking-modal--order-item">
              <div className="booking-modal--order-item--image" style={{ backgroundImage: `url(${NotFoundImage})` }} />
              <div className="booking-modal--order-item--content">
                <div className="booking-modal--order-item--meta">
                  <span className="booking-modal--order-item--name">
                    <TranslatedText value={item.product.name} />
                  </span>
                  <div className="booking-modal--order-item--quantity">
                    <span>{item.quantity}</span>
                  </div>
                </div>
                <div className="booking-modal--order-item--price">
                  <span className="booking-modal--order-item--price-amount">
                    {formatPrice(item.product.price, currentSite?.currency)}
                  </span>
                </div>
              </div>
            </div>
            <div>
              {item.selected_options.map((selected) => (
                <div style={{ padding: '0 .5rem 0 2rem' }}>
                  {selected.variants.map((variant) => (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <TranslatedText value={variant.name} />
                      <span>
                        {`${variant.price
                          ? formatPrice(variant.price, currentSite?.currency) : formatPrice(0, currentSite?.currency)}`}

                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

BookingModalOrder.propTypes = {
  order: PropTypes.object,
};

BookingModalOrder.defaultProps = {
  order: null,
};

export default BookingModalOrder;
