import React from 'react';
import { CirclePicker } from 'react-color';
import PropTypes from 'prop-types';

const CircleColorPicker = ({ value: color, onChange, ...props }) => (
  <CirclePicker color={color} onChange={({ hex }) => onChange(hex)} {...props} />
);

CircleColorPicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
};

CircleColorPicker.defaultProps = {
  value: undefined,
  onChange: () => {},
};

export default CircleColorPicker;
