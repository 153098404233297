import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const [active, setActive] = useState(true);
  const [collapsible] = useState(false);

  const value = useMemo(() => ({
    menuActive: active,
    collapsible,
    toggleMenu: () => setActive(!active),
  }), [
    active,
    collapsible,
  ]);

  return (
    <MenuContext.Provider value={value}>
      {children}
    </MenuContext.Provider>
  );
};

MenuProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MenuContext;
