import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import ContextProvider from 'common/contexts/crud';

const CategoryServiceContext = createContext();

export const CategoryServicesProvider = ({ children }) => (
  <ContextProvider url="category-services" context={CategoryServiceContext}>{children}</ContextProvider>
);

CategoryServicesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useCategoryService = () => useContext(CategoryServiceContext);

export default useCategoryService;
