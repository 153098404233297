/* eslint-disable react/prop-types */
import React, {
  useMemo, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'react-simple-tooltip';
import useRouter from 'common/hooks/use-router';

import Table from 'common/components/Table/Table';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Button from 'common/components/Button/Button';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import useProduct from 'products/contexts/products';
import CategorySelect from 'categories/components/CategorySelect';
import useCategory from 'categories/contexts/categories';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';
import useTrans from 'common/hooks/use-trans';
import formatPrice from 'common/utils/formatPrice';
import { CSVDownload } from 'react-csv';

const ProductsList = () => {
  const { t } = useTranslation();
  const { translateText } = useTrans();
  const router = useRouter();
  const { currentSite } = useSite();
  const [isExporting, setIsExporting] = useState(false);
  const [data, setData] = useState(null);
  const {
    exportProductsToCSV,
  } = useProduct();

  console.log({ currentSite });

  const {
    fetchItems: fetchProducts,
    items: products,
    isFetching,
    pageCount,
    remove,
    update,
    filters,
    setFilter: setProductsFilter,
    itemsCount,
  } = useProduct();
  const {
    setFilter: setCategoryFilter,
  } = useCategory();

  useEffect(() => {
    setProductsFilter('site_null', 0);
    setCategoryFilter('site_null', 0);
  }, [setProductsFilter, setCategoryFilter]);

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    await fetchProducts();
  }, [remove, fetchProducts]);

  const handleEdit = useCallback((row) => router.push(`/products/edit/${row.original.id}`), [router]);

  const handleToggle = useCallback(async (id) => {
    const product = products && products.find((p) => p.id === id);

    await update(product.id, { ...product, active: !product.active });
    await fetchProducts();
  }, [update, fetchProducts, products]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      name: 'name',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { id, name } } }) => (
        <span className="table-link" onClick={() => router.push(`/products/edit/${id}`)}>
          <TranslatedText value={name} />
        </span>
      ),
    },
    {
      Header: t('common.reference'),
      accessor: 'reference',
      filterName: 'reference_contains',
    },
    {
      Header: t('common.price'),
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { option, price, variant_prices: variantPrices } } }) => {
        if (option && variantPrices.length) {
          return variantPrices.filter(({ price }) => price !== null).map(({ price, option_variant: variant }) => (
            <div>
              {`${translateText(variant?.name)}: ${formatPrice(price, currentSite?.currency)}`}
              {' '}
            </div>
          ));
        }
        return (
          <span>
            {`${formatPrice(price, currentSite?.currency)}`}
          </span>
        );
      },
    },
    {
      Header: t('products.category'),
      filterName: 'category.id',
      filterComponent: (props) => <CategorySelect isFilter {...props} />,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { category } } }) => (<TranslatedText value={category?.name} />),
    },
    {
      Header: t('common.site'),
      filterName: 'site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { site } } }) => (
        <span>
          {site?.name}
        </span>
      ),
    },
    {
      Header: t('common.isOnSale'),
      accessor: 'active',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { active, id } } }) => (
        <Button
          onClick={() => handleToggle(id)}
          icon={active ? 'fa-check' : 'fa-times'}
          color={active ? 'success' : 'danger'}
          transparent
        />
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          {/* eslint-disable-next-line react/prop-types */}
          {row?.original?.MenuItemMasterID && (
          <Tooltip content={translateText('common.productLinkedToPos')}>
            <span className="icon">
              <i className="fas fa-desktop" />
            </span>
          </Tooltip>
          )}
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            data-cy="button_edit"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            data-cy="button_delete"
            confirm
            transparent
            confirmMessage={t('products.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, currentSite, translateText, handleToggle, handleEdit, handleDelete, router]);

  const extraLink = currentSite
    ? { url: '/import-products', icon: 'file-import', label: t('products.importProducts') }
    : null;

  const exportFile = useCallback(async () => {
    try {
      setIsExporting(true);
      const datas = await exportProductsToCSV();

      setData(datas);
      setIsExporting(false);
      setData(null);
    } catch (error) {
      console.log(error);
      setIsExporting(false);
    }
  }, [exportProductsToCSV]);

  return (
    <>
      <PageHeader
        title={t('menu.listProducts')}
        link={{ url: '/products/add', icon: 'pizza-slice', label: t('products.addProduct') }}
        extraLink={extraLink}
        additionalLink={{
          onClick: exportFile,
          icon: 'file-export',
          label: t('products.exportProducts'),
          children: (isExporting && data && (
            <CSVDownload data={data} target="_blank" separator=";" />
          )),
        }}
      />
      <section className="section list-page">
        <Table
          data={products}
          columns={columns}
          fetchData={fetchProducts}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setProductsFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default ProductsList;
