import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import FormInput from 'common/components/FormInput/FormInput';
import SiteSelect from 'sites/components/SiteSelect';
import QRCode from 'qrcode.react';

const ListForm = ({ onSubmit, list }) => {
  const { t } = useTranslation();

  const initialValues = useMemo(() => list?.id ? {
    ...list,
    name: list.name,
    sites: list.sites?.map(({ id }) => id),
  }
    : { },
  [list]);

  return (
    <>
      <section className="section" style={{ marginTop: 20, marginBottom: 20 }}>
        <div className="row">
          <div>
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              render={({
                handleSubmit,
                submitting,
                pristine,
              }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <FormInput
                    name="name"
                    label={t('list.name')}
                    required
                  />
                  <FormInput
                    type="custom"
                    name="sites"
                    label={t('list.sites')}
                    required
                  >
                    <SiteSelect multiple />
                  </FormInput>

                  <FormInput
                    type="submit"
                    label={t('common.save')}
                    icon="check"
                    disabled={submitting || pristine}
                  />

                </form>
              )}
            />
          </div>

          <div style={{
            textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          >
            {list?.id ? (
              <QRCode
                size={200}
                value={`https://app.mybea.fr/?list=${list?.id}`}
              />
                ) : null}
          </div>
        </div>
      </section>
    </>
  );
};

ListForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  list: PropTypes.object,
};

ListForm.defaultProps = {
  list: {},
};

export default ListForm;
