import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const PriceAreaContext = createContext();

export const PriceAreasProvider = ({ children }) => (
  <ContextProvider url="price-areas" context={PriceAreaContext}>{children}</ContextProvider>
);

PriceAreasProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const usePriceArea = () => useContext(PriceAreaContext);

export default usePriceArea;
