import React, {
  useEffect, useContext, useCallback,
} from 'react';

import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import PageHeader from 'common/components/PageHeader/PageHeader';

import useOption from 'products/contexts/options';

import PageLoader from 'common/components/PageLoader/PageLoader';
import OptionForm from './OptionForm';

const OptionFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem: fetchOption, item: option, isFetching,
  } = useOption();
  const { id: optionId } = router.match.params;

  useEffect(() => { fetchOption(optionId); }, [fetchOption, optionId]);

  const onSubmit = useCallback(async (option) => {
    if (option.id) {
      const res = await update(option.id, option);

      if (!res.error) {
        setAlert(t('options.successEdit'), 'success');
        router.push('/options');
      } else {
        setAlert(res.message, 'success');
      }
    } else {
      const res = await save(option);

      if (!res.error) {
        setAlert(t('options.successAdd'), 'success');
        router.push('/options');
      } else {
        setAlert(res.message, 'success');
      }
    }
  }, [router, update, save, setAlert, t]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`options.${option && option.id ? 'edit' : 'add'}Option`)}
        subLink={{ url: '/options', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <OptionForm
          onSubmit={onSubmit}
          option={option}
        />
      </section>
    </>
  );
};

export default OptionFormPage;
