let device = null;

export const getDevice = () => device;

export const print = (name, dataToWrite) => {
  if (!device) {
    // eslint-disable-next-line no-undef
    BrowserPrint.getLocalDevices((devicesList) => {
      console.log({ devicesList });
      device = devicesList.printer.find((d) => d.name === name);
      startPrint(dataToWrite);
    });
  } else {
    startPrint(dataToWrite);
  }
};

export const startPrint = (dataToWrite) => {
  try {
    console.log(dataToWrite, 'ici');
    device.send(dataToWrite, (status) => {
      console.log({ status });
    }, (errorCallback) => {
      console.error(errorCallback);
      device = null;
    });
  } catch (e) {
    console.error('acach', e);
    device = null;
  }
};

export default print;
