import React, { Fragment, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import useBooking from 'bookings/contexts/bookings';
import useBeachVisualizer from '../../contexts/beachVisualizer';
import Overlay from './Overlay';

const BoardSquare = ({
  item,
}) => {
  const {
    handleClickonSquare, selectedSeats, date, searchSeat,
  } = useBeachVisualizer();

  const { items: bookingFilteredList } = useBooking();

  const color = '';
  const today = moment().utc().startOf('day').format();

  const filteredItemBookings = useMemo(() => (
    bookingFilteredList.filter((booking) => booking.seats?.map((seat) => seat.id).includes(item.id))
  ), [bookingFilteredList, item]);

  const isFullyPaid = useMemo(() => {
    let returnValue = true;

    (item.bookings || []).forEach((booking) => {
      if (booking.payment?.status !== 'PAID') {
        returnValue = false;
      }
      (booking.orders || []).forEach((order) => {
        if (order.payment?.status !== 'PAID') {
          returnValue = false;
        }
      });
    });

    return returnValue;
  }, [item.bookings]);

  const isCustomerPresent = useCallback((booking) => (
      booking.booking_items?.find((bookingItem) => moment(date).diff(bookingItem.date, 'day') === 0)?.valid
  ), [date]);

  const overlayColorWithoutBookings = useMemo(() => {
    let returnValue = '';

    if (searchSeat !== '') {
      if (item.name?.toLowerCase().includes(searchSeat.toLowerCase())) {
        returnValue = 'found';
      }
    }
    if (selectedSeats.find(({ id }) => id.toString() === item.id.toString())) {
      returnValue = 'selected';
    }

    if (returnValue === '' && date === today && item.calls && item.calls.length > 0) {
      returnValue = 'call';
    }

    return returnValue;
  }, [searchSeat, selectedSeats, date, today, item]);

  const getBookingOverlayColor = useCallback((booking) => {
    let returnValue = '';

    if (searchSeat !== '') {
      if (item.name?.toLowerCase().includes(searchSeat.toLowerCase())
          || booking.clientName?.toLowerCase().includes(searchSeat.toLowerCase())
          || booking.code?.toLowerCase().includes(searchSeat.toLowerCase())
          || booking.user?.email.includes(searchSeat.toLowerCase())) {
        returnValue = 'found';
      }
    }

    if (returnValue === '') {
      if (booking.orders
            && booking.orders.length > 0) {
        (booking.orders.forEach((order) => {
          if (order.state !== 'done') {
            returnValue = 'selected';
          }
        }));
      }
    }
    if (returnValue === '' && booking && isFullyPaid) {
      returnValue = 'paid';
    }
    if (returnValue === '' && booking) {
      returnValue = isCustomerPresent(booking) ? 'valid' : 'reserved';
    }

    return returnValue;
  }, [searchSeat, isFullyPaid, item.name, isCustomerPresent]);

  /* const getOverlays = useMemo(() => {
    if (searchSeat !== '') {
      if (item.name?.toLowerCase().includes(searchSeat.toLowerCase())) {
        return ['yellow', 'yellow'];
      }
    }
    // TODO : No calls property on a seat ?!
    //      if (date === today && item.calls && item.calls.length > 0) {
    //        returnValue = 'orange';
    //      }

    return filteredItemBookings.map((booking, index) => {
      if (searchSeat !== '') {
        if (item.name?.toLowerCase().includes(searchSeat.toLowerCase())
            || booking.clientName?.toLowerCase().includes(searchSeat.toLowerCase())
            || booking.code?.toLowerCase().includes(searchSeat.toLowerCase())
            || booking.user?.email.includes(searchSeat.toLowerCase())
            || (booking.roomID && booking.roomID.includes(searchSeat.toLowerCase()))) {
          return 'yellow';
        }
      }

      if (booking.orders && booking.orders.length > 0) {
        let hasOrderNotDone = false;

        (booking.orders.forEach((order) => {
          if (order.state !== 'done') {
            hasOrderNotDone = true;
          }
        }));
        if (hasOrderNotDone) return 'blue';
      }

      if (isFullyPaid[index]) {
        return 'allGood';
      }
      if (isCustomerValidated[index]) {
        return 'valid';
      }
      return 'reserved';
    });
  }, [searchSeat, filteredItemBookings, item, isFullyPaid, isCustomerValidated]); */

  const isCall = useMemo(() => {
    let returnValue = false;

    if (item.bookings && date === today && item.calls && item.calls.length > 0) {
      returnValue = true;
    }

    return returnValue;
  }, [item.bookings, date, today, item.calls]);

  const getOrders = useMemo(() => {
    let returnValue = false;

    filteredItemBookings.forEach((booking) => {
      if (booking.orders
        && booking.orders.length > 0) {
        (booking.orders.forEach((order) => {
          if (order.state !== 'done') {
            if (!returnValue) {
              returnValue = order.created_at;
            } else if (returnValue > order.created_at) {
              returnValue = order.created_at;
            }
          }
        }));
      }
    });

    return returnValue;
  }, [filteredItemBookings]);

  /* 29/07/2022 */

  // eslint-disable-next-line no-unused-vars
  const renderCurrentCallFlag = (currentBooking) => {
    const hasCurrentCall = item.calls?.find((call) => (
      call.booking.clientName === currentBooking.clientName
      && call.booking.stayDuration === currentBooking.stayDuration
    ));

    return hasCurrentCall ? (
      <div
        onClick={() => handleClickonSquare(item)}
        className={`info${currentBooking.stayDuration}
          ${moment().diff(item.calls[0].created_at, 'minutes') > 10 ? 'callInfo late' : 'callInfo'}
          `}
      >
        <div className="icon-bell">
          <img src="/assets/bell.png" alt="Call" />
        </div>
      </div>
    ) : null;
  };

  const renderCurrentOrderFlag = (currentBooking) => currentBooking.orders.length ? (
    <Fragment key={currentBooking.id}>
      <div
        onClick={() => handleClickonSquare(item, currentBooking.id)}
        className={`${moment().diff(getOrders, 'minutes') > 10 ? 'orderInfo late' : 'orderInfo'}  
        image${currentBooking.stayDuration}`}
      >

        <div className="icon-utensils">
          <img src="/assets/utensils.png" alt="Waiting Orders" />
        </div>
      </div>
    </Fragment>
  ) : null;

  return (
    <div
      style={{
        backgroundColor: color,
        transform: item.rotate ? `rotate(${item.rotate}deg)` : null,
      }}
      className="BoardSquare mouse-select"
    >
      <div
        onClick={() => handleClickonSquare(item)}
        className="Square"
      >
        { item?.furniture?.image && (
        <>
          <span className="seatNumber">
            {item.name}
          </span>
          <img alt={item.name} src={`${process.env.REACT_APP_API_URL}${item.furniture.image.url}`} />
        </>
      ) }
      </div>

      { item.bookings.length === 0 && (
        <Overlay
          color={overlayColorWithoutBookings}
          stayDuration="fullday"
          isSeveralDays={item.bookings[0]?.booking_items.length > 1}
          onClick={() => handleClickonSquare(item)}
        />
      )}
      {
        item.bookings.length === 1 && (
          item.bookings[0]?.stayDuration === 'monday'
          || item.bookings[0]?.stayDuration === 'afternoon'
        ) && (
        <Overlay
          key="extra-space"
          color={overlayColorWithoutBookings}
          stayDuration={item.bookings[0]?.stayDuration === 'morning' ? 'afternoon' : 'morning'}
          isSeveralDays={false}
          onClick={() => handleClickonSquare(item)}
        />
        )
      }
      {(item.bookings || []).map((booking) => (
        <>
          <Overlay
            key={booking.id}
            color={getBookingOverlayColor(booking)}
            stayDuration={booking.stayDuration ?? 'fullday'}
            isSeveralDays={booking.booking_items?.length > 1}
            onClick={() => handleClickonSquare(item, booking.id)}
          />

          {/* TODO : No calls property on item Seat
            {(item.calls && item.calls.length > 0) && renderCurrentCallFlag(booking)}
          */}

          {(!isCall && getOrders && filteredItemBookings?.map((booking) => (
            renderCurrentOrderFlag(booking)
          )))}

          {booking.clientName && (
            <div
              onClick={() => handleClickonSquare(item, booking.id)}
              className={`clientName${booking.stayDuration}`}
            >
              <p className="clientNameContainer">{booking.clientName}</p>
            </div>
          )}
        </>
      ))}

      {/* { selectedSeats.find(({ id }) => id === item.id)
        ? (
          <Overlay
            color="blue"
            stayDuration={stayDuration}
            onClick={() => handleClickonSquare(item)}
          />
        )
        : (
          <Overlay
            onClick={() => handleClickonSquare(item)}
          />
        )}
    */}

    </div>
  );
};

BoardSquare.propTypes = {
  item: PropTypes.object.isRequired,
};

export default BoardSquare;
