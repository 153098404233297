import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Modal = ({
  confirmModal, closeModal, children, isConfirm,
}) => {
  const { t } = useTranslation();

  const handleEscape = useCallback((e) => {
    e.keyCode === 27 && closeModal();
  }, [closeModal]);

  useEffect(() => {
    const keyup = document.addEventListener('keyup', handleEscape);

    return () => document.removeEventListener('keyup', keyup);
  }, [handleEscape]);

  return (
    <div className="modal is-active is-clipped">
      <div className="modal-background" onClick={closeModal} />
      <div className="modal-card">
        <div className="modal-card-body">
          { children }
          {isConfirm && (
            <div className="buttons">
              <button onClick={closeModal} data-cy="cancel" className="button is-dark is-outlined">
                <span className="icon">
                  <i className="fas fa-times" />
                </span>
                <span>{t('modal.cancel')}</span>
              </button>
              <button onClick={confirmModal} data-cy="confirm" className="button is-warning">
                <span className="icon">
                  <i className="fas fa-exclamation-triangle" />
                </span>
                <span>{t('modal.confirm')}</span>
              </button>
            </div>
          )}
        </div>
      </div>
      <button onClick={closeModal} className="modal-close is-large" aria-label="close" />
    </div>
  );
};

Modal.propTypes = {
  isConfirm: PropTypes.bool,
  children: PropTypes.node.isRequired,
  confirmModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  isConfirm: false,
};

export default Modal;
