import React, {
  useState, useEffect, useCallback, useContext, useMemo,
} from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
// import { Input } from 'common/components/FormInput/FormInput';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { createUrl } from 'common/utils/createUrl';
import PageHeader from 'common/components/PageHeader/PageHeader';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import fetchJSON from 'common/utils/fetchJSON';
import formatPrice from 'common/utils/formatPrice';
import useSite from 'sites/contexts/sites';
import AlertsContext from 'common/contexts/alerts';
import Button from 'common/components/Button/Button';
import PageLoader from '../../../common/components/PageLoader/PageLoader';

dayjs.extend(utc);

const Stats = () => {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);
  const { item: site, currentSite } = useSite();
  const [bookingResults, setBookingResults] = useState([]);
  const [seatsResults, setSeatsResults] = useState([]);

  const [startDate, setStartDate] = useState(dayjs().hour(0).minute(0).second(0));
  const [period, setPeriod] = useState('day'); // possible values : ['day', 'week', 'month']
  const [smsAmount, setSmsAmount] = useState(0);

  const endDate = useMemo(() => startDate.add(1, period), [period, startDate]);

  useEffect(() => {
    if (startDate.isAfter(dayjs())) {
      setStartDate(startDate.subtract(1, period));
    }
  }, [startDate, period]);

  const previous = useCallback(() => {
    setStartDate(startDate.subtract(1, period));
  }, [period, startDate]);

  const next = useCallback(() => {
    setStartDate(startDate.add(1, period));
  }, [period, startDate]);

  useEffect(() => {
    if (!startDate.isAfter(dayjs())) {
      const fetchBookings = async () => {
        try {
          const result = await fetchJSON({
            url: 'bookings?'
            + `created_at_gte=${startDate.startOf('day').format('YYYY-MM-DD')}&`
            + `created_at_lte=${endDate.endOf('day').format('YYYY-MM-DD')}`,
            method: 'GET',
          });

          setBookingResults(result);
        } catch (e) {
          setAlert(t('bookings.errorBookingInfo'), 'danger');
        }
      };

      const fetchSeats = async () => {
        try {
          const result = await fetchJSON({
            url: 'seats?_start=0&_limit=1000&'
            + '_where[_or][0][outOfOrder_null]=true&_where[_or][1][outOfOrder]=false&'
            + '_where[map.active]=true&_where[site]=6',
            method: 'GET',
          });

          setSeatsResults(result);
        } catch (e) {
          setAlert(t('seats.errorSeatsInfo'), 'danger');
        }
      };

      const fetchSms = async () => {
        try {
          const params = {
            startDate: startDate.format().split('T')[0],
            endDate: endDate.format().split('T')[0],
          };
          const esc = encodeURIComponent;
          const smsQueryParams = Object.keys(params).map((key) => `${esc(key)}=${esc(params[key])}`).join('&');

          fetchJSON({
            url: `sms-records/stats?${smsQueryParams}`,
            method: 'GET',
          }).then((results) => {
            setSmsAmount(results.smsAmount);
          });
        } catch (e) {
          setAlert(t('seats.errorSeatsInfo'), 'danger');
        }
      };

      fetchBookings();
      fetchSeats();
      fetchSms();
    }
  }, [endDate, setAlert, startDate, t]);

  const stats = useMemo(() => {
    const bookings = bookingResults.filter(
      (b) => b.created_at <= dayjs(startDate).utc().endOf('day').format()
          && b.created_at >= dayjs(endDate).utc().startOf('day').format(),
    );

    // Get total number of seats (reservable)
    const nbSiteSeats = seatsResults?.length;
    const nbSeatsForPeriod = nbSiteSeats * (dayjs(endDate).diff(startDate, 'days') + 1);

    // Get total of booking cost
    const bookingTotalCost = bookings.reduce((acc, b) => (acc + b.total), 0);

    // Get nb of seats booked / seats available.
    const seatsUsed = bookings.reduce((acc, b) => (acc + b.seats?.length), 0);

    // Get average order cost
    const products = [];

    let ordersCost = 0;

    let ordersNb = 0;

    let productsKey;

    bookings.forEach((booking) => {
      ordersNb += booking.orders.length;
      ordersCost += booking.orders.reduce((acc, b) => (acc + b.price), 0);
      booking.orders.forEach((order) => {
        order.order_items.forEach((product) => {
          const currentProduct = products.findIndex((p) => product?.reference === p.product?.reference);

          if (currentProduct === -1) {
            product.count = 0;
            productsKey = products.push(product) - 1;
          } else {
            productsKey = currentProduct;
          }

          products[productsKey].count += product.quantity;
        });
      });
    });

    const orderAverageCost = ordersNb !== 0 ? ordersCost / ordersNb : 0;

    // 5) Get topProducts
    const topProducts = products.sort((a, b) => b.count - a.count)?.slice(0, 8);

    const nbNoShow = bookings.reduce(
      (total, b) => total + b.booking_items.filter(
        (bi) => bi.status === 'noShow',
      ).length,
      0,
    );
    const nbCanceled = bookings.reduce(
      (total, b) => total + b.booking_items.filter(
        (bi) => bi.status === 'cancelled',
      ).length,
      0,
    );

    return {
      nbBookings: bookings.length,
      nbNoShow,
      nbCanceled,
      bookingTotalCost,
      seatsUsed,
      seatsNb: nbSeatsForPeriod,
      seatsOccupancy: `${seatsUsed}/${nbSeatsForPeriod}`,
      ordersNb,
      ordersCost,
      orderAverageCost,
      topProducts,
    };
  }, [bookingResults, endDate, seatsResults.length, startDate]);

  const bookingChart = useMemo(() => {
    // Nombre de transat (40/80),
    const bookingSeatsNb = [];
    // taux d’occupation (40%),
    const bookingSeatsPercent = [];
    // C.A des ventes de transats,
    const bookingCost = [];
    // Nombre de commande FnB
    const orderNb = [];
    // C.A des ventes de produits (CA Total, Moyenne total/nbre de commande),
    const orderPaid = [];
    const orderAveragePaid = [];
    const dates = [];

    for (let m = startDate; !m.isAfter(endDate); m = m.add(1, period)) {
      const bookings = bookingResults.filter(
        (b) => b.created_at <= dayjs(m).utc().endOf('day').format()
          && b.created_at >= dayjs(m).utc().startOf('day').format(),
      );

      // Get total number of seats (reservable)
      const nbSiteSeats = currentSite.seats?.filter((s) => s.outOfOrder !== true)?.length;

      let totalOrderCount = 0;

      let totalOrderPaid = 0;

      bookings.forEach((b) => {
        totalOrderCount += b.orders?.length;
        totalOrderPaid += b.booking_items.reduce(
          (total, bi) => total + bi.price,
          0,
        );
      });

      const nbReservedSeats = bookings.reduce((acc, b) => (acc + b.seats?.length), 0);

      bookingSeatsNb.push(nbReservedSeats);
      bookingSeatsPercent.push(Math.round((nbReservedSeats / nbSiteSeats) * 100));
      bookingCost.push(bookings.reduce((acc, b) => (acc + b.total), 0));
      orderNb.push(totalOrderCount);
      orderPaid.push(totalOrderPaid);
      orderAveragePaid.push(totalOrderCount ? Math.round(totalOrderPaid / totalOrderCount) : 0);
      dates.push(m.format());
    }
    return {
      bookingSeatsNb, bookingSeatsPercent, bookingCost, orderNb, orderPaid, orderAveragePaid, dates,
    };
  }, [bookingResults, currentSite, endDate, period, startDate]);

  /* const setRow = useCallback((label, property, isCurrency = false) => (
    <div className="row">
      <div className="columns">
        <div className="column is-two-fifths" style={{ minWidth: '240px' }}>
          <div className="is-size-4">
            {label}
          </div>
        </div>
        {[statDay, statWeek, statMonth].map((d, i) => {
          console.log(d[property]);
          return (
            <div key={`${property}-${i}`} className="column has-text-right">
              <strong className="is-size-4">
                {isCurrency ? `${d[property]}` : formatPrice(d[property], currentSite?.currency)}
              </strong>
            </div>
          );
        })}
      </div>
    </div>
  ), [currentSite, statDay, statMonth, statWeek]); */

  return (
    <>
      <PageLoader processing={!stats} />
      {(stats)
      && (
      <>
        <PageHeader title={t('menu.stats')} />
        <section className="section list-page">

          <div className="date-controller">
            <Button onClick={previous} icon="fa-chevron-left" additionnalClasses="date-nav" />
            <Button
              color={period === 'day' ? 'primary' : undefined}
              onClick={() => setPeriod('day')}
              label={t('date.day')}
            />
            <Button
              color={period === 'week' ? 'primary' : undefined}
              onClick={() => {
                setStartDate(startDate.day(1));
                setPeriod('week');
              }}
              label={t('date.week')}
            />
            <Button
              color={period === 'month' ? 'primary' : undefined}
              onClick={() => {
                setStartDate(startDate.date(1));
                setPeriod('month');
              }}
              label={t('date.month')}
            />
            <Button
              onClick={next}
              icon="fa-chevron-right"
              additionnalClasses="date-nav"
              disabled={startDate.add(1, period).isAfter(dayjs())}
            />
          </div>

          <div className="date-title">
            <h1 className="is-size-4 has-text-grey-dark">
              {t('date.fromTo', {
                from: startDate.format('DD/MM/YYYY'),
                to: endDate.format('DD/MM/YYYY'),
              })}
            </h1>
          </div>
          {(currentSite?.smsIsActive || (smsAmount !== 0 && smsAmount != null && smsAmount !== undefined)) && (
          <div className="pb-2">
            <span className="box is-inline">
              <strong className="is-size-5 has-text-black">{t('sms.nbSms', { nbSms: smsAmount })}</strong>
              <br />
            </span>
          </div>
          )}

          <div className="columns">
            <div className="column is-half">
              <div className="box">
                <article className="media">
                  <div className="media-content">
                    <div className="rows">
                      <div className="row">
                        <div className="columns">
                          <div className="column" style={{ minWidth: '240px' }}>
                            <div className="is-size-4">
                              {t('stats.occupancy')}
                            </div>
                          </div>
                          <div className="column has-text-right">
                            <strong className="is-size-4">
                              {`${stats.seatsOccupancy}`}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="columns">
                          <div className="column" style={{ minWidth: '240px' }}>
                            <div className="is-size-4">
                              {t('stats.noShows')}
                            </div>
                          </div>
                          <div className="column has-text-right">
                            <strong className="is-size-4">
                              {`${stats.nbNoShow}`}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="columns">
                          <div className="column" style={{ minWidth: '240px' }}>
                            <div className="is-size-4">
                              {t('stats.cancelation')}
                            </div>
                          </div>
                          <div className="column has-text-right">
                            <strong className="is-size-4">
                              {`${stats.nbCanceled}`}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="columns">
                          <div className="column" style={{ minWidth: '240px' }}>
                            <div className="is-size-4">
                              {t('stats.turnover')}
                            </div>
                          </div>
                          <div className="column has-text-right">
                            <strong className="is-size-4">
                              {formatPrice(stats.bookingTotalCost || 0, currentSite?.currency)}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="columns">
                          <div className="column" style={{ minWidth: '240px' }}>
                            <div className="is-size-4">
                              {t('stats.nbOrders')}
                            </div>
                          </div>
                          <div className="column has-text-right">
                            <strong className="is-size-4">
                              {`${stats.ordersNb}`}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="columns">
                          <div className="column" style={{ minWidth: '240px' }}>
                            <div className="is-size-4">
                              {t('stats.averageBasket')}
                            </div>
                          </div>
                          <div className="column has-text-right">
                            <strong className="is-size-4">
                              {formatPrice(stats.averageOrder || 0, currentSite?.currency)}
                            </strong>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-two-thirds">
              {/* <div className="columns">
                <div className="column is-4 is-offset-8">
                  <Input
                    type="select"
                    options={[
                      {
                        value: 'day',
                        label: t('common.day'),
                      },
                      {
                        value: 'week',
                        label: t('common.week'),
                      },
                      {
                        value: 'month',
                        label: t('common.month'),
                      }, {
                        value: 'year',
                        label: t('common.year'),
                      }]}
                  />
                </div>
              </div> */}
              <Chart
                options={{
                  chart: {
                    height: 350,
                    type: 'line',
                  },
                  stroke: {
                    width: [0, 4],
                  },
                  title: {
                    text: t('common.business'),
                  },
                  dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1],
                    formatter(val) {
                      /* return `${val} %`; */
                      return val ? `${val}` : undefined;
                    },
                  },
                  tooltip: {
                    y: [{
                      formatter(val) {
                        /* return `${formatPrice(val, currentSite?.currency)}`; */
                        return `${val}`;
                      },
                    }, {
                      formatter(val) {
                        /* return `${val} %`; */
                        return `${val}`;
                      },
                    }],
                  },
                  labels: bookingChart.dates || [],
                  xaxis: {
                    type: 'datetime',
                  },
                  yaxis: [{
                    title: {
                      text: `${t('menu.orders')}`,
                    },

                  }, {
                    opposite: true,
                    title: {
                      text: `${t('stats.bookedSeats')}`,
                    },
                  }],
                }}
                series={[{
                  name: t('menu.orders'),
                  type: 'column',
                  data: bookingChart.orderNb || [],
                }, {
                  name: t('menu.bookings'),
                  type: 'line',
                  data: bookingChart.bookingSeatsNb || [],
                }]}
                width="100%"
                height="600px"
              />
            </div>
            <div className="column is-quarter">
              <div className="content">
                {/* <h2>{t('stats.promotions')}</h2>
                <Chart
                  options={state2.options}
                  series={state2.series}
                  width="75%"
                  type="pie"
                /> */}
                <h2>{t('stats.topProducts')}</h2>
                <div className="columns">
                  <div className="column">
                    {stats && stats.topProducts.map((product) => (
                      <article className="media" key={product.id}>
                        <figure className="media-left">
                          <p className="image is-32x32">
                            {product?.product?.images?.length > 0
                              && (
                                <img
                                  src={createUrl(product?.product?.images[0]?.url)}
                                  alt={product?.product?.reference
                                    ? `product ${product?.product?.reference}`
                                    : t('Image not found')}
                                />
                              )}
                          </p>
                        </figure>
                        <div className="media-content">
                          <TranslatedText value={product.name} />
                        </div>
                      </article>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      )}
    </>
  );
};

export default Stats;
