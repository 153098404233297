import React, { useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import useBooking from 'bookings/contexts/bookings';
import PageHeader from 'common/components/PageHeader/PageHeader';

import PageLoader from 'common/components/PageLoader/PageLoader';
import BookingForm from './BookingForm';

const BookingFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: booking, error, isFetching,
  } = useBooking();

  const { id: bookingId } = router.match.params;

  useEffect(() => {
    fetchItem(bookingId);
  }, [fetchItem, bookingId]);

  const onSubmit = useCallback(
    async (data) => {
      const booking = { ...data };

      const newDate = moment(booking.date).utc().startOf('day').format();

      booking.date = newDate;

      if (booking.id) {
        await update(booking.id, booking);

        if (!error) {
          setAlert(t('bookings.successEdit'), 'success');
          router.push('/bookings');
        } else {
          setAlert(error, 'danger');
        }
      } else {
        await save({
          ...booking,
          active: true,
        });

        if (!error) {
          setAlert(t('bookings.successAdd'), 'success');
          router.push('/bookings');
        } else {
          setAlert(error, 'danger');
        }
      }
    },
    [update, setAlert, t, router, error, save],
  );

  if (bookingId && !booking) {
    return <PageLoader processing={isFetching} />;
  }

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(
          `bookings.${
            booking && booking.id ? 'edit' : 'add'
          }Booking`,
        )}
        subLink={{
          url: '/bookings',
          icon: 'arrow-left',
          label: t('common.back'),
        }}
      />
      <section className="section form-page">
        <BookingForm onSubmit={onSubmit} booking={booking} />
      </section>
    </>
  );
};

export default BookingFormPage;
