/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';

const processOptions = (options) => options.map(({ id, username }) => (
  { value: id, label: username }
));

const UserSelect = (props) => {
  const { t } = useTranslation();

  return (
    <RemoteSelect
      filters={['id_ne', 'site_null', 'site']}
      url="users"
      placeholder={t('orders.user')}
      processOptions={processOptions}
      {...props}
    />
  );
};

export default UserSelect;
