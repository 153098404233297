import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const PageLoader = ({ processing, timeoutDuration }) => {
  const [state, setState] = useState('idle');
  const timeout = useRef(null);

  useEffect(() => {
    if (processing && state === 'idle') {
      clearTimeout(timeout.current);

      timeout.current = setTimeout(() => {
        setState('display');
      }, timeoutDuration);
      setState('delay');
    }

    if (processing === false) {
      clearTimeout(timeout.current);
      setState('idle');
    }

    return () => clearTimeout(timeout.current);
  }, [state, timeoutDuration, processing]);

  if (state === 'display') {
    return (
      <div className="loader-wrapper">
        <div className="loader is-loading" />
      </div>
    );
  }

  return null;
};

PageLoader.propTypes = {
  processing: PropTypes.bool,
  timeoutDuration: PropTypes.number,
};

PageLoader.defaultProps = {
  processing: true,
  timeoutDuration: 400,
};

export default PageLoader;

export const loader = () => <PageLoader />;
