/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import Table from 'common/components/Table/Table';
import Button from 'common/components/Button/Button';
import PageHeader from 'common/components/PageHeader/PageHeader';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import useCategoryService from 'categoryServices/contexts/categoryServices';
import CategoryServiceSelect from 'categoryServices/components/CategoryServiceSelect';

import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';

const CategoryServicesList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentSite } = useSite();
  const {
    fetchItems: fetchCategoryServices, items: categoryServices, isFetching, pageCount, remove, filters, setFilter,
    itemsCount,
  } = useCategoryService();

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    fetchCategoryServices();
  }, [remove, fetchCategoryServices]);

  const handleEdit = useCallback((row) => router.push(`/categoryServices/edit/${row.original.id}`), [router]);

  /* useEffect(() => {
    setSort('index:DESC');
  }, [setSort]); */

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      Cell: ({ row: { original: { id, name } } }) => (
        <span className="table-link" onClick={() => router.push(`/categoryServices/edit/${id}`)}>
          <TranslatedText value={name} />
        </span>
      ),
    },
    {
      Header: t('common.reference'),
      accessor: 'reference',
      filterName: 'reference_contains',
    },
    {
      Header: t('common.parent'),
      filterName: 'parent.id',
      filterComponent: (props) => <CategoryServiceSelect isFilter {...props} />,
      Cell: ({ row: { original: { parent } } }) => (
        <span>
          {parent && <TranslatedText value={parent?.name} displayEnglish />}
        </span>
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      Cell: ({ row: { original: { site } } }) => (
        <span>
          {site?.name}
        </span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('categoryServices.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, currentSite, handleEdit, handleDelete, router]);

  return (
    <>
      <PageHeader
        title={t('menu.listCategoryServices')}
        link={{ url: '/categoryServices/add', icon: 'tag', label: t('categoryServices.addCategoryService') }}
      />
      <section className="section list-page">
        <Table
          data={categoryServices}
          columns={columns}
          fetchData={fetchCategoryServices}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default CategoryServicesList;
