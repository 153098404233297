import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const CustomerContext = createContext();

export const CustomersProvider = ({ children }) => (
  <ContextProvider url="customers" context={CustomerContext}>{children}</ContextProvider>
);

CustomersProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useCustomer = () => useContext(CustomerContext);

export default useCustomer;
