import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useBeachEditor from '../../contexts/beachEditor';
import usePriceArea from 'priceAreas/contexts/priceAreas';
import useWaiterArea from 'waiterAreas/contexts/waiterAreas';
import useFurniture from 'furnitures/contexts/furnitures';
import Button from 'common/components/Button/Button';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useTrans from 'common/hooks/use-trans';

const meterToPx = 80;

const EditorFooter = () => {
    const { t } = useTranslation();
    const { translateText } = useTrans();

    const {
        incrementationSeat, setIncrementationSeat, getValidNumber,
        seats, selectedSeats, setSelectedSeats, updateSeat, removeSeat,
      } = useBeachEditor();
    
      const {
        items: priceAreas,
      } = usePriceArea();
    
      const {
        items: waiterAreas,
      } = useWaiterArea();

      const {
        items: furnitures,
      } = useFurniture();

    const handleDeleteFurniture = useCallback(() => {
        const deletedSeats = selectedSeats;
    
        setSelectedSeats([]);
        deletedSeats.map((seatId) => {
          removeSeat(seatId);
    
          return null;
        });
    }, [selectedSeats, removeSeat, setSelectedSeats]);

    // creation
    useEffect(() => {
      setIncrementationSeat((current) => {
        if (seats?.length) {
          // quand on change de carte, si le n° d'incrementation est déjà utilisé par le nouveau jeu de sièges
          const currentNameAlreadyUsed = seats.find((seat) => Number(seat.name) === current?.name);
  
          return (
            { ...current,
              name: !currentNameAlreadyUsed ? current?.name : getValidNumber(1),
              price_area: current?.price_area || priceAreas.find((area) => area.default) || priceAreas?.[0],
              waiter_area: current?.waiter_area || waiterAreas.find((area) => area.default) || waiterAreas?.[0],
              furniture: current?.furniture || furnitures?.[0],
            });
        }
        return {
          ...current,
          name: getValidNumber(1),
        };
      });
    }, [setIncrementationSeat, priceAreas, waiterAreas, getValidNumber, seats]);
    
    // edition
    const [scaleValue, setScaleValue] = useState('');
    const [rotateValue, setRotateValue] = useState('');
    const [currentSeats, setCurrentSeats] = useState([]);

    useEffect(() => {
      const currentSelectedSeats = selectedSeats.map((selected) => seats.find((seat) => seat.id === selected));

      setScaleValue(currentSelectedSeats.length === 1 ? currentSelectedSeats[0]?.scale : '');
      setRotateValue(currentSelectedSeats.length === 1 ? currentSelectedSeats[0]?.rotate : '');
      setCurrentSeats(currentSelectedSeats);
    },
      [selectedSeats, setCurrentSeats, setRotateValue, setScaleValue]
    );    
  
    const handleRotate = useCallback((rotate, absolute) => {
      if (!rotate.isNan) {
        const newCurrentSeats = currentSeats.map((seat) => {
          const newProperties = { rotate: seat.rotate && !absolute ? seat.rotate + rotate : rotate };
          updateSeat(seat.id, newProperties);
          return { ...seat, ...newProperties };
        });
        setCurrentSeats(newCurrentSeats);
      }
    }, [updateSeat, currentSeats]);
  
    const handleScale = useCallback((scale, absolute) => {
      if (!scale.isNan) {
        const newCurrentSeats = currentSeats.map((seat) => {
          const newScale = seat.scale && !absolute ? seat.scale + scale : scale;
          const newProperties = {
            scale: newScale,
            x: seat.x + ((seat.scale - newScale) * seat.furniture.width * meterToPx / 2),
            y: seat.y + ((seat.scale - newScale) * seat.furniture.height * meterToPx / 2),
          };
          if (newScale > 0) {
            updateSeat(seat.id, newProperties);
          }
          return { ...seat, ...newProperties };
        });
        setCurrentSeats(newCurrentSeats);
      }
    }, [updateSeat, currentSeats]);
  
    const handleAlign = useCallback((direction, side) => {
      let refY = 0;
      let refX = 0;
      let newCurrentSeats = {};
  
      if (side === 'middle') {
        const currentSeatsX = currentSeats.map((seat) => seat.x + (seat.scale * seat.furniture.width  * meterToPx / 2));
        const currentSeatsY = currentSeats.map((seat) => seat.y + (seat.scale * seat.furniture.height * meterToPx / 2));
        
        refY = currentSeatsY.reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0,
        ) / currentSeats.length;
        refX = currentSeatsX.reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0,
        ) / currentSeats.length;

        newCurrentSeats = currentSeats.map((seat) => {
          const newProperties = {
            x: direction === 'ver'
              ? Math.round(refX - (seat.scale * seat.furniture.width * meterToPx / 2))
              : seat.x,
            y: direction === 'hor'
              ? Math.round(refY - (seat.scale * seat.furniture.height * meterToPx / 2))
              : seat.y
          };
          if (seat.scale > 0.1) {
            updateSeat(seat.id, newProperties);
          }
          return { ...seat, ...newProperties };
        });
      }
  
      if (side === 'start') {
        const currentSeatsX = currentSeats.map((seat) => seat.x);
        const currentSeatsY = currentSeats.map((seat) => seat.y);

        refY = currentSeatsY.reduce(
          (previousValue, currentValue) => Math.min(previousValue, currentValue),
          +Infinity,
        );
        refX = currentSeatsX.reduce(
          (previousValue, currentValue) => Math.min(previousValue, currentValue),
          +Infinity,
        );

        newCurrentSeats = currentSeats.map((seat) => {
          const newProperties = {
            x: direction === 'ver'
              ? Math.round(refX)
              : seat.x,
            y: direction === 'hor'
              ? Math.round(refY)
              : seat.y
          };
          if (seat.scale > 0.1) {
            updateSeat(seat.id, newProperties);
          }
          return { ...seat, ...newProperties };
        });
      }
  
      if (side === 'end') {
        const currentSeatsX = currentSeats.map((seat) => seat.x + (seat.scale * seat.furniture.width  * meterToPx));
        const currentSeatsY = currentSeats.map((seat) => seat.y + (seat.scale * seat.furniture.height * meterToPx));

        refY = currentSeatsY.reduce(
          (previousValue, currentValue) => Math.max(previousValue, currentValue),
          -Infinity,
        );
        refX = currentSeatsX.reduce(
          (previousValue, currentValue) => Math.max(previousValue, currentValue),
          -Infinity,
        );

        newCurrentSeats = currentSeats.map((seat) => {
          const newProperties = {
            x: direction === 'ver'
              ? Math.round(refX - (seat.scale * seat.furniture.width * meterToPx))
              : seat.x,
            y: direction === 'hor'
              ? Math.round(refY - (seat.scale * seat.furniture.height * meterToPx))
              : seat.y
          };
          if (seat.scale > 0.1) {
            updateSeat(seat.id, newProperties);
          }
          return { ...seat, ...newProperties };
        });
      }
  
      setCurrentSeats(newCurrentSeats)
    }, [currentSeats, updateSeat]);

    const handleSpace = useCallback((direction) => {
      if (currentSeats.length < 3) {
        return null;
      }
      let newCurrentSeats = {};
      if (direction === 'hor') {
        const orderedCurrentSeats = currentSeats.sort((a, b) => a.x - b.x);
        const pointsX = orderedCurrentSeats.map((seat) => seat.x + (seat.furniture.width * seat.scale * meterToPx / 2));
        const nbPt = pointsX.length
        const minPt = pointsX[0];
        const maxPt = pointsX[nbPt - 1];
        const stepDistance = (maxPt - minPt) / (nbPt - 1);
        let incrementalDistance = minPt;
        newCurrentSeats = orderedCurrentSeats.map((seat) => {
          const newProperties = {
            x: Math.round(incrementalDistance - (seat.furniture.width * seat.scale * meterToPx / 2)),
          };
          if (seat.scale > 0.1) {
            updateSeat(seat.id, newProperties);
            incrementalDistance += stepDistance;
          }
          return { ...seat, ...newProperties };
        })

      } else {
        const orderedCurrentSeats = currentSeats.sort((a, b) => a.y - b.y);
        const pointsY = orderedCurrentSeats.map((seat) => seat.y + (seat.furniture.height * seat.scale * meterToPx / 2));
        const nbPt = pointsY.length
        const minPt = pointsY[0];
        const maxPt = pointsY[nbPt - 1];
        const stepDistance = (maxPt - minPt) / (nbPt - 1);
        let incrementalDistance = minPt;
        newCurrentSeats = orderedCurrentSeats.map((seat) => {
          const newProperties = {
            y: Math.round(incrementalDistance - (seat.furniture.height * seat.scale * meterToPx / 2)),
          };
          if (seat.scale > 0.1) {
            updateSeat(seat.id, newProperties);
            incrementalDistance += stepDistance;
          }
          return { ...seat, ...newProperties };
        })
      }
      setCurrentSeats(newCurrentSeats);
    }, [currentSeats, updateSeat]);
  
    return (
    <div className="beach-footer">
        {!selectedSeats.length ? (
        <div className="editor-incrementation">
        <b>
          {t('editor.incrementationTitle')}
        </b>
        <div className="editor-fields-bar">
          <div className="incrementation-item">
            <input
              type="number"
              onChange={(e) => {
                // on laisse libre
                setIncrementationSeat((current) => ({ ...current, name: Number(e.target.value) }));
              }}
              onBlur={(e) => {
                // puis on corrige
                setIncrementationSeat((current) => ({ ...current, name: getValidNumber(current?.name) }));
              }}
              value={incrementationSeat?.name}
            />
          </div>

          <div className="incrementation-item">
            <select
              onChange={(e) => {
                const res = furnitures.find((furniture) => furniture.id === Number(e.target.value));

                setIncrementationSeat((current) => ({ ...current, furniture: res }));
              }}
              style={{ backgroundColor:  'white' }}
              className="editor-incrementation-select"
              value={incrementationSeat?.furniture?.id}

            >
              {furnitures?.map((furniture) => {
                return(
                <option
                  key={`furnitureIncr-${furniture.id}`}
                  value={furniture.id}
                >
                  {translateText(furniture.name)}

                </option>
              )})}
            </select>
          </div>

          <div className="incrementation-item">
            <select
              onChange={(e) => {
                const res = priceAreas.find((area) => area.id === Number(e.target.value));

                setIncrementationSeat((current) => ({ ...current, price_area: res }));
              }}
              style={{ backgroundColor: incrementationSeat?.price_area?.color || 'white' }}
              className="editor-incrementation-select colored"
              value={incrementationSeat?.price_area?.id}
            >
              {priceAreas?.map((area) => (
                <option
                  key={`priceAreaIncr-${area.id}`}
                  value={area.id}
                  style={{ backgroundColor: area.color || 'white' }}
                >
                  {area.name}

                </option>
              ))}
            </select>
          </div>

          <div className="incrementation-item">
            <select
              onChange={(e) => {
                const res = priceAreas.find((area) => area.id === Number(e.target.value));

                setIncrementationSeat((current) => ({ ...current, waiter_area: res }));
              }}
              style={{ backgroundColor: incrementationSeat?.waiter_area?.color || 'white' }}
              className="editor-incrementation-select colored"
              value={incrementationSeat?.waiter_area?.id}

            >
              {waiterAreas?.map((area) => (
                <option
                  key={`waiterAreaIncr-${area.id}`}
                  value={area.id}
                  style={{ backgroundColor: area.color || 'white' }}
                >
                  {area.name}

                </option>
              ))}
            </select>
          </div>
        </div>

        <div/>
      </div>
    ) : (
      <div className="editor-edition">
        <b>
          {t('editor.editionTitle')}
        </b>

        <div className='editor-formatting'>
        <div className="editor-geometric">
        {/* delete */}
        <Button
            onClick={() => handleDeleteFurniture()}
            className="beach-editor-menu--button delete-seats"
            confirm
            confirmMessage={t('editor.confirmDelete')}
            icon="fa-trash"
        >
        </Button>

        {/* transform */}
    
        <button
          className="beach-editor-menu--button"
          onClick={() => { handleRotate(5, false); }}
        >
          <i className="fas fa-redo" />
        </button>
        <button
          className="beach-editor-menu--button"
          onClick={() => { handleRotate(-5, false); }}
        >
          <i className="fas fa-undo" />
        </button>
        <button
          className="beach-editor-menu--button"
          onClick={() => { handleScale(0.1, false); }}
        >
          <i className="fas fa-plus" />
        </button>
        <button
          className="beach-editor-menu--button"
          onClick={() => { handleScale(-0.1, false); }}
        >
          <i className="fas fa-minus" />
        </button>
      </div>

      {currentSeats.length > 1 && (
      <div className="editor-geometric">
        <button
          className="beach-editor-menu--button"
          onClick={() => { handleAlign('ver', 'start'); }}
        >
          <i className="fas fa-align-left" />
        </button>
        <button
          className="beach-editor-menu--button"
          onClick={() => { handleAlign('ver', 'middle'); }}
        >
          <i className="fas fa-align-center" />
        </button>
        <button
          className="beach-editor-menu--button"
          onClick={() => { handleAlign('ver', 'end'); }}
        >
          <i className="fas fa-align-right" />
        </button>
        <button
          className="beach-editor-menu--button"
          onClick={() => { handleSpace('hor'); }}
          disabled={currentSeats.length < 3}
        >
          <i className="fas fa-arrows-alt-h" />
        </button>

        <button
          className="beach-editor-menu--button"
          onClick={() => { handleAlign('hor', 'start'); }}
        >
          <i className="fas fa-align-left" style={{ transform: 'rotate(90deg)' }} />
        </button>
        <button
          className="beach-editor-menu--button"
          onClick={() => { handleAlign('hor', 'middle'); }}
        >
          <i className="fas fa-align-center" style={{ transform: 'rotate(90deg)' }} />
        </button>
        <button
          className="beach-editor-menu--button"
          onClick={() => { handleAlign('hor', 'end'); }}
        >
          <i className="fas fa-align-right" style={{ transform: 'rotate(90deg)' }} />
        </button>
        <button
          className="beach-editor-menu--button"
          onClick={() => { handleSpace('ver'); }}
          disabled={currentSeats.length < 3}
        >
          <i className="fas fa-arrows-alt-v" />
        </button>
      </div>
      )}

      <div className="editor-custom-field">
        <label>{t('editor.customScale')}</label>
        <input
          type="number"
          onChange={(e) => {
            setScaleValue(e.target.value);
            handleScale(Number(e.target.value), true);
          }}
          value={scaleValue}
          placeholder={t('editor.customScalePlaceholder')}
        />
      </div>

      <div className="editor-custom-field">
        <label>{t('editor.customRotate')}</label>
        <input
          type="number"
          onChange={(e) => {
            setRotateValue(e.target.value);
            handleRotate(Number(e.target.value), true);
          }}
          defaultValue={rotateValue}
          placeholder={t('editor.customRotatePlaceholder')}
        />
      </div>
     </div>

      <button onClick={() => setSelectedSeats([])} className="quit">
          <i className="fas fa-times" />
        </button>
      </div>
    )}
    </div>
  
    );
  };
  
  export default EditorFooter;



  