/* eslint-disable max-len */
import SignIn from 'common/pages/SignIn/SignIn';
import ForgotPassword from 'common/pages/ForgotPassword/ForgotPassword';
import ResetPassword from 'common/pages/ResetPassword/ResetPassword';
import UsersList from 'users/pages/UsersList/UsersList';
import UserFormPage from 'users/pages/UserFormPage/UserFormPage';
import SitesList from 'sites/pages/SitesList/SitesList';
import BookingsList from 'bookings/pages/BookingsList/BookingsList';
import SiteFormPage from 'sites/pages/SiteFormPage/SiteFormPage';
import CategoriesList from 'categories/pages/CategoriesList/CategoriesList';
import CategoryFormPage from 'categories/pages/CategoryFormPage/CategoryFormPage';
import CatalogCategoriesList from 'catalog/pages/CatalogCategoriesList/CatalogCategoriesList';
import CatalogCategoryFormPage from 'catalog/pages/CatalogCategoryFormPage/CatalogCategoryFormPage';
import ProductsList from 'products/pages/ProductsList/ProductsList';
import ProductFormPage from 'products/pages/ProductFormPage/ProductFormPage';
import OptionsList from 'products/pages/OptionsList/OptionsList';
import OptionFormPage from 'products/pages/OptionFormPage/OptionFormPage';
import CatalogOptionsList from 'catalog/pages/CatalogOptionsList/CatalogOptionsList';
import CatalogOptionFormPage from 'catalog/pages/CatalogOptionFormPage/CatalogOptionFormPage';
import ImportProducts from 'products/pages/ImportProducts/ImportProducts';
import ImportProductsCsv from 'products/pages/ImportProductsCsv/ImportProductCsv';
import ImportCategories from 'products/pages/ImportCategories/ImportCategories';
import CatalogProductsList from 'catalog/pages/CatalogProductsList/CatalogProductsList';
import CatalogProductFormPage from 'catalog/pages/CatalogProductFormPage/CatalogProductFormPage';
import CategoryServicesList from 'categoryServices/pages/CategoryServicesList/CategoryServicesList';
import CategoryServiceFormPage from 'categoryServices/pages/CategoryServiceFormPage/CategoryServiceFormPage';
import ServicesList from 'services/pages/ServicesList/ServicesList';
import ServiceFormPage from 'services/pages/ServiceFormPage/ServiceFormPage';
import AroundMeList from 'aroundMe/pages/AroundMeList/AroundMeList';
import AroundMeFormPage from 'aroundMe/pages/AroundMeFormPage/AroundMeFormPage';
import CategoryItemsList from 'categoryItems/pages/CategoryItemsList/CategoryItemsList';
import CategoryItemFormPage from 'categoryItems/pages/CategoryItemFormPage/CategoryItemFormPage';
import FurnituresList from 'furnitures/pages/FurnituresList/FurnituresList';
import FurnitureFormPage from 'furnitures/pages/FurnitureFormPage/FurnitureFormPage';
import DecorationFormPage from 'decorations/pages/DecorationFormPage/DecorationFormPage';
import ArchivedOrdersList from 'orders/pages/ArchivedOrdersList/ArchivedOrdersList';
import ActivesOrdersList from 'orders/pages/ActivesOrdersList/ActivesOrdersList';
import BeachEditor from 'sites/pages/BeachEditor/BeachEditor';
import BookingFormPage from 'bookings/pages/BookingFormPage/BookingFormPage';
import BeachVisualizer from 'sites/pages/BeachVisualizer/BeachVisualizer';
import WaiterAreasList from 'waiterAreas/pages/WaiterAreasList/WaiterAreasList';
import WaiterAreaFormPage from 'waiterAreas/pages/WaiterAreaFormPage/WaiterAreaFormPage';
import PriceAreasList from 'priceAreas/pages/PriceAreasList/PriceAreasList';
import PriceAreaFormPage from 'priceAreas/pages/PriceAreaFormPage/PriceAreaFormPage';
import SpecialOffersList from 'specialOffers/pages/SpecialOffersList/SpecialOffersList';
import SpecialOfferFormPage from 'specialOffers/pages/SpecialOfferFormPage/SpecialOfferFormPage';
import MapsList from 'maps/pages/MapsList/MapsList';
import MapFormPage from 'maps/pages/MapFormPage/MapFormPage';
import Page404 from 'common/pages/Page404';
// import Stats from 'orders/pages/Stats/Stats';
import VatList from 'vat/pages/VatList/VatList';
import VatFormPage from 'vat/pages/VatFormPage/VatFormPage';
import ReportsList from 'reports/pages/ReportsList/ReportsList';
import Profile from 'profile/pages/Profile/Profile';
import PosHome from 'pos/pages/Home/Home';
import Test from 'pos/pages/Test/Test';
import Edit from 'pos/pages/Test/Edit';
import Check from 'pos/pages/Test/CheckDetail';
import VoidTransaction from 'pos/pages/Test/VoidTransaction';
import Checks from 'pos/pages/Test/Checks';
import Welcome from 'welcomePage/Welcome';
import SeasonsList from 'seasons/pages/SeasonsList';
import SeasonFormPage from 'seasons/pages/SeasonFormPage';
import ListList from 'sitesList/pages/SitesListList/SiteListList';
import ListFormPage from 'sitesList/pages/SitesListFormPage/SitesListFormPage';
import CustomerCategoryFormPage from 'customers/pages/CustomerCategoryFormPage/CustomerCategoryFormPage';
import CustomerCategoriesList from 'customers/pages/CustomersList/CustomerCategoriesList';
import WatchesList from 'watches/pages/WatchesList/WatchesList';
import WatchFormPage from 'watches/pages/WatchFormPage/WatchFormPage';
import CallTypesList from 'callTypes/pages/CallTypesList';
import CallTypeFormPage from 'callTypes/pages/CallTypeFormPage';
import CustomersList from './customers/pages/CustomersList/CustomersList';
import CustomerFormPage from './customers/pages/CustomerFormPage/CustomerFormPage';

const routes = [
  { path: '/', roles: ['superadmin', 'admin', 'waiter', 'reception', 'manager'], component: Welcome },
  { path: '/users', roles: ['superadmin', 'admin'], component: UsersList },
  { path: '/users/add', roles: ['superadmin', 'admin'], component: UserFormPage },
  { path: '/users/edit/:id', roles: ['superadmin', 'admin'], component: UserFormPage },
  { path: '/customers', roles: ['superadmin', 'admin'], component: CustomersList },
  { path: '/customers/add', roles: ['superadmin', 'admin'], component: CustomerFormPage },
  { path: '/customers/edit/:id', roles: ['superadmin', 'admin'], component: CustomerFormPage },
  { path: '/customer-categories', roles: ['superadmin', 'admin'], component: CustomerCategoriesList },
  { path: '/customer-categories/add', roles: ['superadmin', 'admin'], component: CustomerCategoryFormPage },
  { path: '/customer-categories/edit/:id', roles: ['superadmin', 'admin'], component: CustomerCategoryFormPage },
  { path: '/sites', roles: ['superadmin'], component: SitesList },
  { path: '/sites/add', roles: ['superadmin'], component: SiteFormPage },
  { path: '/sites/edit/:id', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: SiteFormPage },
  { path: '/sites/organize/:id', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: BeachEditor },
  { path: '/sites/monitor/:id', roles: ['superadmin', 'reception', 'admin', 'waiter', 'manager'], component: BeachVisualizer },
  { path: '/seasons', roles: ['superadmin', 'admin'], component: SeasonsList },
  { path: '/seasons/add', roles: ['superadmin'], component: SeasonFormPage },
  { path: '/seasons/edit/:id', roles: ['superadmin'], component: SeasonFormPage },
  { path: '/call-types', roles: ['superadmin', 'admin'], component: CallTypesList },
  { path: '/call-types/add', roles: ['superadmin'], component: CallTypeFormPage },
  { path: '/call-types/edit/:id', roles: ['superadmin'], component: CallTypeFormPage },
  { path: '/categories', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: CategoriesList },
  { path: '/categories/add', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: CategoryFormPage },
  { path: '/categories/edit/:id', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: CategoryFormPage },
  { path: '/catalog-categories', roles: ['superadmin'], component: CatalogCategoriesList },
  { path: '/catalog-categories/add', roles: ['superadmin'], component: CatalogCategoryFormPage },
  { path: '/catalog-categories/edit/:id', roles: ['superadmin'], component: CatalogCategoryFormPage },
  { path: '/products', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: ProductsList },
  { path: '/products/add', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: ProductFormPage },
  { path: '/products/edit/:id', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: ProductFormPage },
  { path: '/options', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: OptionsList },
  { path: '/options/add', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: OptionFormPage },
  { path: '/options/edit/:id', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: OptionFormPage },
  { path: '/import-categories', roles: ['admin', 'superadmin', 'waiter', 'manager'], component: ImportCategories },
  { path: '/import-products', roles: ['admin', 'superadmin', 'waiter', 'manager'], component: ImportProducts },
  { path: '/import-products-csv', roles: ['admin', 'superadmin', 'waiter', 'manager'], component: ImportProductsCsv },
  { path: '/catalog-products', roles: ['superadmin'], component: CatalogProductsList },
  { path: '/catalog-products/add', roles: ['superadmin'], component: CatalogProductFormPage },
  { path: '/catalog-products/edit/:id', roles: ['superadmin'], component: CatalogProductFormPage },
  { path: '/catalog-options', roles: ['superadmin'], component: CatalogOptionsList },
  { path: '/catalog-options/add', roles: ['superadmin'], component: CatalogOptionFormPage },
  { path: '/catalog-options/edit/:id', roles: ['superadmin'], component: CatalogOptionFormPage },
  { path: '/categoryServices', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: CategoryServicesList },
  { path: '/categoryServices/add', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: CategoryServiceFormPage },
  { path: '/categoryServices/edit/:id', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: CategoryServiceFormPage },
  { path: '/services', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: ServicesList },
  { path: '/services/add', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: ServiceFormPage },
  { path: '/services/edit/:id', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: ServiceFormPage },
  { path: '/aroundme', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: AroundMeList },
  { path: '/aroundme/add', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: AroundMeFormPage },
  { path: '/aroundme/edit/:id', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: AroundMeFormPage },
  { path: '/category-items', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: CategoryItemsList },
  { path: '/category-items/add', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: CategoryItemFormPage },
  { path: '/category-items/edit/:id', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: CategoryItemFormPage },
  { path: '/furnitures', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: FurnituresList },
  { path: '/furnitures/add', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: FurnitureFormPage },
  { path: '/furnitures/edit/:id', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: FurnitureFormPage },
  { path: '/decorations/add', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: DecorationFormPage },
  { path: '/decorations/edit/:id', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: DecorationFormPage },
  { path: '/active-orders', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: ActivesOrdersList },
  { path: '/archived-orders', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: ArchivedOrdersList },
  // { path: '/stats', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: Stats },
  { path: '/bookings', roles: ['superadmin', 'reception', 'admin', 'waiter', 'manager'], component: BookingsList },
  { path: '/bookings/add', roles: ['superadmin', 'reception', 'admin', 'waiter', 'manager'], component: BookingFormPage },
  { path: '/bookings/edit/:id', roles: ['superadmin', 'reception', 'admin', 'waiter', 'manager'], component: BookingFormPage },
  { path: '/waiter-areas', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: WaiterAreasList },
  { path: '/waiter-areas/add', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: WaiterAreaFormPage },
  { path: '/waiter-areas/edit/:id', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: WaiterAreaFormPage },
  { path: '/price-areas', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: PriceAreasList },
  { path: '/price-areas/add', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: PriceAreaFormPage },
  { path: '/price-areas/edit/:id', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: PriceAreaFormPage },
  { path: '/special-offers', roles: ['superadmin', 'admin', 'waiter', 'manager', 'manager'], component: SpecialOffersList },
  { path: '/special-offers/add', roles: ['superadmin', 'admin', 'waiter', 'manager', 'manager'], component: SpecialOfferFormPage },
  { path: '/special-offers/edit/:id', roles: ['superadmin', 'admin', 'waiter', 'manager', 'manager'], component: SpecialOfferFormPage },
  { path: '/maps', roles: ['superadmin', 'admin'], component: MapsList },
  { path: '/maps/add', roles: ['superadmin', 'admin'], component: MapFormPage },
  { path: '/maps/edit/:id', roles: ['superadmin', 'admin'], component: MapFormPage },
  { path: '/vat', roles: ['superadmin'], component: VatList },
  { path: '/vat/add', roles: ['superadmin'], component: VatFormPage },
  { path: '/vat/edit/:id', roles: ['superadmin'], component: VatFormPage },
  { path: '/list', roles: ['superadmin'], component: ListList },
  { path: '/list/add', roles: ['superadmin'], component: ListFormPage },
  { path: '/list/edit/:id', roles: ['superadmin'], component: ListFormPage },
  { path: '/welcome/', roles: ['superadmin', 'admin', 'waiter', 'manager'], component: Welcome },
  { path: '/reports', roles: ['superadmin', 'admin'], component: ReportsList },
  { path: '/login', component: SignIn },
  { path: '/forgotpassword', component: ForgotPassword },
  { path: '/reset-password/:id', component: ResetPassword },
  { path: '/profile', roles: ['superadmin', 'admin', 'waiter', 'reception', 'manager'], component: Profile },
  { path: '/pos', roles: ['superadmin', 'admin'], component: PosHome },
  { path: '/test', roles: ['superadmin', 'admin'], component: Test },
  { path: '/testedit', roles: ['superadmin', 'admin'], component: Edit },
  { path: '/checks', roles: ['superadmin', 'admin'], component: Checks },
  { path: '/check', roles: ['superadmin', 'admin'], component: Check },
  { path: '/voidtransaction', roles: ['superadmin', 'admin'], component: VoidTransaction },
  { path: '/watches', roles: ['superadmin', 'admin'], component: WatchesList },
  { path: '/watches/add', roles: ['superadmin', 'admin'], component: WatchFormPage },
  { path: '/watches/edit/:id', roles: ['superadmin', 'admin'], component: WatchFormPage },
  { path: '*', exact: false, component: Page404 },
];

export default routes;
