/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';
import useCustomerCategory from 'customers/contexts/customerCategories';
import useRouter from '../../../common/hooks/use-router';
import Button from '../../../common/components/Button/Button';
import PageHeader from '../../../common/components/PageHeader/PageHeader';
import Table from '../../../common/components/Table/Table';

const CustomerCategoriesList = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const {
    fetchItems: fetchCategories, items: categories, isFetching: isFetchingCategories,
    pageCount: pageCountCategories, filters: filtersCategories, setFilter: setFilterCategories,
    itemsCount: itemsCountCategories, remove,
  } = useCustomerCategory();

  const handleEditCategory = useCallback((row) => router.push(`/customer-categories/edit/${row.original.id}`),
    [router]);

  const handleDeleteCategory = useCallback(async (row) => {
    await remove(row.original.id);
    fetchCategories();
  }, [remove, fetchCategories]);

  const columnsCategories = useMemo(() => [
    {
      Header: t('common.name'),
      accessor: 'name',
      filterName: 'name_contains',
      Cell: ({ row: { original: { id, name } } }) => (
        <span className="table-link" onClick={() => router.push(`/customer-categories/edit/${id}`)}>
          {name}
        </span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEditCategory(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDeleteCategory(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('customers.confirmDeleteCategory')}
          />
        </>
      ),
    },
  ], [handleEditCategory, handleDeleteCategory, router, t]);

  return (
    <>
      <PageHeader
        title={t('customers.customerCategories')}
        link={{ url: '/customer-categories/add', icon: 'tag', label: t('customers.addCustomerCategory') }}
      />
      <section className="section list-page has-overflow-x-table">
        <Table
          data={categories}
          columns={columnsCategories}
          fetchData={fetchCategories}
          isFetching={isFetchingCategories}
          pageCount={pageCountCategories}
          filters={filtersCategories}
          setFilter={setFilterCategories}
          itemsCount={itemsCountCategories}
        />
      </section>
    </>
  );
};

export default CustomerCategoriesList;
