import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const ReportContext = createContext();

export const ReportsProvider = ({ children }) => (
  <ContextProvider url="reports" context={ReportContext}>{children}</ContextProvider>
);

ReportsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useReport = () => useContext(ReportContext);

export default useReport;
