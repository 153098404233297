import React, {
  useEffect, useContext, useCallback,
} from 'react';

import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import PageHeader from 'common/components/PageHeader/PageHeader';

import useSite from 'sites/contexts/sites';

import PageLoader from 'common/components/PageLoader/PageLoader';
import usePriceArea from 'priceAreas/contexts/priceAreas';
import useWaiterArea from 'waiterAreas/contexts/waiterAreas';
import SiteForm from './SiteForm';

const SiteFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem: fetchSite, item: site, currentSite, isFetching, fetchItems: fetchSites,
  } = useSite();

  const {
    save: savePriceArea,
    fetchItems: fetchPriceAreas,
  } = usePriceArea();
  const {
    save: saveWaiterArea,
    fetchItems: fetchWaiterAreas,
  } = useWaiterArea();

  const { id: siteId } = router.match.params;

  useEffect(() => { fetchSite(siteId); }, [fetchSite, siteId]);

  const createDefaultAreas = useCallback(async (newSiteId) => {
    const resPriceArea = await savePriceArea({
      default: true,
      name: t('priceAreas.defaultTitle'),
      color: '#00FF00',
      site: newSiteId,
    });

    const resWaiterArea = await saveWaiterArea({
      default: true,
      name: t('waiterAreas.defaultTitle'),
      color: '#0000FF',
      site: newSiteId,
      users: [],
    });

    if (!resPriceArea.error && !resWaiterArea.error) {
      setAlert(t('priceAreas.successAdd'), 'success');
      fetchPriceAreas();
      fetchWaiterAreas();
    } else {
      setAlert(resPriceArea.message, 'danger');
    }
  }, [fetchPriceAreas, savePriceArea, fetchWaiterAreas, saveWaiterArea, setAlert, t]);

  const onSubmit = useCallback(async (site) => {
    site.beach_data = {
      col: 6,
      row: 4,
    };
    // hack to accelerate the saving process
    // no need to send the seats config, they haven't been modified here
    site.seats = undefined;

    if (site.id) {
      const res = await update(site.id, site);

      if (!res.error) {
        setAlert(t('sites.successEdit'), 'success');
        if (!(currentSite && currentSite.id === res.id)) {
          router.push('/sites');
        }
      } else {
        setAlert(res.message, 'success');
      }
    } else {
      const res = await save(site);

      if (!res.error) {
        setAlert(t('sites.successAdd'), 'success');
        if (!(currentSite && currentSite.id === res.id)) {
          router.push('/sites');
        }

        createDefaultAreas(res.id);
      } else {
        setAlert(res.message, 'success');
      }
    }
    await fetchSites();
  }, [fetchSites, update, setAlert, t, currentSite, router, save, createDefaultAreas]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`sites.${site && site.id ? 'edit' : 'add'}Site`)}
        subLink={!(currentSite && site && currentSite.id === site.id)
          ? { url: '/sites', icon: 'arrow-left', label: t('common.back') } : ''}
      />
      <section className="section form-page">
        <SiteForm
          onSubmit={onSubmit}
          site={site}
        />
      </section>
    </>
  );
};

export default SiteFormPage;
