import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'common/hooks/use-router';

import Table from 'common/components/Table/Table';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Button from 'common/components/Button/Button';

import useWaiterArea from 'waiterAreas/contexts/waiterAreas';

const WaiterAreaList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    fetchItems: fetchWaiterAreas, items: waiterAreas, isFetching, pageCount, remove, filters, setFilter,
    itemsCount,
  } = useWaiterArea();

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    await fetchWaiterAreas();
  }, [remove, fetchWaiterAreas]);

  const handleEdit = useCallback((row) => router.push(`/waiter-areas/edit/${row.original.id}`), [router]);

  const columns = useMemo(() => [
    {
      Header: t('waiterAreas.id'),
      accessor: 'id',
    },
    {
      Header: t('common.name'),
      accessor: 'name',
      filterName: 'name_contains',
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('waiterAreas.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, handleDelete, handleEdit]);

  return (
    <>
      <PageHeader
        title={t('menu.listWaiterAreas')}
        link={{ url: '/waiter-areas/add', icon: 'plus', label: t('waiterAreas.addWaiterArea') }}
      />
      <section className="section list-page">
        <Table
          data={waiterAreas}
          columns={columns}
          fetchData={fetchWaiterAreas}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default WaiterAreaList;
