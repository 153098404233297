import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

export const BeachEditorContext = createContext();

export const BeachEditorProvider = ({ children }) => {
  const [selectedSelector, setSelectedSelector] = useState({});
  const [site, setSite] = useState(null);
  const [map, setMap] = useState(null);
  const [mode, setMode] = useState('furniture');

  // Default seats for mock
  const [seats, setSeats] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [incrementationSeat, setIncrementationSeat] = useState(null);
  const [someErrors, setSomeErrors] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const getValidNumber = useCallback((wantedSeat) => {
    const wantedSeatNumber = Number(wantedSeat);
    const isValid = !Number(wantedSeatNumber).isNan;
    const checkIsFree = (toCheck) => !seats.find((seat) => Number(seat.name) === toCheck);

    if (isValid && checkIsFree(wantedSeat)) {
      return wantedSeatNumber;
    }
    let altNumber = isValid ? wantedSeatNumber : 1;

    while (!checkIsFree(altNumber)) {
      altNumber += 1;
    }
    return altNumber;
  }, [seats]);

  const updateSeat = useCallback((id, updates) => {
    setSeats((previousSeats) => {
      const newseats = [...previousSeats];
      const index = newseats.findIndex((item) => item.id === id);

      newseats[index] = {
        ...newseats[index],
        ...updates,
        touched: true,
      };
      return newseats;
    });
  }, [setSeats]);

  const removeSeat = useCallback((itemId) => {
    setSeats((seats) => {
      const newSeats = seats.filter(({ id }) => id !== itemId);

      return newSeats;
    });
  }, [setSeats]);

  const handleClickonSquare = useCallback((id, ctrlPress = false) => {
    setSelectedSeats((oldSelection) => {
      if (ctrlPress) {
        if (oldSelection.find((seatId) => seatId === id)) {
          return oldSelection.filter((seatId) => seatId !== id);
        }
        return [...oldSelection, id];
      }
      return [id];
    });
  }, []);

  /* Update objects attached to Seat, if user update the list of priceAreas or (color, price, etc...) */
  const updateSeatInformation = useCallback((objectKey, objects) => {
    // Petit code qui empeche de mettre à jour si les données "objects" ne sont pas du même site
    // Race Condition:  il arrive que lorsque le superadmin change de site, il y est deux requetes
    // pour Récupérer le mobilier une avec l'ancien et une avec le nouveau par exemple
    // si on execute le code suivant sur le mauvais mobilier cela l'efface
    setIsUpdating(true);
    if (objects.length > 0 && objects?.[0].site?.id === site?.id) {
      setSeats((previousSeats) => {
        const newSeats = previousSeats.map(((seat) => seat[objectKey] ? {
          ...seat,
          [objectKey]: objects.find(({ id }) => id === seat[objectKey].id),
        } : seat));

        return newSeats;
      });
    }
    setIsUpdating(false);
  }, [site, setIsUpdating]);

  const changeSeatName = useCallback((seatModified, name) => {
    setSeats((previousSeats) => previousSeats.map(((seat) => seat.id === seatModified.id ? {
      ...seat,
      name,
      touched: true,
    } : seat)));
  }, [setSeats]);

  const changeMode = useCallback((mode) => {
    setMode(mode);
    setSelectedSelector({});
  }, [setMode, setSelectedSelector]);

  const value = useMemo(() => ({
    setSite,
    site,
    setMap,
    map,
    mode,
    changeMode,
    seats,
    setSeats,
    selectedSelector,
    setSelectedSelector,
    handleClickonSquare,
    updateSeatInformation,
    changeSeatName,
    updateSeat,
    removeSeat,
    selectedSeats,
    setSelectedSeats,
    incrementationSeat,
    setIncrementationSeat,
    getValidNumber,
    someErrors,
    setSomeErrors,
    isUpdating,
  }), [
    site,
    map,
    setMap,
    mode,
    changeMode,
    seats,
    selectedSelector,
    handleClickonSquare,
    updateSeatInformation,
    changeSeatName,
    updateSeat,
    removeSeat,
    selectedSeats,
    setSelectedSeats,
    incrementationSeat,
    setIncrementationSeat,
    getValidNumber,
    someErrors,
    setSomeErrors,
    isUpdating,
  ]);

  return (
    <BeachEditorContext.Provider value={value}>
      {children}
    </BeachEditorContext.Provider>
  );
};

BeachEditorProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default () => useContext(BeachEditorContext);
