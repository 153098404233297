import React, { useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import useProduct from 'products/contexts/products';

import PageHeader from 'common/components/PageHeader/PageHeader';
import PageLoader from 'common/components/PageLoader/PageLoader';
import CatalogProductForm from './CatalogProductForm';

const CatalogProductFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: product, isFetching,
  } = useProduct();

  const { id: productId } = router.match.params;

  useEffect(() => { fetchItem(productId); }, [fetchItem, productId]);

  const onSubmit = useCallback(async (data) => {
    const product = { ...data };

    // En attendant de faire un vrai champ InputNumber
    product.price = parseFloat(product.price);

    if (product.id) {
      const updatedProduct = await update(product.id, product);

      if (updatedProduct) {
        setAlert(t('products.successEdit'), 'success');
        router.push('/catalog-products');
      }
    } else {
      const newProduct = await save({ ...product });

      if (newProduct) {
        setAlert(t('products.successAdd'), 'success');
        router.push('/catalog-products');
      }
    }
  }, [router, update, save, setAlert, t]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`products.${product && product.id ? 'edit' : 'add'}CatalogProduct`)}
        subLink={{ url: '/catalog-products', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <CatalogProductForm
          onSubmit={onSubmit}
          product={product}
        />
      </section>
    </>
  );
};

export default CatalogProductFormPage;
