import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useMemo, useCallback,
} from 'react';
import ContextProvider from 'common/contexts/crud';
import fetchJSON from 'common/utils/fetchJSON';
import AlertsContext from 'common/contexts/alerts';
import { useTranslation } from 'react-i18next';

const SeatContext = createContext();

export const SeatsProvider = ({ children }) => {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const getSeatsInfo = useCallback(async () => {
    try {
      const res = await fetchJSON({
        url: 'seats?_start=0&_limit=1000',
        method: 'GET',
      });

      return res;
    } catch (e) {
      setAlert(t('seats.errorSeatsInfo'), 'danger');
    }
  }, [setAlert, t]);

  const value = useMemo(() => ({
    getSeatsInfo,
  }), [getSeatsInfo]);

  return (
    <ContextProvider value={value} url="seats" context={SeatContext}>{children}</ContextProvider>
  );
};

SeatsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useSeat = () => useContext(SeatContext);

export default useSeat;
