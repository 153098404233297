import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FormInput from 'common/components/FormInput/FormInput';
import SiteSelect from 'sites/components/SiteSelect';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import arrayMutators from 'final-form-arrays';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import i18n from '../../i18n';

const month31 = [1, 3, 5, 7, 8, 10, 12];
const month30 = [4, 6, 9, 11];
// const month28 = [2];

const getDaysOfMonth = (currentMonth) => {
  const newList = [];

  let count = 28; // minimum of february

  if (month31.find((month) => month === currentMonth)) {
    count = 31;
  } else if (month30.find((month) => month === currentMonth)) {
    count = 30;
  }
  for (let i = 1; i < count + 1; i++) {
    newList.push({ value: i, label: i });
  }

  return newList;
};

const SeasonForm = ({
  onSubmit, season, currentSite, isFetching,
}) => {
  const { t } = useTranslation();

  if (currentSite && season && season.id && season.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  const defaultLang = season?.site?.default_language ?? (
    currentSite ? currentSite.default_language : i18n.language);

  const initialValues = {
    active: true,
    site: currentSite?.id ?? '',
    startDay: 1,
    startMonth: 1,
    endDay: 28,
    endMonth: 12,
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [daysListStart, setDaysListStart] = useState([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!isFetching && !daysListStart?.length) {
      setDaysListStart(getDaysOfMonth(season?.startMonth || 1));
    }
  }, [isFetching, season, daysListStart]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [daysListEnd, setDaysListEnd] = useState([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!isFetching && !daysListEnd?.length) {
      setDaysListEnd(getDaysOfMonth(season?.endMonth || 12));
    }
  }, [isFetching, season, daysListEnd]);

  const months = [];

  let date;

  for (let i = 0; i < 12; i++) {
    date = new Date(2022, i, 1); // the year and day are not important
    months.push({ value: i + 1, label: date.toLocaleString(defaultLang, { month: 'long' }) });
  }

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      initialValues={season ?? initialValues}
      render={({
        handleSubmit, submitting, pristine, values, form,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />

          <h2 className="subtitle">{t('menu.seasons')}</h2>

          <div className="row">

            <MultilingualInput
              icon="heading"
              type="text"
              name="name"
              label={t('common.name')}
              defaultLang={defaultLang}
              required
            />
            <div style={{
              alignSelf: 'flex-end',
              padding: 16,
            }}
            >
              <FormInput
                type="checkbox"
                name="active"
                label={t('common.active')}
              />
            </div>
          </div>
          <div className="row">

            <FormInput
              type="select"
              name="startMonth"
              label={t('seasons.startMonth')}
              options={months}
              required
              onChange={(value) => {
                setDaysListStart(getDaysOfMonth(value));
                if (!getDaysOfMonth(value).find((day) => day.value === values?.startDay)) {
                  form.mutators.setFieldValue({
                    field: 'startDay',
                    value: 1,
                  });
                }
              }}
            />

            <FormInput
              type="select"
              name="startDay"
              label={t('seasons.startDay')}
              options={daysListStart}
              required
            />

            <FormInput
              type="select"
              name="endMonth"
              label={t('seasons.endMonth')}
              options={months}
              required
              onChange={(value) => {
                setDaysListEnd(getDaysOfMonth(value));
                if (!getDaysOfMonth(value).find((day) => day.value === values?.endDay)) {
                  form.mutators.setFieldValue({
                    field: 'startDay',
                    value: 1,
                  });
                }
              }}
            />

            <FormInput
              type="select"
              name="endDay"
              label={t('seasons.endDay')}
              options={daysListEnd}
              required
            />
          </div>
          {!currentSite && (
            <FormInput
              type="custom"
              name="site"
              label={t('common.site')}
              required
            >
              <SiteSelect />
            </FormInput>
          )}

          <FormInput
            type="submit"
            label={t('common.save')}
            icon="map-marker-alt"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

SeasonForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  season: PropTypes.object,
};

SeasonForm.defaultProps = {
  season: {},
};

export default SeasonForm;
