/* eslint-disable react/prop-types */
import Button from 'common/components/Button/Button';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Table from 'common/components/Table/Table';
import useRouter from 'common/hooks/use-router';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';
import RoleSelect from 'users/components/RoleSelect';
import useUser from 'users/contexts/users';
import WaiterAreaSelect from 'waiterAreas/components/WaiterAreaSelect';

const UsersList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentSite } = useSite();
  const {
    fetchItems: fetchUsers, items: users, isFetching, pageCount, remove, update, filters, setFilter,
    itemsCount,
  } = useUser();

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    fetchUsers();
  }, [remove, fetchUsers]);
  const handleEdit = useCallback((row) => router.push(`/users/edit/${row.original.id}`), [router]);

  const handleAreas = useCallback(async (row, areasId) => {
    await update(row.original.id, { areas: areasId });
    await fetchUsers();
  }, [update, fetchUsers]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      accessor: 'username',
      filterName: 'username_contains',
      Cell: ({ row: { original: { id, username } } }) => (
        <span className="table-link" onClick={() => router.push(`/users/edit/${id}`)}>
          {username}
        </span>
      ),
    },
    {
      Header: t('common.email'),
      accessor: 'email',
      filterName: 'email_contains',
    },
    {
      Header: t('users.role'),
      filterName: 'role.id',
      filterComponent: (props) => <RoleSelect isFilter {...props} />,
      Cell: ({ row: { original: { role } } }) => (
        <span>
          {role?.name && t(`common.${role?.name}`)}
        </span>
      ),
    },
    {
      Header: t('users.waiterAreas'),
      Cell: ({ row, row: { original: { areas, site, role } } }) => (
        <span>
          {(['waiter', 'admin'].includes(role?.name)) && (
          <WaiterAreaSelect
            value={areas.map(({ id }) => id)}
            site={site?.id}
            multiple
            onChange={(areasId) => handleAreas(row, areasId)}
          />
          )}
        </span>
      ),
    },
    {
      Header: t('users.color'),
      accessor: 'color',
      Cell: ({ row: { original: { color } } }) => (
        <div className="color-picker">
          {
            color ? (
              <div className="color-picker">
                <div className="swatch">
                  <div style={{ backgroundColor: `${color}` }} className="color" />
                </div>
              </div>
            ) : null
          }
        </div>
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      Cell: ({ row: { original: { site } } }) => (
        <span>
          {site?.name}
        </span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('users.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, currentSite, handleAreas, handleEdit, handleDelete, router]);

  return (
    <>
      <PageHeader
        title={t('menu.listUsers')}
        link={{ url: '/users/add', icon: 'user-plus', label: t('users.addUser') }}
      />
      <section className="section list-page has-overflow-x-table">
        <Table
          data={users}
          columns={columns}
          fetchData={fetchUsers}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default UsersList;
