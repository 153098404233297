import PropTypes from 'prop-types';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const HtmlEditor = ({
  value, onChange, placeholder, name, ...rest
}) => {
  // [class ~= au lieu d'un simple .class car le name peut contenir un point
  const modules = {
    toolbar: {
      container: `[class~="toolbar-${name}"]`,
    },
  };

  return (
    <div className="text-editor">
      <div className={`toolbar-${name}`}>
        <button label="bold" className="ql-bold" />
        <button label="italic" className="ql-italic" />
      </div>
      <ReactQuill
        onChange={onChange}
        placeholder={placeholder}
        modules={modules}
        theme="snow"
        value={value}
        style={{ height: '200px' }}
        {...rest}
      />
    </div>
  );
};

HtmlEditor.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

HtmlEditor.defaultProps = {
  value: '',
  name: '',
  placeholder: '',
};

export default HtmlEditor;
