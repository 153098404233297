/* eslint-disable prefer-destructuring */
import React, {
  useRef, useCallback, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import useBeachEditor from 'sites/pages/BeachEditor/contexts/beachEditor';
import usePriceArea from 'priceAreas/contexts/priceAreas';
import useWaiterArea from 'waiterAreas/contexts/waiterAreas';
import useFurniture from 'furnitures/contexts/furnitures';
import RectangleSelection from '../RectangleSelect';
import Item from '../Item/Item';

let currentNewIndex = 0;

const Board = ({
  siteId,
  mapId,
  scale,
  previousScale,
  mapScreenDimensions,
  mapTrueDimensions,
  ...rest
}) => {
  const {
    mode, seats, setSeats, selectedSeats, setSelectedSeats,
    incrementationSeat, setIncrementationSeat, getValidNumber,
  } = useBeachEditor();

  const {
    items: priceAreas,
  } = usePriceArea();

  const {
    items: waiterAreas,
  } = useWaiterArea();

  const {
    items: furnitures,
  } = useFurniture();

  const areaRef = useRef(null);

  // move
  const [isMoving, setIsMoving] = useState(false);
  const [offset, setOffset] = useState(null);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const handleZoneSelect = (coords) => {
    setSelectedSeats((oldSelection) => {
      let xmin;

      let ymin;

      let xmax;

      let ymax;

      if (coords.origin[0] < coords.target[0]) {
        xmin = coords.origin[0];
        xmax = coords.target[0];
      } else {
        xmin = coords.target[0];
        xmax = coords.origin[0];
      }
      if (coords.origin[1] < coords.target[1]) {
        ymin = coords.origin[1];
        ymax = coords.target[1];
      } else {
        ymin = coords.target[1];
        ymax = coords.origin[1];
      }
      const zoneSeats = seats.filter((seat) => seat.x >= xmin && seat.x <= xmax
                && seat.y >= ymin && seat.y <= ymax);

      const newSelection = [...oldSelection];

      zoneSeats.forEach((seat) => {
        if (!oldSelection.find((s) => s === seat.id)) {
          newSelection.push(seat.id);
        }
      });
      return newSelection;
    });
  };

  const handleMouseDown = (e) => {
    let squareTarget;

    if (e.target.classList.contains('BoardItem') || e.target.classList.contains('editor-selected-zone')) {
      squareTarget = e.target;
    } else if (e.target.parentNode.classList.contains('BoardItem')) {
      squareTarget = e.target.parentNode;
    }

    if (squareTarget) {
      const seatId = squareTarget.getAttribute('dataid');
      const seat = selectedSeats.find((id) => id.toString() === seatId.toString());

      if (!seat) {
        return;
      }

      setIsMouseDown(true);

      const containerPosition = areaRef.current.getBoundingClientRect();
      const cursorLeft = Math.round((e.clientX - containerPosition.x) / (scale * previousScale));
      const cursorTop = Math.round((e.clientY - containerPosition.y) / (scale * previousScale));

      setOffset([
        squareTarget.offsetLeft - cursorLeft,
        squareTarget.offsetTop - cursorTop,
        seat,
      ]);
    }
  };

  useEffect(() => {
    const handleMouseUp = () => {
      setTimeout(() => { setIsMoving(false); }, 0);
      setIsMouseDown(false);
    };

    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleMouseMove = (e) => {
    if (isMouseDown) {
      setIsMoving(true);
      e.stopPropagation();

      const containerPosition = areaRef.current.getBoundingClientRect();
      const cursorLeft = Math.round((e.clientX - containerPosition.x) / (scale * previousScale));
      const cursorTop = Math.round((e.clientY - containerPosition.y) / (scale * previousScale));

      const currentSeat = seats.find(({ id }) => offset[2].toString() === id.toString());
      const moveLeft = cursorLeft + offset[0] - currentSeat.x;
      const moveTop = cursorTop + offset[1] - currentSeat.y;

      selectedSeats.forEach((seat) => {
        moveBox(seat, moveLeft, moveTop);
      });
    }
  };

  const handleDoubleClick = useCallback((event) => {
    if (event?.target?.className
      && ((event.target.className.indexOf('itemElement') !== -1)
      || (event.target.className.indexOf('BoardItem') !== -1))) {
      return;
    }

    // if selection, clean it first
    setSelectedSeats((currentSelection) => {
      if (!currentSelection.length) {
        if (mode !== 'conception' && mode !== 'decoration') {
          // create square
          const containerPosition = areaRef.current.getBoundingClientRect();
          const left = Math.round((event.clientX - containerPosition.x) / (scale * previousScale)) - 30;
          const top = Math.round((event.clientY - containerPosition.y) / (scale * previousScale)) - 30;

          setSeats((seats) => {
            const newSeat = {
              id: `new_${currentNewIndex++}`,
              x: left,
              y: top,
              active: true,
              site: siteId,
              map: mapId,
              scale: 1,
              furniture: incrementationSeat?.furniture || furnitures?.[0] || null,
              waiter_area: incrementationSeat?.waiter_area
              || waiterAreas.find((area) => area.default) || waiterAreas?.[0] || null,
              price_area: incrementationSeat?.price_area
              || priceAreas.find((area) => area.default) || priceAreas?.[0] || null,
              name: (getValidNumber(incrementationSeat?.name)).toString(),
            };

            setIncrementationSeat(() => ({
              name: getValidNumber(Number(newSeat.name) + 1),
              waiter_area: newSeat.waiter_area,
              price_area: newSeat.price_area,
              furniture: newSeat.furniture,
            }));

            seats.push(newSeat);
            return [...seats];
          });
        }
        return currentSelection;
      }

      return [];
    });
  }, [setSeats, scale, mode, siteId, previousScale,
    mapId, setSelectedSeats, incrementationSeat, setIncrementationSeat,
    priceAreas, waiterAreas, furnitures, getValidNumber]);

  const moveBox = (seatId, left, top) => {
    setSeats((seats) => {
      let currentSeat = seats.find(({ id }) => seatId.toString() === id.toString());

      if (!currentSeat) {
        currentSeat = { id: `new_${currentNewIndex++}`, x: left, y: top };
        seats.push(currentSeat);
      } else {
        currentSeat.x += left;
        currentSeat.y += top;
      }
      currentSeat.touched = true;
      return [...seats];
    });
  };

  return (
    <div
      ref={areaRef}
      className="beach-offset-screen"
    >
      <div
        {...rest}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onDoubleClick={handleDoubleClick}
      >
        <RectangleSelection
          scale={scale * previousScale}
          container={areaRef.current}
          onMouseUp={handleZoneSelect}
          style={{
            backgroundColor: 'rgba(0,0,255,0.4)',
            borderColor: 'blue',
          }}
        >
          { seats.map((seat, key) => {
            const {
              x, y, id, title = 'seat',
            } = seat;

            return (
              <Item
                key={key}
                id={id}
                item={seat}
                left={x}
                top={y}
                hideSourceOnDrag
                moveInProgress={isMoving}
                mapTrueDimensions={mapTrueDimensions}
                mapScreenDimensions={mapScreenDimensions}
              >
                {title}
              </Item>
            );
          })}
        </RectangleSelection>
        {/* selectedSeats.length > 1 && printRect() */}
      </div>
    </div>
  );
};

Board.propTypes = {
  scale: PropTypes.number,
  siteId: PropTypes.number.isRequired,
  mapId: PropTypes.number.isRequired,
  previousScale: PropTypes.number,
  mapTrueDimensions: PropTypes.object,
  mapScreenDimensions: PropTypes.object,
};
Board.defaultProps = {
  scale: 1,
  previousScale: 1,
  mapTrueDimensions: {
    width: 50,
    height: 50,
  },
  mapScreenDimensions: {
    width: 800,
    height: 800,
  },
};

export default Board;
