import React, {
  useCallback, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import useMap from 'maps/contexts/maps';
import AlertsContext from 'common/contexts/alerts';
import PropTypes from 'prop-types';
import useRouter from 'common/hooks/use-router';
import useBeachEditor from '../../contexts/beachEditor';

const EditorHeader = ({ mapScale }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    update, isFetching,
  } = useMap();
  const {
    seats, setSeats, mode, changeMode, map: updatedMap, setMap: updateMap, someErrors,
  } = useBeachEditor();

  const { setAlert } = useContext(AlertsContext);

  const saveConfiguration = useCallback(async () => {
    const payload = { ...updatedMap };

    payload.seats = seats.map((s) => ({
      ...s,
      furniture: s.furniture?.id,
      price_area: s.price_area?.id,
      waiter_area: s.waiter_area?.id,
    }));

    payload.mapBackOfficeScale = mapScale;
    const res = await update(payload.id, payload, false);

    if (!res.error) {
      updateMap(res);
      setSeats(res.seats.filter((s) => s.active));
      setAlert(t('maps.successEdit'), 'success');
    } else {
      setAlert(res.message, 'success');
    }
  }, [updatedMap, seats, mapScale, update, updateMap, setSeats, setAlert, t]);

  return (
    <div className="beach-header">
      <div className="beach-mode buttons has-addons">
        <button
          className="button beach-editor-tabs--button"
          onClick={() => router.push('/maps')}
          style={{
            borderRight: '2px solid rgba(255,255,255,0.2)',
            borderRadius: 0,
            marginRight: 12,
          }}
        >
          <i className="fas fa-list" />
        </button>

        <button
          className={mode === 'conception'
            ? 'button is-active beach-editor-tabs--button'
            : 'button beach-editor-tabs--button'}
          onClick={() => changeMode('conception')}
        >
          { t('menu.conception') }
        </button>

        <button
          className={mode === 'furniture'
            ? 'button is-active beach-editor-tabs--button'
            : 'button beach-editor-tabs--button'}
          onClick={() => changeMode('furniture')}
        >
          { t('menu.furnitures') }
        </button>

        {/*
        <button
          className={mode === 'decoration'
            ? 'button is-active beach-editor-tabs--button'
            : 'button beach-editor-tabs--button'}
          onClick={() => changeMode('decoration')}
        >
          { t('menu.decorations') }
        </button>
        */}

        <button
          className={mode === 'price'
            ? 'button is-active beach-editor-tabs--button'
            : 'button beach-editor-tabs--button'}
          onClick={() => changeMode('price')}
        >
          { t('menu.priceAreas') }
        </button>
        <button
          className={mode === 'waiter'
            ? 'button is-active beach-editor-tabs--button'
            : 'button beach-editor-tabs--button'}
          onClick={() => changeMode('waiter')}
        >
          { t('menu.waiterAreas') }
        </button>
      </div>
      <button
        className={
              `button save-editor-button 
              ${someErrors.length ? 'is-danger' : 'is-success'} 
              ${isFetching ? 'is-loading' : ''}`
            }
        disabled={isFetching || someErrors.length}
        onClick={saveConfiguration}
      >
        {t('beach.save')}
        {Boolean(someErrors.length) && (
        <div style={{ marginLeft: 10 }}>
          {someErrors.length}
          <i className="fa fa-exclamation-triangle" style={{ marginLeft: 3 }} />
        </div>

        )}
      </button>
    </div>

  );
};

export default EditorHeader;

EditorHeader.propTypes = {
  mapScale: PropTypes.number,
};
EditorHeader.defaultProps = {
  mapScale: 1,
};
