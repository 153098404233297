import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import useBeachEditor from 'sites/pages/BeachEditor/contexts/beachEditor';
import ReactSwitch from 'react-switch';

const SeatAvailabilityChoice = () => {
  const {
    updateSeat, selectedSeats, seats,
  } = useBeachEditor();
  const { t } = useTranslation();

  const currentSeats = useMemo(() => (
    selectedSeats.map((selected) => seats.find((seat) => seat.id === selected))
  ), [seats, selectedSeats]);

  const initialStateOutOfOrder = useCallback(() => {
    // set outOfOrder switch value
    const nbSeatsOutOfOrder = currentSeats.filter(
      (currentSeat) => currentSeat?.outOfOrder || false,
    ).length;

    if (nbSeatsOutOfOrder === 0) {
      return false;
    } if (nbSeatsOutOfOrder === currentSeats.length) {
      return true;
    }
    return false;
  }, [currentSeats]);

  const initialStateOutOfService = useCallback(() => {
    // set outOfService switch value
    const nbSeatsOutOfService = currentSeats.filter(
      (currentSeat) => currentSeat?.outOfService || false,
    ).length;

    if (nbSeatsOutOfService === 0) {
      return false;
    } if (nbSeatsOutOfService === currentSeats.length) {
      return true;
    }
    return false;
  }, [currentSeats]);

  const [outOfOrder, setOutOfOrder] = useState(initialStateOutOfOrder);
  const [outOfService, setOutOfService] = useState(initialStateOutOfService);

  useEffect(() => {
    setOutOfOrder(initialStateOutOfOrder);
    setOutOfService(initialStateOutOfService);
  }, [currentSeats, initialStateOutOfOrder, initialStateOutOfService]);

  const handleChangeOutOfOrder = useCallback((outOfOrder) => {
    setOutOfOrder(outOfOrder);
    currentSeats.forEach(async (currentSeat) => {
      await updateSeat(currentSeat.id, { outOfOrder });
    });
  }, [currentSeats, updateSeat]);

  const handleChangeOutOfService = useCallback((outOfService) => {
    setOutOfService(outOfService);
    currentSeats.forEach(async (currentSeat) => {
      await updateSeat(currentSeat.id, { outOfService });
    });
  }, [currentSeats, updateSeat]);

  return (
    <div className="selectors">
      <h3 className="editor-subtitle">{t('editor.seatAvailability')}</h3>
      <div className="editor-form-control-horizontal">
        <ReactSwitch
          checked={outOfOrder}
          onChange={handleChangeOutOfOrder}
          onColor="#FF8C00"
          offColor={outOfOrder === null ? '#888' : '#0073FF'}
          checkedIcon={false}
          uncheckedIcon={false}
        />
        <label>{t('editor.seatOutOfOrder')}</label>
      </div>
      {/* add little text in italic */}
      <div className="editor-form-control-horizontal">
        <i>{t('editor.seatOutOfOrderDescription')}</i>
      </div>
      <div className="editor-form-control-horizontal">
        <ReactSwitch
          checked={outOfService}
          onChange={handleChangeOutOfService}
          onColor="#FF8C00"
          offColor={outOfService === null ? '#888' : '#0073FF'}
          checkedIcon={false}
          uncheckedIcon={false}
        />
        <label>{t('editor.seatOutOfService')}</label>
      </div>
      <div className="editor-form-control-horizontal">
        <i>{t('editor.seatOutOfServiceDescription')}</i>
      </div>

    </div>
  );
};

export default SeatAvailabilityChoice;
