import React, { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'common/hooks/use-router';

import Table from 'common/components/Table/Table';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Button from 'common/components/Button/Button';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import useProduct from 'products/contexts/products';
import CategorySelect from 'categories/components/CategorySelect';
import useCategory from 'categories/contexts/categories';
import useTrans from 'common/hooks/use-trans';
import formatPrice from 'common/utils/formatPrice';
import useSite from 'sites/contexts/sites';

const CatalogProductsList = () => {
  const { t } = useTranslation();
  const { translateText } = useTrans();
  const router = useRouter();
  const {
    fetchItems: fetchProducts,
    items: products,
    isFetching,
    pageCount,
    remove,
    filters,
    setFilter: setProductsFilter,
    itemsCount,
  } = useProduct();

  const { item: currentSite } = useSite();
  const {
    setFilter: setCategoryFilter,
  } = useCategory();

  useEffect(() => {
    setProductsFilter('site_null', 1);
    setCategoryFilter('site_null', 1);
  }, [setProductsFilter, setCategoryFilter]);

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    await fetchProducts();
  }, [remove, fetchProducts]);

  const handleEdit = useCallback((row) => router.push(`/catalog-products/edit/${row.original.id}`), [router]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { name } } }) => (<TranslatedText value={name} />),
    },
    {
      Header: t('common.reference'),
      accessor: 'reference',
      filterName: 'reference_contains',
    },
    {
      Header: t('common.price'),
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { price, option, variant_prices: variantPrices } } }) => {
        if (option && variantPrices.length) {
          return variantPrices.filter(({ price }) => price !== null).map(({ price, option_variant: variant }) => (
            <div>
              {`${translateText(variant?.name)}: ${formatPrice(price, currentSite?.currency)}`}
            </div>
          ));
        }
        return (
          <span>
            {`${formatPrice(price, currentSite?.currency)}`}
          </span>
        );
      },
    },
    {
      Header: t('products.category'),
      filterName: 'category.id',
      filterComponent: (props) => <CategorySelect isFilter {...props} />,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { category } } }) => (<TranslatedText value={category?.name} />),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('products.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, translateText, handleEdit, handleDelete, currentSite]);

  return (
    <>
      <PageHeader
        title={t('menu.listCatalogProducts')}
        link={{ url: '/catalog-products/add', icon: 'pizza-slice', label: t('products.addCatalogProduct') }}
      />
      <section className="section list-page">
        <Table
          data={products}
          columns={columns}
          fetchData={fetchProducts}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setProductsFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default CatalogProductsList;
