import React, { useContext, useCallback } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'common/contexts/auth';
import AlertsContext from 'common/contexts/alerts';
import FormInput from 'common/components/FormInput/FormInput';
import useRouter from 'common/hooks/use-router';
import Button from 'common/components/Button/Button';

const initialValues = {
  password: '',
  passwordConfirmation: '',
};

const ResetPassword = () => {
  const { t } = useTranslation();
  const { resetPassword } = useAuth();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const onSubmit = useCallback(async (payload) => {
    await resetPassword({ ...payload, code: router.query.id });

    setAlert(t('auth.newPasswordSaved'), 'success');

    router.push('/');
  }, [resetPassword, setAlert, router, t]);

  const validate = useCallback((values) => {
    const errors = {};

    if (!values.password) {
      errors.password = t('common.fieldRequired');
    }
    if (!values.passwordConfirmation) {
      errors.passwordConfirmation = t('common.fieldRequired');
    } else if (values.passwordConfirmation !== values.password) {
      errors.passwordConfirmation = t('common.mustMatch');
    }
    return errors;
  }, [t]);

  return (
    <section className="section login-page">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <FormInput
              type="password"
              name="password"
              label={t('auth.password')}
              icon="fa-key"
            />
            <FormInput
              type="password"
              name="passwordConfirmation"
              label={t('auth.passwordConfirm')}
              icon="fa-key"
            />
            <Button
              type="submit"
              label={t('common.send')}
              icon="fa-sign-in-alt"
              onClick={handleSubmit}
              color="primary"
              disabled={submitting || pristine}
            />
          </>
        )}
      />
    </section>
  );
};

export default ResetPassword;
