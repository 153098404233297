/* eslint-disable object-curly-newline */
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import fetchJSON from 'common/utils/fetchJSON';
import Modal from 'common/components/Modal/Modal';
import useSite from 'sites/contexts/sites';
import Button from '../../../common/components/Button/Button';

const CustomersImportModal = ({ showImportModal,
  setShowImportModal }) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [errors, setErrors] = useState([]);
  const { currentSite } = useSite();

  const importDatas = useCallback(
    async (fileByteArray) => {
      const importItems = async (body) => {
        const response = await fetchJSON({
          url: 'customers/import',
          method: 'POST',
          payload: {
            file: body,
            site: currentSite.id,
          },
        });

        if (response.status === 400) {
          const err = response;

          setErrors(err.newErrors);
          return err;
        }
        return response;
      };

      const body = fileByteArray;

      setSpinner(true);
      const datas = await importItems(body);

      if (datas) {
        if (datas.status === 200 && !datas.newErrors) {
          setShowImportModal(false);
        }
      }
      setSpinner(false);
    },
    [
      setShowImportModal,
      setSpinner,
      currentSite,
    ],
  );

  useEffect(() => {
    if (selectedFile) {
      setErrors([]);
      setSelectedFile(null);
      const reader = new FileReader();
      const fileByteArray = [];

      reader.readAsArrayBuffer(selectedFile);
      reader.onloadend = (evt) => {
        if (evt.target && evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;

          if (arrayBuffer) {
            const array = new Uint8Array(arrayBuffer);

            array.forEach((a) => fileByteArray.push(a));
            importDatas(fileByteArray);
          }
        }
      };
    }
  }, [importDatas, selectedFile, setErrors]);

  const handleCloseModal = useCallback(() => {
    setShowImportModal(false);
  }, [setShowImportModal]);

  return (
    <>
      {showImportModal && (
      <Modal confirmModal={() => {}} closeModal={handleCloseModal}>
        <>
          {spinner ? (
            <p style={{ textAlign: 'center' }}>{t('customers.loading')}</p>
          ) : (
            <>
              <a href="/mybea_import_example.xlsx" target="_blank">
                {t('customers.importExample')}
              </a>
              <label htmlFor="select-xls" className="file">
                <input
                  id="select-xls"
                  type="file"
                  accept=".xlsx "
                  style={{ display: 'none' }}
                  onChange={(e) => e.target.files && setSelectedFile(e.target.files[0])}
                />

                <p>{t('customers.importFile')}</p>
              </label>
            </>
          )}
          <div className="error">
            {errors
                  && errors.map((error, errorIndex) => {
                    const key = `error-${errorIndex}`;

                    return (
                      <p key={key}>
                        <span>
                          {`Ligne ${error.line + 1}, Col ${error.column} : `}
                        </span>
                        <br />
                        {error.message}
                      </p>
                    );
                  })}
          </div>
        </>
      </Modal>

      )}
    </>
  );
};

export default CustomersImportModal;
