import React from 'react';
import PropTypes from 'prop-types';

import BoardSquare from '../BoardSquare/BoardSquare';
import useBeachVisualizer from '../../contexts/beachVisualizer';

const style = {
  position: 'absolute',
  border: '1px dashed gray',
  padding: 0,
};

const RenderSquare = ({ item }) => (
  <>
    { item.furniture && (
    <div style={{
      ...style,
      width: (item?.furniture?.width || 100) * item.scale,
      height: (item?.furniture?.height || 100) * item.scale,
      left: item.x,
      top: item.y,
    }}
    >
      <BoardSquare item={item} />
    </div>
    ) }
  </>
);

RenderSquare.propTypes = {
  item: PropTypes.object.isRequired,
};

const Board = ({ ...rest }) => {
  const { seats } = useBeachVisualizer();

  return (
    <div className="board" {...rest}>
      {seats.map((seat) => (
        <RenderSquare key={seat.id} x={seat.x} y={seat.y} item={seat} />
      ))}
    </div>
  );
};

export default Board;
