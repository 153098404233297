import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import ContextProvider from 'common/contexts/crud';
import AlertsContext from 'common/contexts/alerts';
import fetchJSON from 'common/utils/fetchJSON';
import useSite from 'sites/contexts/sites';

const CategoryContext = createContext();

export const CategoriesProvider = ({ children }) => {
  const [isImportFetching, setIsImportFetching] = useState(false);
  const { setAlert } = useContext(AlertsContext);
  const { currentSite } = useSite();

  const importCategories = useCallback(async (categories, rootCategory) => {
    setIsImportFetching(true);
    try {
      const res = await fetchJSON({
        url: 'categories/import',
        method: 'POST',
        payload: { categories, rootCategory, site: currentSite.id },
      });

      if (res.error) {
        throw new Error('Error importing category');
      }
      return res;
    } catch (e) {
      throw new Error('Error importing category');
    } finally {
      setIsImportFetching(false);
    }
  }, [currentSite]);

  const getRef = useCallback(async (filters = {}) => {
    const params = {
      ...filters,
    };

    const esc = encodeURIComponent;
    const queryParams = Object.keys(params).map((key) => `${esc(key)}=${esc(params[key])}`).join('&');

    setIsImportFetching(true);
    try {
      const res = await fetchJSON({
        url: `categories/reference${queryParams ? '?' : ''}${queryParams}`,
        method: 'GET',
      });

      return res;
    } catch (e) {
      setAlert(e.message, 'danger');
    } finally {
      setIsImportFetching(false);
    }
  }, [setAlert]);

  const value = useMemo(() => ({
    isImportFetching,
    importCategories,
    getRef,
  }), [
    isImportFetching,
    importCategories,
    getRef,
  ]);

  return (
    <ContextProvider
      url="categories"
      value={value}
      context={CategoryContext}
    >
      {children}
    </ContextProvider>
  );
};

CategoriesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useCategory = () => useContext(CategoryContext);

export default useCategory;
