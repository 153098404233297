import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const OptionContext = createContext();

export const OptionsProvider = ({ children }) => (
  <ContextProvider url="options" context={OptionContext}>{children}</ContextProvider>
);

OptionsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useOption = () => useContext(OptionContext);

export default useOption;
